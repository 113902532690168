import { ChatTemplateService } from "@/services";

const Types = {
  FETCH_TEMPLATE_SUCCESS: "FETCH_TEMPLATE_SUCCESS",
  SERVICE_ERROR: "SERVICE_ERROR",
  SET_LOADING: "SET_LOADING",
};

const state = {
  data: [],
  error: null,
  loading: false,
};

const getters = {
  get: (state) => state.data,
};

const mutations = {
  [Types.FETCH_TEMPLATE_SUCCESS]: (state, payload) => (state.data = payload),
  [Types.SERVICE_ERROR]: (state, payload) => (state.error = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
};

const actions = {
  async get({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await ChatTemplateService.get();
      commit(Types.FETCH_TEMPLATE_SUCCESS, response.data);
    } catch (error) {
      commit(Types.SERVICE_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await ChatTemplateService.create(payload);
      return response.data;
    } catch (error) {
      commit(Types.SERVICE_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async update({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await ChatTemplateService.update({
        id: payload.id,
        key: payload.key,
        content: payload.content,
        files: payload.files
      });
      return response.data;
    } catch (error) {
      commit(Types.SERVICE_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async delete({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await ChatTemplateService.delete({
        id: payload.id,
      });
      return response.data;
    } catch (error) {
      commit(Types.SERVICE_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async deleteMultiple({commit}, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await ChatTemplateService.deleteMultiple(payload);
      return response.data;
    } catch(error) {
      commit(Types.SERVICE_ERROR, error)
    }
    commit(Types.SET_LOADING, false)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
