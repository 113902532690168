<template>
    <div class="card-body">
      <h5 class="card-title mb-3">Integrate With Dynamic</h5>
      <!-- card list integration start -->
      <transition-group
        name="list"
        tag="card"
        class="row row-cols-lg-4 row-cols-lg-3 row-cols-md-2 row-cols-1"
      >
        <div class="col" v-for="(item, key) in listIntegration" :key="key">
          <Card
            :title="item.integration_data.IntegrationName"
            :created_at="item.created_at"
            :updated_at="item.updated_at ?? item.created_at"
            :status="item.status"
            icon="ri-shopping-bag-fill"
            @edit-on-click="showModalEdit(item)"
            @delete-on-click="showModalDelete(item)"
            @status-on-click="changeStatus(item)"
          />
        </div>
        <!-- card list integration end -->
        <!-- card add start-->
        <!-- eslint-disable prettier/prettier -->
        <div class="col">
          <div
            class="card card-body card-height-100 d-flex flex-column justify-content-center"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <i
                class="ri-add-circle-fill pointer text-primary"
                style="font-size: 45px"
                @click="showModalCreate"
              ></i>
              <label class="text-primary">Add New Dynamic Integration</label>
            </div>
          </div>
        </div>
        <!-- card add end -->
      </transition-group>
    </div>
    <Delete
      :showModal="show.delete"
      @onClose="show.delete = false"
      @onDelete="onDelete"
    />
    <ModalLine
      :showModal="show.add"
      @onClose="show.add = false"
      @onSubmit="onSubmit"
      title="Setup Your Dynamic Integration"
    />
    <ModalLine
      :data="form"
      :showModal="show.edit"
      @onClose="show.edit = false"
      @onSubmit="onUpdate"
      title="Edit Your Dynamic Integration"
    />
  </template>
  
  <script>
  import { nextTick } from "vue";
  import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
  import { mapGetters, mapActions } from "vuex"; // best practice for using actions and actions.
  import Card from "./global/Card.vue"; // card list integration.
  import Delete from "./dynamic/delete.vue"; // modal box for delete.
  import ModalLine from "./dynamic/modal.vue"; // modal box for edit and create.
  
  export default {
    components: {
      Card,
      Delete,
      ModalLine,
    },
    data() {
      return {
        show: {
          delete: false,
          edit: false,
          add: false,
        },
        form: {},
      };
    },
    computed: {
      ...mapGetters({
        listIntegration: "integration/integrationChannel",
      }),
    },
    async created() {
      this.getIntegrations("dynamic");
      this.$store.dispatch("ticket/getBranchOffice"); // dipanggil disini jadi ntar modal kebuka langsung ada
      this.$store.dispatch("ticket/getAllGroupManagement", ""); // dipanggil disini jadi ntar modal kebuka langsung ada
    },
    methods: {
      // ====================================================== ||
      // key di mapActions bisa di panggil seperti method biasa ||
      ...mapActions({
        getIntegrations: "integration/getIntegrationChannel",
        updateIntegration: "integration/updateIntegration",
        saveIntegration: "integration/saveIntegration",
        deleteIntegration: "integration/deleteIntegration",
      }),
      // ====================================================== ||
      // changeStatus func ==================================== ||
      // this only update status in omnichannel.integrations == ||
      // use cloneDeep if you want to edit data from vuex. ==== ||
      // change status by reverse current value status. ======= ||
      // need integrationId as id integration in backend. ===== ||
      // call func update to update all new data. ============= ||
      async changeStatus(data) {
        this.form = cloneDeep(data);
        this.form.status = !this.form.status;
        this.form.integrationId = this.form.id;
        await this.update();
        await nextTick();
        this.getIntegrations("dynamic"); // get latest integration from vuex.
      },
      // ====================================================== ||
      // on click add button ================================== ||
      // modal will appear after this method triggered. ======= ||
      showModalCreate() {
        this.form = false; // reset
        this.show.add = true;
      },
      // ====================================================== ||
      // on click edit button, ================================ ||
      // edit modal will appear after this method triggered. == ||
      // use cloneDeep if you want to edit data from vuex. ==== ||
      showModalEdit(data) {
        this.form = cloneDeep(data);
        this.show.edit = true;
      },
      // ====================================================== ||
      // on click delete button, ============================== ||
      // confirm modal will appear after this method triggered. ||
      // use cloneDeep if you want to edit data from vuex. ==== ||
      showModalDelete(data) {
        this.form = cloneDeep(data);
        this.show.delete = true;
      },
      // ====================================================== ||
      // submit integration =================================== ||
      // this will processess after submit button clicked ===== ||
      // first we restructure the data from modal box. ======== ||
      // and send it to create func =========================== ||
      // nextTick is a vue func =============================== ||
      // to make sure getIntegrations run after create func done||
      async onSubmit(data) {
        this.reform(data); // prepare the data
        await this.create(); // send to app project
        await nextTick();
        this.getIntegrations("dynamic"); // get latest integration from vuex.
      },
      // ====================================================== ||
      // update integration =================================== ||
      // this will processess after update button clicked ===== ||
      // first we restructure the data from modal box. ======== ||
      // and send it to update func =========================== ||
      // nextTick is a vue func =============================== ||
      // to make sure getIntegrations run after create func done||
      async onUpdate(data) {
        this.reform(data); // prepare the data
        await this.update(); // send to app project
        await nextTick();
        this.getIntegrations("dynamic"); // get latest integration from vuex.
      },
      // ====================================================== ||
      // delete integration =================================== ||
      // this will processess after delete button clicked ===== ||
      // and send it to delete func =========================== ||
      // nextTick is a vue func =============================== ||
      // to make sure getIntegrations run after create func done||
      async onDelete() {
        console.log("integration delete, id is ", this.form.id);
        await this.delete(); // send to app project
        await nextTick();
        this.getIntegrations("dynamic"); // get latest integration from vuex.
      },
      // ====================================================== ||
      // create func ========================================== ||
      // send form data to backend ============================ ||
      async create() {
        try {
          let response = await this.saveIntegration(this.form); // add integration.
          console.log("balikan data:", response);
          this.successToast("our data has been successfully added.");
          this.show.add = false;
        } catch (error) {
          console.log("error data:".error);
          this.errorToast("Oops! Your integration could not be added.");
        }
      },
      // ====================================================== ||
      // update func ========================================== ||
      // send form data to backend ============================ ||
      async update() {
        try {
          let response = await this.updateIntegration(this.form); // update integration.
          if (response.data.success) {
            this.successToast("Status has been successfully updated.");
            this.show.edit = false;
          }
        } catch (error) {
          this.errorToast("Oops! Your integration could not be updated.");
        }
      },
      // ====================================================== ||
      // delete func ========================================== ||
      // send form data to backend ============================ ||
      async delete() {
        let response = await this.deleteIntegration(this.form.id); // delete integration.
        if (response.data.success) {
          this.successToast("Your data has been successfully deleted.");
        } else {
          this.errorToast("Oops! Your integration could not be deleted.");
        }
      },
      // ====================================================== ||
      // reform is a helper func ============================== ||
      // its help to refactor modalbox data to form data ====== ||
      reform(data) {
        this.form = data;
        this.form.status = this.form.status ? this.form.status : false;
        this.form.channelName = "dynamic";
        this.form.integrationCode = data.integration_data.integrationCode;
        this.form.IntegrationName = data.integration_data.IntegrationName;
        this.form.channel = data.integration_data.channel;
        this.form.integrationId = this.form.id; // for update purpose
        console.log("reform data : ", this.form);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
  .list-move, /* apply transition to moving elements */
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
  
  /* ensure leaving items are taken out of layout flow so that moving
     animations can be calculated correctly. */
  .list-leave-active {
    position: absolute;
  }
  </style>
  
  <!-- || ================================================================== || -->
  <!-- || Note :  ========================================================== || -->
  <!-- || If you want to re-use this page to other channel ================= || -->
  <!-- || Just edit some points below ====================================== || -->
  <!-- ||  - edit card-title inside template =============================== || -->
  <!-- ||  - use your model base on your channel =========================== || -->
  <!-- ||  - Parsing your channel to getIntegrations func ================== || -->
  <!-- ||  - change reform func inside methods depending your data structure || -->
  <!-- || ================================================================== || -->
  