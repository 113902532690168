const listAgentTripa = [
  {
    path: "/:appId/agent-approval",
    name: "Agent Approval",
    component: () => import("@/views/agent-tripa/agent-list.vue"),
    meta: { title: "Agent List" },
  },
];

export default listAgentTripa;
