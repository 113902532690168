const state = {
  layoutType: "horizontal",
  layoutWidth: "fluid",
  sidebarSize: "lg",
  topbar: "light",
  mode: "light",
  position: "fixed",
  sidebarView: "default",
  sidebarColor: "dark",
  hideFooter: false,
  hideSidebar: false,
  paddingContent: "calc(70px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2)"
};

// export const getters = {
//   layoutType(state) {
//     return state.layoutType;
//   },
// };

const getters = {
  layoutType(state) {
    return state.layoutType;
  },
  hideFooter(state) {
    return state.hideFooter;
  },
};

const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  CHANGE_SIDEBAR_TYPE(state, sidebarSize) {
    state.sidebarSize = sidebarSize;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
  },
  CHANGE_MODE(state, mode) {
    state.mode = mode;
  },
  CHANGE_POSITION(state, position) {
    state.position = position;
  },
  CHANGE_SIDEBAR_VIEW(state, sidebarView) {
    state.sidebarView = sidebarView;
  },
  CHANGE_SIDEBAR_COLOR(state, sidebarColor) {
    state.sidebarColor = sidebarColor;
  },
  CHANGE_HIDE_FOOTER(state, payload) {
    state.hideFooter = payload;
  },
  CHANGE_HIDE_SIDEBAR(state, payload) {
    state.hideSidebar = payload;
  },
  // CHANGE_PADDING_CONTENT(state, payload) {
  //   state.
  // }
};

const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit("CHANGE_LAYOUT", layoutType);
    document.body.removeAttribute("style");
  },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit("CHANGE_LAYOUT_WIDTH", layoutWidth);
  },

  changeSidebarSize({ commit }, { sidebarSize }) {
    commit("CHANGE_SIDEBAR_TYPE", sidebarSize);
  },

  changeTopbar({ commit }, { topbar }) {
    commit("CHANGE_TOPBAR", topbar);
  },

  changeMode({ commit }, { mode }) {
    commit("CHANGE_MODE", mode);
  },

  changePosition({ commit }, { position }) {
    commit("CHANGE_POSITION", position);
  },

  changeSidebarView({ commit }, { sidebarView }) {
    commit("CHANGE_SIDEBAR_VIEW", sidebarView);
  },

  changeSidebarColor({ commit }, { sidebarColor }) {
    commit("CHANGE_SIDEBAR_COLOR", sidebarColor);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
