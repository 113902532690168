import moment from "moment";
class LennaMessageType {
  constructor(input) {
    this.uploadUrl = import.meta.env.VITE_OMNICHANNEL_URL + "/upload/chat";
    // this.uploadUrl = 'https://0577196e.ngrok.io/app/public/upload/chat'

    this.cloudStorageUrl = "https://storage.googleapis.com/lenna-dev";

    this.message = {
      temporary_id: moment().format("x"),
      messageable_id: input.messageable_id,
      messageable_type: "user_platform",
      room_id: input.roomId,
      created_at: null,
    };
  }
  template(input) {
    let content = [
      {
        type: "template",
        template: input.template || "",
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  text(input) {
    let content = [
      {
        type: "text",
        text: input.text || "",
        speech: input.text || "",
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  image(input) {
    let content = [
      {
        type: "image",
        originalContentUrl: input.fileUrl,
        previewImageUrl: input.fileUrl,
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  privateNoteImage(input) {
    let content = [
      {
        type: "private-note-image",
        originalContentUrl: input.fileUrl,
        previewImageUrl: input.fileUrl,
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  privateNoteFile(input) {
    let content = [
      {
        type: "private-note-file",
        fileUrl: input.fileUrl,
        fileName: input.fileUrl,
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  file(input) {
    let content = [
      {
        type: "file",
        fileUrl: input.fileUrl,
        fileName: input.fileName,
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  video(input) {
    let content = [
      {
        type: "video",
        fileName: input.fileName,
        originalContentUrl: input.fileUrl,
        previewImageUrl: input.fileUrl,
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
  privateNote(input) {
    let content = [
      {
        type: "private-note",
        text: input.text || "",
        speech: input.text || "",
      },
    ];
    return {
      ...this.message,
      content: content,
    };
  }
}
export default LennaMessageType;
