// import LennaMessageType from "@/helpers/messagetype.helper";
import {
  assignIn,
  find,
  findIndex,
  isUndefined,
  isNull,
  some,
  remove,
  cloneDeep,
} from "lodash";
import moment from "moment";

import { FeedService } from "@/services";

const state = {
  feedList: [],
  selectedFeed: null,
  unreadComment: null,
  current_status: "",
  selected_channels: [],
  search_keyword: "",
};

const getters = {
  feedList(state) {
    return state.feedList;
  },
  selectedFeed(state) {
    return state.selectedFeed;
  },
};

const mutations = {
  UPDATE_SELECTED_CHANNELS(state, channels) {
    state.selected_channels = channels;
  },
  HANDLE_NEW_COMMENT: (state, payload) => {
    let isExist = find(state.feedList, function (obj) {
      return obj.id == payload.feed.id;
    });
    if (isExist) {
      // is room exist
      let feedIndex = findIndex(state.feedList, function (obj) {
        return obj.id == payload.feed.id;
      });
      state.feedList[feedIndex].comments.push(payload.comment);
      state.feedList[feedIndex].updated_at = payload.feed.updated_at;
      return;
    } else {
      // if doesnt exist, add as new feed
      payload.feed.unread_count = 0;
      let feed = cloneDeep(payload.feed);
      assignIn(feed.comments[0], {
        userable: payload.comment.userable,
      });
      state.feedList.push(feed);
    }
  },
  SET_UNREAD_COUNT: (state, payload) => {
    let feedIndex = findIndex(state.feedList, function (obj) {
      return obj.id == payload.feed.id;
    });
    switch (payload.event) {
      case "select_feed":
        state.feedList[feedIndex].unread_count = 0;
        break;
      case "new_comment_selected":
        state.feedList[feedIndex].unread_count = 0;
        break;
      case "new_comment":
        state.feedList[feedIndex].unread_count++;
        break;
      default:
        break;
    }
  },
  SET_FEED_LIST: (state, payload) => {
    state.feedList = payload;
  },
  HANDLE_SELECT_FEED: (state, payload) => {
    state.selectedFeed = find(state.feedList, function (obj) {
      return obj.id == payload.id;
    });
  },
  ADD_MORE_COMMENTS: (state, payload) => {
    let feedIndex = findIndex(state.feedList, function (obj) {
      return obj.id == state.selectedFeed.id;
    });
    state.feedList[feedIndex].comments = payload.concat(
      state.feedList[feedIndex].comments
    );
  },
  HANDLE_SENT_COMMENT: (state, payload) => {
    console.log("handle sent", payload);
    const comment = {
      content: [
        {
          type: "text",
          text: payload.text,
        },
      ],
      created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      parent_id: payload.comment.id,
      id: payload.tmpId,
      temporary_id: payload.tmpId,
      feed_id: payload.comment.feed_id,
      userable: {
        name: payload.integration.name,
        picture: payload.integration.picture,
        id: moment().format("x"),
      },
      userable_id: moment().format("x"),
      userable_type: "user_platform",
    };
    let feedIndex = findIndex(state.feedList, function (obj) {
      return obj.id == payload.feedId;
    });
    state.feedList[feedIndex].comments.push(comment);
  },
  UPDATE_MESSAGE: (state, payload) => {
    console.log("update", payload);
    let commentIndex = findIndex(state.selectedFeed.comments, function (obj) {
      return obj.temporary_id == payload.temporaryId;
    });
    console.log("index", commentIndex);
    state.selectedFeed.comments[commentIndex] = payload.comment;
  },
};

const actions = {
  newComment(context, payload) {
    context.commit("HANDLE_NEW_COMMENT", payload);
    if (
      !isNull(context.getters.selectedFeed) &&
      context.getters.selectedFeed.id == payload.feed.id
    ) {
      context.dispatch("updateUnreadComment", {
        feed: payload.feed,
        senderId: context.rootGetters.localStorage.decodedUserId,
        forceUpdate: true,
      });
      context.commit("SET_UNREAD_COUNT", {
        ...payload,
        event: "new_comment_selected",
      });
    } else {
      context.commit("SET_UNREAD_COUNT", { ...payload, event: "new_comment" });
    }
  },
  async updateUnreadComment(context, payload) {
    // console.log("updateUnreadMessage", payload);
    let condition = !isUndefined(payload.feed.unread_count)
      ? payload.feed.unread_count > 0
      : payload.forceUpdate == true;
    if (condition) {
      //   ConversationService.updateUnreadMessage({
      //     roomId: payload.room.id,
      //     senderId: payload.senderId,
      //   });
    }
  },
  async getFeedList({ rootState, commit }, payload = {}) {
    Object.assign(payload, {
      status: rootState.comment.current_status,
      channel: rootState.comment.selected_channels,
    });
    console.log(payload);
    const { data: response } = await FeedService.getFeedList(payload);
    console.log(response);
    commit("SET_FEED_LIST", response.data);
    return response.data;
  },
  updateFeedList(context, payload) {
    context.commit("SET_FEED_LIST", payload);
  },
  async getMoreComment(context, payload) {
    try {
      const response = await FeedService.getMoreComments(payload);
      return response.data;
    } catch (error) {
      // Toast.error(error);
    }
  },
  async getMoreCommentTicket(context, payload) {
    try {
      const response = await FeedService.getMoreCommentsTicket(payload);
      return response.data;
    } catch (error) {
      // Toast.error(error);
    }
  },
  async selectFeed({ commit, dispatch }, payload) {
    dispatch("updateUnreadMessage", payload);
    commit("HANDLE_SELECT_FEED", payload.feed);
    commit("SET_UNREAD_COUNT", { ...payload, event: "select_feed" });
  },
  handleInfiniteScroll(context, payload) {
    context.commit("ADD_MORE_COMMENTS", payload.comments);
  },
  async sendComment(context, payload) {
    console.log("payload", payload);
    const tmpId = moment().format("x");
    const comment = [
      {
        text: payload.text,
        type: "text",
      },
    ];
    context.commit("HANDLE_SENT_COMMENT", {
      feedId: payload.feed.id,
      comment: payload.comment,
      text: payload.text,
      integration: payload.integration,
      tmpId: tmpId,
    });
    try {
      const response = await FeedService.sendComment({
        feedId: payload.feed.id,
        senderId: payload.senderId,
        commentId: payload.commentId,
        comment: comment,
        temporaryId: tmpId,
        ticketId: payload.ticketId,
      });
      context.commit("UPDATE_MESSAGE", response.data.data);
    } catch (error) {
      console.log("error", error);
      const e = error.response ? error.response.data.message : error;
      // Toast.error(e);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
