<template>
  <div 
    id="trial-alert" 
    :class="'d-flex w-100 justify-content-between align-items-center py-2 text-white bg-' + background">
    <div class="shape-left"></div>

    <div class="d-flex align-items-center">
      <i class="ri-information-line me-2"></i>
      <small v-if="props.remaining > 0">Your platform trial ends in {{ props.remaining }} days</small>
      <small v-else>Your platform trial has expired</small>

      <b-button
        class="btn"
        type="button"
        :variant="''"
        size="sm"
        :class="['px-4 btn-contact-sales ms-3 text-white']"
        @click="contactSales"
      >
        <small>Contact Sales</small>
      </b-button>
    </div>

    <div class="shape-right"></div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps } from 'vue';

const props = defineProps({
  remaining: Number
});

const background = ref(null);

const contactSales = async () => {
  const { data: { data } } = await httpPlain.get(`user-platform/user-data`);
  
  if(data.company) {
    const url = 'https://api.whatsapp.com/send/?phone=6285773749263&text=Halo%2C%20saya%20' + encodeURIComponent(data.name) + '%20dari%20' + encodeURIComponent(data.company.company_name) + '%20ingin%20menjadwalkan%20demo%20meeting%20untuk%20mengetahui%20solusi%20Lenna.ai%20lebih%20lanjut&type=phone_number';
    window.open(url, '_blank');

  } else {
    toast.error('Company data not found');
  }
}

onBeforeMount( () => {
  if(props.remaining > 3) {
    background.value = 'warning';
  } else if(props.remaining > 0) {
    background.value = 'danger';
  } else {
    background.value = 'dark';
  }
});
</script>

<style lang="scss">
div#trial-alert {
  position: fixed;
  top: 0;
  z-index: 999;
  overflow: hidden;

  &.bg-warning {
    background-color: #F6AB2F !important;
  }

  &.bg-danger {
    background-color: #E6492D !important;
  }

  &.bg-dark {
    background-color: #959595 !important;
  }

  .btn-contact-sales {
    border-radius: 16px;
    background-color: #D28B15 !important;

    &:hover {
      background-color: #aa6e09 !important;
    }
  }

  .shape-left,
  .shape-right {
    position: relative;
  }

  .shape-left::before,
  .shape-right::before {
    content: '';
    width: 45px;
    height: 45px;
    border-radius: 100%;
    position: absolute;
  }

  .shape-left::before {
    left: -15px;
    top: -40px;
  }

  .shape-right::before {
    right: -15px;
    top: -15px;
  }

  &.bg-warning {
    .shape-left::before {
      background-color: #F3644B;
    }

    .shape-right::before {
      background-color: #F8F2BC;
    }
  }

  &.bg-danger {
    .shape-left::before {
      background-color: #F4AA2F;
    }

    .shape-right::before {
      background-color: #F8CABC;
    }
  }

  &.bg-dark {
    .shape-left::before {
      background-color: #856F62;
    }

    .shape-right::before {
      background-color: #75809D;
    }
  }

  .shape-left::after,
  .shape-right::after {
    content: '';
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 100%;
    position: absolute;
  }

  .shape-left::after {
    top: -30px;
    left: 15px;
  }

  .shape-right::after {
    top: -20px;
    right: 20px;
  }
}
</style>