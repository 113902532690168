import moment from "moment";

const state = {
  dashboardData: null,
  dashboardDataChat: null,
  totalConversation: null,
  totalUser: null,
  totalTransaction: null,
  totalAmount: null,
  consumerBase: null,
  dailyMessage: null,
  dailyHsm: null,
  dailyOngoingRoomConversation: null,
  dailyResolvedRoomConversation: null,
  dailyUser: null,
  totalMessageAgent: null,
  dailiyMsgPerAgent: null,
  newConversationPerAgent: null,
  totalOngoingConvPeragent: null,
  totalResolvedConvPeragent: null,
  totalServedRoomConvPerGroupAgent: null,
  dailyNewConvPeragent: null,
  avgConvDurationPeragent: null,
  avgAgentFirstResponseTime: null,
  totalHsmTemplate: null,
  filterName: null,
  filterDate: {
    start: moment().subtract(7, "d").format("DD MMM YYYY HH:mm"),
    end: moment().format("DD MMM YYYY HH:mm"),
  },
  filterBU: [],
  overallMessages: null,
  overallLiveConversation: null,
  filterStoriesDashboard: false,
  listAgent: [],
  overallMessage: null,
  hsmData: null,
  isLoaded: {
    summary: false,
    hsm: false,
    conversation: false,
    performance: false,
    ticketVolume: false,
    reportDashboard: false,
  },
  performance: {},
  showFilter: true,
};
const getters = {
  overallMessages(state) {
    return state.overallMessages;
  },
  showFilter(state) {
    return state.showFilter;
  },
  overallLiveConversation(state) {
    return state.overallLiveConversation;
  },
  filterName(state) {
    return state.filterName;
  },
  dashboardData(state) {
    return state.dashboardData;
  },
  dashboardDataChat(state) {
    return state.dashboardDataChat;
  },
  totalMessageAgent(state) {
    return state.totalMessageAgent;
  },
  newConversationPerAgent(state) {
    return state.newConversationPerAgent;
  },
  totalOngoingConvPeragent(state) {
    return state.totalOngoingConvPeragent;
  },
  totalResolvedConvPeragent(state) {
    return state.totalResolvedConvPeragent;
  },
  totalServedRoomConvPerGroupAgent(state) {
    return state.totalServedRoomConvPerGroupAgent;
  },
  dailiyMsgPerAgent(state) {
    return state.dailiyMsgPerAgent;
  },
  dailyNewConvPeragent(state) {
    return state.dailyNewConvPeragent;
  },
  avgConvDurationPeragent(state) {
    return state.avgConvDurationPeragent;
  },
  avgAgentFirstResponseTime(state) {
    return state.avgAgentFirstResponseTime;
  },
  totalConversation(state) {
    return state.totalConversation;
  },
  totalUser(state) {
    return state.totalUser;
  },
  totalTransaction(state) {
    return state.totalTransaction;
  },
  totalAmount(state) {
    return state.totalAmount;
  },
  consumerBase(state) {
    return state.consumerBase;
  },
  dailyMessage(state) {
    return state.dailyMessage;
  },
  dailyHsm(state) {
    return state.dailyHsm;
  },
  dailyOngoingRoomConversation(state) {
    return state.dailyOngoingRoomConversation;
  },
  dailyResolvedRoomConversation(state) {
    return state.dailyResolvedRoomConversation;
  },
  dailyUser(state) {
    return state.dailyUser;
  },
  totalHsmTemplate(state) {
    return state.totalHsmTemplate;
  },
  listAgent(state) {
    return state.listAgent;
  },
  filterDate(state) {
    return state.filterDate;
  },
  filterBU(state) {
    return state.filterBU
  },
  filterStories(state) {
    return state.filterStoriesDashboard;
  },
  hsmData(state) {
    return state.hsmData;
  },
  getPerformance(state) {
    return state.performance;
  },
};
const mutations = {
  SET_OVERALL_MESSAGE: (state, payload) => {
    state.overallMessages = payload;
  },
  IS_FILTER: (state, payload) => {
    state.showFilter = payload;
  },
  SET_OVERALL_LIVE_CONVERSATION: (state, payload) => {
    state.overallLiveConversation = payload;
  },
  SET_FILTERNAME: (state, payload) => {
    state.filterName = payload;
  },
  SET_DASHBOARD_DATA: (state, payload) => {
    state.dashboardData = payload;
  },
  SET_DASHBOARD_DATA_CHAT: (state, payload) => {
    state.dashboardDataChat = payload;
  },
  SET_TOTAL_MESSAGE_AGENT: (state, payload) => {
    state.totalMessageAgent = payload;
  },
  SET_NEW_CONVERSATION_PERAGENT: (state, payload) => {
    state.newConversationPerAgent = payload;
  },
  SET_TOTAL_ONGOING_CONF_PERAGENT: (state, payload) => {
    state.totalOngoingConvPeragent = payload;
  },
  SET_TOTAL_RESOLVED_CONF_PERAGENT: (state, payload) => {
    state.totalResolvedConvPeragent = payload;
  },
  SET_TOTAL_SERVED_ROOM_CONV_PER_GROUPAGENT: (state, payload) => {
    state.totalServedRoomConvPerGroupAgent = payload;
  },
  SET_DAILY_MESSAGE_PERAGENT: (state, payload) => {
    state.dailiyMsgPerAgent = payload;
  },
  SET_DAILY_NEW_CONVERSATION_PERAGENT: (state, payload) => {
    state.dailyNewConvPeragent = payload;
  },
  SET_AVG_CONV_DURATION_PERAGENT: (state, payload) => {
    state.avgConvDurationPeragent = payload;
  },
  SET_AVG_AGENT_FIRST_RESPONSE_TIME: (state, payload) => {
    state.avgAgentFirstResponseTime = payload;
  },
  SET_TOTAL_CONVERSATION: (state, payload) => {
    state.totalConversation = payload;
  },
  SET_TOTAL_USER: (state, payload) => {
    state.totalUser = payload;
  },
  SET_TOTAL_TRANSACTION: (state, payload) => {
    state.totalTransaction = payload;
  },
  SET_TOTAL_AMOUNT: (state, payload) => {
    state.totalAmount = payload;
  },
  SET_CONSUMER_BASE: (state, payload) => {
    state.consumerBase = payload;
  },
  SET_DAILY_MESSAGE: (state, payload) => {
    state.dailyMessage = payload;
  },
  SET_DAILY_HSM: (state, payload) => {
    state.dailyHsm = payload;
  },
  SET_DAILY_ONGOING_ROOM_CONVERSATION: (state, payload) => {
    state.dailyOngoingRoomConversation = payload;
  },
  SET_DAILY_RESOLVED_ROOM_CONVERSATION: (state, payload) => {
    state.dailyResolvedRoomConversation = payload;
  },
  SET_DAILY_USER: (state, payload) => {
    state.dailyUser = payload;
  },
  SET_TOTAL_HSM_PERTEMPLATE: (state, payload) => {
    state.totalHsmTemplate = payload;
  },
  SET_LIST_AGENT: (state, payload) => {
    state.listAgent = payload;
  },
  SET_FILTER_DATE(state, payload) {
    state.filterDate = payload;
  },
  SET_FILTER_BU(state, payload) {
    state.filterBU = payload
  },
  SET_FILTER_STORIES(state, payload) {
    state.filterStoriesDashboard = payload;
  },
  SET_HSM_DATA(state, payload) {
    state.hsmData = payload;
  },
  RESET_IS_LOADED(state) {
    state.isLoaded = {
      summary: false,
      hsm: false,
      conversation: false,
      performance: false,
      ticketVolume: false,
      reportDashboard: false,
    };
  },
  SET_IS_LOADED(state, payload) {
    state.isLoaded[payload] = true;
  },
  SET_IS_PERFORMANCE(state, payload) {
    state.performance = payload;
  },
};
const actions = {
  showFilter(context, payload) {
    context.commit("IS_FILTER", payload);
  },
  setFilterDate(context, payload) {
    context.commit("SET_FILTER_DATE", payload);
  },
  setFilterBU(ctx, payload) {
    const BU = payload.map(item => item.id || item)
    ctx.commit('SET_FILTER_BU', BU)
  },
  setFilterStories(context, payload) {
    context.commit("SET_FILTER_STORIES", payload.bool);
  },
  async setFilterName(context, payload) {
    context.commit("SET_FILTERNAME", payload);
  },
  async getDashboardData(context, obj) {
    // return console.log('obj : ', obj.start);
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-data`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit("SET_DASHBOARD_DATA", response.data.data);
  },
  async getDashboardPerformance(context, payload) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/performance-data`,
      {
        params: payload,
      }
    );
    console.log("respone performance", payload);
    context.commit("SET_IS_PERFORMANCE", response.data.data);
  },
  async getDashboardDataChat(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-data-chat`
    );
    context.commit("SET_DASHBOARD_DATA_CHAT", response.data.data);
  },
  async getTotalMessageAgent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/total-message-agent`,
      {
        params: {
          ...obj,
        },
      }
    );
    context.commit("SET_TOTAL_MESSAGE_AGENT", response.data.data.agent);
  },
  async getNewConversationPerAgent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/new-conversation-per-agent`,
      {
        params: {
          ...obj,
        },
      }
    );
    context.commit(
      "SET_NEW_CONVERSATION_PERAGENT",
      response.data.data.new_conversation_per_agent
    );
  },
  async getTotalOngoingConvPeragent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/total-ongoing-conversation-per-agent`,
      {
        params: {
          ...obj,
        },
      }
    );
    context.commit(
      "SET_TOTAL_ONGOING_CONF_PERAGENT",
      response.data.data.total_ongoing_room_conversation
    );
  },
  async getTotalResolvedConvPeragent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/total-resolved-conversation-per-agent`,
      {
        params: {
          ...obj,
        },
      }
    );
    context.commit(
      "SET_TOTAL_RESOLVED_CONF_PERAGENT",
      response.data.data.total_resolved_room_conversation
    );
  },
  async getServedRoomConvPerGroupAgent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/served-room-conversation-per-group-agent`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit(
      "SET_TOTAL_SERVED_ROOM_CONV_PER_GROUPAGENT",
      response.data.data.served_room_conv_group_agent
    );
  },
  async getDailiyMsgPerAgent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/daily-messages-per-agent`,
      {
        params: {
          agentId: obj.agentId ? obj.agentId : null,
        },
      }
    );
    context.commit(
      "SET_DAILY_MESSAGE_PERAGENT",
      response.data.data.daily_messages_per_agent
    );
  },
  async getDailyNewConvPeragent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/daily-new-conversation-per-agent`,
      {
        params: {
          agentId: obj.agentId ? obj.agentId : null,
        },
      }
    );
    context.commit(
      "SET_DAILY_NEW_CONVERSATION_PERAGENT",
      response.data.data.daily_new_conversation_per_agent
    );
  },
  async getAvgConvDurationPeragent(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/average-conversation-duration-per-agent`,
      {
        params: {
          ...obj,
        },
      }
    );
    context.commit(
      "SET_AVG_CONV_DURATION_PERAGENT",
      response.data.data.average_conversation_duration_per_agent
    );
  },
  async getAvgAgentFirstResponseTime(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/average-first-response-time-per-agent`,
      {
        params: {
          ...obj,
        },
      }
    );
    context.commit(
      "SET_AVG_AGENT_FIRST_RESPONSE_TIME",
      response.data.data.average_first_response_time_per_agent
    );
  },
  async getTotalConversation(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-total-conversation`
    );
    context.commit("SET_TOTAL_CONVERSATION", response.data.data);
  },
  async getTotalUser(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-total-user`
    );
    context.commit("SET_TOTAL_USER", response.data.data);
  },
  async getTotalTransaction(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-total-transaction`
    );
    context.commit("SET_TOTAL_TRANSACTION", response.data.data);
  },
  async getTotalAmount(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-total-amount`
    );
    context.commit("SET_TOTAL_AMOUNT", response.data.data);
  },
  async getConsumerBase(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-consumer-base`
    );
    context.commit("SET_CONSUMER_BASE", response.data.data);
  },
  async getDailyMessage(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/daily-message`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit("SET_DAILY_MESSAGE", response.data.data);
  },
  async getDailyHsm(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/daily-hsm`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit("SET_DAILY_HSM", response.data.data);
  },
  async getDailyOngoingRoomConversation(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/daily-ongoing-room-conversation`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit("SET_DAILY_ONGOING_ROOM_CONVERSATION", response.data.data);
  },
  async getDailyResolvedRoomConversation(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/daily-resolved-room-conversation`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit("SET_DAILY_RESOLVED_ROOM_CONVERSATION", response.data.data);
  },
  async getTotalHsmPerTemplate(context, obj) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/total-hsm-pertemplate`,
      {
        params: {
          start: obj.start,
          end: obj.end,
        },
      }
    );
    context.commit("SET_TOTAL_HSM_PERTEMPLATE", response.data.data);
  },
  async getDailyUser(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-daily-user`
    );
    context.commit("SET_DAILY_USER", response.data.data);
  },
  async getListAgent(context) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/get-agent-list`
    );
    context.commit("SET_LIST_AGENT", response.data);
  },
  async getDataHsm(context, param) {
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/dashboard/hsm-data${param.param}`
    );
    context.commit("SET_HSM_DATA", response.data);
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
