import { httpClientOmni } from '@/libraries';
export default {
    async create(payload) {
        return await httpClientOmni.post('v3/reply-material', payload);
    },
    async edit(payload, id) {
        return await httpClientOmni.put('v3/reply-material/' + id, payload);
    },
    async delete(id) {
        return await httpClientOmni.delete('v3/reply-material/' + id);
    }
};
