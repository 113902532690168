<template>
  <b-modal
    v-model="modalShow"
    :title="title"
    hide-footer
    modal-class="flip"
    class="v-modal-custom"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
    size="lg"
    centered
  >
    <form action="javascript:void(0);">
      <b-row class="g-2">
        <!-- option integration start -->
        <!-- left -->
        <div class="col-lg-6 mt-3">
          <div class="dropzone position-relative">
            <div class="mb-1">
              <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
            </div>

            <h5>Drop files here or click to upload.</h5>
            <label for="dropzoneFile" class="bg-light text-dark stretched-link"
              >Upload</label
            >
            <input
              type="file"
              id="dropzoneFile"
              class="dropzoneFile btn btn-primary"
              @change="handleFileChange"
            />
          </div>
          <a href="/file/broadcast_sms.csv">download broadcast template</a>
          <div class="row mt-2">
            <div class="col">
              <b-button variant="soft-success" class="w-100" @click="addForm"
                ><i class="ri-image-edit-line"></i> Add Number</b-button
              >
            </div>
            <div class="col">
              <b-button
                variant="soft-success"
                class="w-100"
                @click="convertToArray"
                ><i class="ri-image-edit-line"></i> Export</b-button
              >
            </div>
          </div>
          <b-col xxl="12">
            <transition-group name="list">
              <div v-for="(form, index) in recipient" :key="index">
                <div class="row mt-3 g-0">
                  <div class="col-2">
                    <input
                      type="number"
                      class="form-control"
                      id="regionCode"
                      placeholder="Region Code"
                      v-model="form.regionCode"
                      required
                    />
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="number"
                      placeholder="Phone Number"
                      v-model="form.number"
                      required
                    />
                  </div>
                  <div class="col-1">
                    <button
                      type="button"
                      class="btn"
                      @click.prevent="deleteForm(index)"
                    >
                      <i class="ri-delete-bin-6-line text-danger"></i>
                    </button>
                  </div>
                </div>
              </div>
            </transition-group>
          </b-col>
        </div>
        <!-- right -->
        <div class="col-lg-6 mt-3">
          <div>
            <label for="topics" class="form-label"
              >Topics / Broadcast name<span text-danger>*</span></label
            >
            <input
              v-model="this.topics"
              type="text"
              class="form-control"
              id="topics"
              required
            />
          </div>
          <b-row class="g-2 my-2">
            <b-col>
              <div>
                <label class="form-label"
                  >integration<span text-danger>*</span></label
                >
                <v-select
                  label="label"
                  name="integration"
                  class="bg-white"
                  :options="optionsIntegration"
                  :placeholder="`Select your integration`"
                  v-model="integrationModel"
                  @option:selected="changeValueListIntegration"
                />
              </div>
            </b-col>
            <b-col>
              <div>
                <label class="form-label">Type</label>
                <v-select
                  label="label"
                  name="type"
                  class="bg-white"
                  :options="optionsType"
                  :placeholder="`Select your sender type`"
                  v-model="typeModel"
                  @option:selected="changeValueListType"
                />
              </div>
            </b-col>
          </b-row>
          <div>
            <label for="message" class="form-label"
              >Message<span text-danger>*</span></label
            >
            <textarea
              v-model="this.message"
              class="form-control"
              style="height: 130px"
              id="message"
              required
            ></textarea>
          </div>
        </div>
        <!-- bottom start-->
        <b-col lg="12">
          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="handleCloseModal">
              cancel</b-button
            >
            <b-button
              type="submit"
              variant="success"
              class="px-4"
              @click="handleSubmit"
              >Broadcast</b-button
            >
          </div>
        </b-col>
        <!-- bottom end-->
      </b-row>
    </form>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
import { useDropzone } from "vue3-dropzone"; // drop zone for image
// import { httpClientOmni } from "@/libraries";
export default {
  components: {
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: "Broadcast",
    },
  },
  setup() {
    function onDrop(acceptFiles, rejectReasons) {
      console.log(rejectReasons);
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      modalShow: this.showModal,
      integrationModel: null,
      typeModel: null,
      topics: null,
      message: "",
      recipient: [],
      optionsType: [
        { value: null, label: "No Label" },
        { value: "OTP", label: "OTP" },
        { value: "NOTIFY", label: "Notification" },
        { value: "MKT", label: "Marketing" },
        // Add more options as needed
      ],
    };
  },

  computed: {
    // when you try to use v-select ===================================================== ||
    // pls make sure you have key named label and value inside the json data ============ ||
    // if dont you can generate manually value and label using this func ================ ||
    // this func help us to restructure our json key pair =============================== ||
    // first this func will check is listIntegration empty or not ======================= ||
    // if not empty they will try to mapping new key pair for v-select options ========== ||
    optionsIntegration() {
      console.log("list integration");
      console.log(this.listIntegration);
      return this.listIntegration
        ? this.listIntegration.map((item) => ({
            value: item.id,
            label:
              item.integration_data.IntegrationName +
              " [" +
              (item.status ? "Active" : "Not Active") +
              "]",
          }))
        : [];
    },
    // dont forget this only getting data from vuex, ==================================== ||
    // make sure you call action to update this data before its show in modal box ======= ||
    ...mapGetters({
      listIntegration: "integration/integrationChannel",
    }),
  },
  watch: {
    // ================================================================================== ||
    // SHOW MODAL FUNC ================================================================== ||
    // first func running aftar modalbox trigged ======================================== ||
    showModal(newVal) {
      this.topics = null;
      this.integrationModel = null;
      this.modalShow = newVal;
      console.log("data form dari parent ", this.data);
      console.log("data list integration : ", this.listIntegration);
      if (this.data != null) {
        this.form = cloneDeep(this.data);
      }
    },
  },
  methods: {
    // ================================================================================== ||
    // handleCloseModal FUNC ============================================================ ||
    handleCloseModal() {
      this.$emit("onClose"); // Emit an event named "onClose" to notify parent components
      this.modalShow = false; // Set the value of modalShow to false, hiding the modal
      this.recipient = [];
      this.message = "";
      this.topics = "";
    },
    // ================================================================================== ||
    // handleSubmit FUNC ================================================================ ||
    // The && operator short-circuits if the left operand is falsy, ===================== ||
    // so the $emit call is only executed if the validate method returns a truthy value = ||
    handleSubmit() {
      console.log("data hasil");
      let dataForm = {
        topics: this.topics,
        integration_id: this.form.integration_id ?? null,
        type: this.form.type,
        message: this.message,
        recipient: this.recipient,
      };
      console.log(dataForm);
      this.validate()
        ? this.$emit("onSubmit", dataForm)
        : this.errorToast("Please complete all required fields");
    },
    // ================================================================================== ||
    // validate FUNC ==================================================================== ||
    validate() {
      // this is shorthand ============================================================== ||
      // If all fields are truthy, the expression inside the !! operator ================ ||
      // evaluates to true, and !! converts it to the boolean value true. =============== ||
      // If any of the fields are falsy, the expression evaluates to false, ============= ||
      // and !! converts it to the boolean value false. ================================= ||
      return !!(
        this.form.integration_id &&
        this.topics &&
        this.recipient &&
        this.recipient.length > 0 &&
        this.message
      );
    },
    // ================================================================================== ||
    // changeValueListIntegration FUNC ================================================== ||
    changeValueListIntegration(val) {
      this.integrationModel = val.label;
      this.form = { ...this.form, integration_id: val.value };
    },
    changeValueListType(val) {
      this.typeModel = val.label;
      this.form = { ...this.form, type: val.value };
    },
    addForm() {
      this.recipient.push({ regionCode: "", number: "" });
      console.log("form add : ", this.recipient);
    },
    deleteForm(index) {
      this.recipient.splice(index, 1);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileData = e.target.result;
      };
      reader.readAsText(file);
    },
    convertToArray() {
      const lines = this.fileData.split("\n");
      const dataArray = [];
      for (let line of lines) {
        // Skip the line with the header containing "regional_code"
        if (line.trim() === "regional_code") {
          continue;
        }
        if (line.trim() === "") continue; // Skip empty lines

        const [regionCode, number] = line.split(",");

        if (!isNaN(parseInt(regionCode)) && !isNaN(parseInt(number))) {
          dataArray.push({
            regionCode: parseInt(regionCode),
            number: parseInt(number),
          });
        }
      }
      console.log(this.recipient);
      this.recipient = dataArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
