<template>
  <div 
    id="layout-wrapper" 
    :class="{ showTopbarQuickstart: quickStart && trialAlert !== 0, showTopbarTrialAlert: isShowTrialAlert }">
    <QuickStart v-if="quickStart" :items="quickStart" />
    <TrialAlert v-if="isShowTrialAlert" :remaining="trialAlert" />
    <NavBar @setAlertTopbar="setAlertTopbar" />

    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div
        class="app-menu navbar-menu"
        :style="{ 'background-color': subSidebarColor }"
      >
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark" v-if="domain == 'damcorp'">
            <span class="logo-sm">
              <img src="@/assets/images/damcorp-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/damcorp-logo.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-dark" v-else-if="domain == 'bawaslu'">
            <span class="logo-sm">
              <img src="@/assets/images/bawaslu-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/bawaslu-logo.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-dark" v-else-if="domain == 'pgd-omni'">
            <span class="logo-sm">
              <img src="@/assets/images/pegadaian-workspace.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/pegadaian-workspace.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-dark" v-else>
            <span class="logo-sm">
              <img src="@/assets/images/lenna.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/lenna.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-dark" v-if="domain == 'damcorp'">
            <span class="logo-sm">
              <img src="@/assets/images/damcorp-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/damcorp-logo.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-dark" v-else-if="domain == 'bawaslu'">
            <span class="logo-sm">
              <img src="@/assets/images/bawaslu-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/bawaslu-logo.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-dark" v-else-if="domain == 'pgd-omni'">
            <span class="logo-sm">
              <img src="@/assets/images/pegadaian-workspace.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/pegadaian-workspace.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-light" v-else>
            <span class="logo-sm">
              <img src="@/assets/images/lenna.png" alt="" height="28" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/lenna.png" alt="" height="17" />
            </span>
          </router-link>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div class="container-fluid">
            <div id="two-column-menu">
              <simple-bar
                class="twocolumn-iconview list-unstyled"
                key="simplebar_twocolumn"
                :style="{ 'background-color': sidebarColor }"
              >
                <div class="">
                  <a a href="javascript:void(0)" @click="toAuth()" class="logo">
                    <img
                      src="@/assets/images/damcorp-logo.png"
                      alt="Logo"
                      height="22"
                      v-if="domain == 'damcorp'"
                    />
                    <img
                      src="@/assets/images/pegadaian-workspace.png"
                      alt="Logo"
                      height="22"
                      v-else-if="domain == 'pgd-omni'"
                    />
                    <img
                      src="@/assets/images/logo/lenna-white.svg"
                      alt="Logo"
                      height="28"
                      v-else
                    />
                  </a>
                </div>
                <li
                  v-for="(m, i) in modules"
                  :key="`modules_${i}`"
                  :id="`#sidebartooltip_${m.slug}`"
                  class="mt-2"
                >
                  <a
                    class="nav-icon"
                    :href="`#sidebar_${m.slug}`"
                    role="button"
                    @click.prevent="updateMenu(m)"
                  >
                    <i :class="m.icon"></i>

                    <b-tooltip
                      :target="`#sidebartooltip_${m.slug}`"
                      title="Tooltip title"
                      triggers="hover"
                    ></b-tooltip>
                  </a>
                  <span class="menu-name">{{ m.name }}</span>
                </li>
                <li class="menu-article menu-doc">
                  <a
                    class="nav-icon"
                    role="button"
                    id="popup-knowledge-base"
                    @click="showPopupKnowledgebase"
                    >
                    <i class="ri-information-fill text-primary"></i>
                  </a>

                  <b-popover 
                    target="popup-knowledge-base"
                    placement="right" 
                    triggers="click"
                    v-model:show="popupKnowledge">
                    <svg class="w-100" viewBox="0 0 448 324" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_6985_18508)">
                        <rect x="40" y="35" width="368" height="244" rx="16" fill="#FDDBCB"/>
                      </g>
                      <path d="M196.513 242.999H215.74H249.383C262.308 242.999 274.313 236.304 281.118 225.301L289.633 211.534C296.598 200.274 292.419 185.456 280.602 179.506C269.913 174.124 265.291 161.316 270.074 150.336L273.479 142.52C278.512 130.967 276.198 117.523 267.592 108.324L252.656 92.357C236.858 75.4691 210.234 75.0336 193.893 91.3958L175.251 110.064C172.829 112.488 169.064 112.937 166.142 111.148C161.451 108.277 155.38 111.338 154.892 116.821L152.579 142.779C152.07 148.49 149.488 153.81 144.911 157.256C135.884 164.053 123.43 172.427 121.152 177.999C105.096 217.268 154.135 242.999 196.513 242.999Z" fill="#E7E8FF"/>
                      <path d="M180.354 86.9958C180.354 84.7878 182.143 82.9978 184.352 82.9978H302.798C305.006 82.9978 306.796 84.7878 306.796 86.9958V191.66C306.796 193.868 305.006 195.658 302.798 195.658H184.352C182.143 195.658 180.354 193.868 180.354 191.66V86.9958Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M179.754 86.9957C179.754 84.4567 181.812 82.3984 184.351 82.3984H302.798C305.337 82.3984 307.395 84.4567 307.395 86.9957V191.659C307.395 194.198 305.337 196.257 302.798 196.257H184.351C181.812 196.257 179.754 194.198 179.754 191.659V86.9957ZM184.351 83.5969C182.474 83.5969 180.952 85.1186 180.952 86.9957V191.659C180.952 193.537 182.474 195.058 184.351 195.058H302.798C304.675 195.058 306.197 193.537 306.197 191.659V86.9957C306.197 85.1186 304.675 83.5969 302.798 83.5969H184.351Z" fill="#032068"/>
                      <path d="M189.941 105.17C189.941 104.177 190.746 103.372 191.739 103.372H221.702C222.695 103.372 223.5 104.177 223.5 105.17C223.5 106.163 222.695 106.968 221.702 106.968H191.739C190.746 106.968 189.941 106.163 189.941 105.17Z" fill="#F4AD5E"/>
                      <path d="M180.354 86.9958C180.354 84.7878 182.143 82.9978 184.352 82.9978H302.798C305.006 82.9978 306.796 84.7878 306.796 86.9958V96.7807H180.354V86.9958Z" fill="#032068"/>
                      <path d="M193.001 89.9873C193.001 91.6417 191.66 92.9828 190.005 92.9828C188.351 92.9828 187.01 91.6417 187.01 89.9873C187.01 88.3329 188.351 86.9917 190.005 86.9917C191.66 86.9917 193.001 88.3329 193.001 89.9873Z" fill="white"/>
                      <path d="M202.987 89.9873C202.987 91.6417 201.646 92.9828 199.992 92.9828C198.337 92.9828 196.996 91.6417 196.996 89.9873C196.996 88.3329 198.337 86.9917 199.992 86.9917C201.646 86.9917 202.987 88.3329 202.987 89.9873Z" fill="white"/>
                      <path d="M212.972 89.9873C212.972 91.6417 211.63 92.9828 209.976 92.9828C208.322 92.9828 206.98 91.6417 206.98 89.9873C206.98 88.3329 208.322 86.9917 209.976 86.9917C211.63 86.9917 212.972 88.3329 212.972 89.9873Z" fill="white"/>
                      <path d="M185.746 176.781C185.746 176.285 186.149 175.882 186.645 175.882H300.503C301 175.882 301.402 176.285 301.402 176.781C301.402 177.277 301 177.68 300.503 177.68H186.645C186.149 177.68 185.746 177.277 185.746 176.781Z" fill="#E7E8FF"/>
                      <path d="M185.746 181.575C185.746 181.078 186.149 180.676 186.645 180.676H300.503C301 180.676 301.402 181.078 301.402 181.575C301.402 182.071 301 182.474 300.503 182.474H186.645C186.149 182.474 185.746 182.071 185.746 181.575Z" fill="#E7E8FF"/>
                      <path d="M185.746 181.575C185.746 181.078 186.149 180.676 186.645 180.676H253.162C253.659 180.676 254.061 181.078 254.061 181.575C254.061 182.071 253.659 182.474 253.162 182.474H186.645C186.149 182.474 185.746 182.071 185.746 181.575Z" fill="#F4AD5E"/>
                      <path d="M255.859 181.575C255.859 182.733 254.92 183.672 253.761 183.672C252.603 183.672 251.664 182.733 251.664 181.575C251.664 180.417 252.603 179.478 253.761 179.478C254.92 179.478 255.859 180.417 255.859 181.575Z" fill="#032068"/>
                      <path d="M202.525 189.365L197.582 192.22L197.582 186.511L202.525 189.365Z" fill="#032068"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M210.466 188.673V186.669H211.665V189.365V192.062H210.466V190.057L206.721 192.22V186.511L210.466 188.673Z" fill="#032068"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M189.343 188.673V186.669H188.144V189.365V192.062H189.343V190.057L193.088 192.22V186.511L189.343 188.673Z" fill="#032068"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M289.418 187.867H283.425L283.425 191.463H289.418V187.867ZM283.425 187.268C283.094 187.268 282.826 187.536 282.826 187.867V191.463C282.826 191.794 283.094 192.062 283.425 192.062H289.418C289.749 192.062 290.017 191.794 290.017 191.463V187.867C290.017 187.536 289.749 187.268 289.418 187.268H283.425Z" fill="#E7E8FF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M301.402 187.867H295.41L295.41 191.463H301.402V187.867ZM295.41 187.268C295.079 187.268 294.811 187.536 294.811 187.867V191.463C294.811 191.794 295.079 192.062 295.41 192.062H301.402C301.733 192.062 302.002 191.794 302.002 191.463V187.867C302.002 187.536 301.733 187.268 301.402 187.268H295.41Z" fill="#E7E8FF"/>
                      <path d="M278.032 189.665C278.032 191.32 276.69 192.661 275.035 192.661C273.381 192.661 272.039 191.32 272.039 189.665C272.039 188.01 273.381 186.669 275.035 186.669C276.69 186.669 278.032 188.01 278.032 189.665Z" fill="#E7E8FF"/>
                      <path d="M263.65 143.522C263.65 154.444 254.797 163.298 243.875 163.298C232.953 163.298 224.1 154.444 224.1 143.522C224.1 132.601 232.953 123.747 243.875 123.747C254.797 123.747 263.65 132.601 263.65 143.522Z" fill="#E7E8FF"/>
                      <path d="M219.305 190.057V188.228C219.305 187.965 219.476 187.732 219.728 187.655L221.971 186.965C222.275 186.871 222.601 187.031 222.698 187.334C223.17 188.811 223.103 189.727 222.701 190.952C222.601 191.254 222.275 191.413 221.971 191.32L219.728 190.63C219.476 190.552 219.305 190.32 219.305 190.057Z" fill="#E7E8FF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M224.239 191.98C223.995 191.858 223.898 191.562 224.002 191.31C224.642 189.759 224.641 188.108 224.033 186.743C223.923 186.494 224.002 186.193 224.239 186.058C224.476 185.923 224.779 186.004 224.894 186.251C225.674 187.925 225.66 189.926 224.887 191.752C224.781 192.003 224.483 192.102 224.239 191.98Z" fill="#E7E8FF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M226.706 189.098C226.729 188.105 226.51 187.069 225.958 185.725C225.854 185.473 225.965 185.182 226.214 185.071C226.464 184.961 226.756 185.073 226.86 185.324C227.454 186.761 227.721 187.944 227.693 189.121C227.666 190.279 227.354 191.393 226.853 192.693C226.755 192.947 226.467 193.069 226.214 192.967C225.962 192.865 225.84 192.577 225.938 192.323C226.418 191.074 226.683 190.089 226.706 189.098Z" fill="#E7E8FF"/>
                      <path d="M215 136.798C215 135.805 215.805 135 216.798 135H246.76C247.753 135 248.558 135.805 248.558 136.798C248.558 137.791 247.753 138.596 246.76 138.596H216.798C215.805 138.596 215 137.791 215 136.798Z" fill="#F4AD5E"/>
                      <path d="M224 147.798C224 146.805 224.805 146 225.798 146H255.76C256.753 146 257.558 146.805 257.558 147.798C257.558 148.791 256.753 149.596 255.76 149.596H225.798C224.805 149.596 224 148.791 224 147.798Z" fill="#F4AD5E"/>
                      <path d="M266.344 162.047C266.344 160.253 267.802 158.799 269.6 158.799H309.836C311.635 158.799 313.093 160.253 313.093 162.047V240.055C313.093 241.848 311.635 243.302 309.836 243.302H269.6C267.802 243.302 266.344 241.848 266.344 240.055V162.047Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M266.047 162.047C266.047 160.09 267.637 158.504 269.599 158.504H309.835C311.797 158.504 313.388 160.09 313.388 162.047V240.055C313.388 242.012 311.797 243.598 309.835 243.598H269.599C267.637 243.598 266.047 242.012 266.047 240.055V162.047ZM269.599 159.094C267.964 159.094 266.639 160.416 266.639 162.047V240.055C266.639 241.685 267.964 243.007 269.599 243.007H309.835C311.47 243.007 312.796 241.685 312.796 240.055V162.047C312.796 160.416 311.47 159.094 309.835 159.094H269.599Z" fill="#032068"/>
                      <path d="M280.408 161.607L278.906 158.799H300.528L298.76 161.736C298.225 162.626 297.262 163.17 296.222 163.17H283.02C281.927 163.17 280.922 162.569 280.408 161.607Z" fill="#032068"/>
                      <path d="M312.518 160.547H266.617C268.137 159.032 269.588 158.75 270.123 158.799H309.011C310.881 158.799 312.128 159.964 312.518 160.547Z" fill="#032068"/>
                      <path d="M312.497 241.645H266.939C268.518 243.185 269.431 243.471 269.988 243.422H309.449C311.393 243.422 312.093 242.237 312.497 241.645Z" fill="#032068"/>
                      <path d="M270.42 169.269C270.42 168.78 270.818 168.378 271.308 168.378H286.207C286.697 168.378 287.095 168.78 287.095 169.269C287.095 169.758 286.697 170.16 286.207 170.16H271.308C270.818 170.16 270.42 169.758 270.42 169.269Z" fill="#F4AD5E"/>
                      <path d="M270.42 215.721C270.42 215.24 270.811 214.85 271.294 214.85H297.213C297.696 214.85 298.087 215.24 298.087 215.721C298.087 216.202 297.696 216.593 297.213 216.593H271.294C270.811 216.593 270.42 216.202 270.42 215.721Z" fill="#F4AD5E"/>
                      <path d="M270.42 220.969C270.42 220.48 270.818 220.078 271.308 220.078H286.207C286.697 220.078 287.095 220.48 287.095 220.969C287.095 221.458 286.697 221.86 286.207 221.86H271.308C270.818 221.86 270.42 221.458 270.42 220.969Z" fill="#F4AD5E"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M270.125 175.944C270.125 175.292 270.655 174.763 271.309 174.763H307.976C308.63 174.763 309.16 175.292 309.16 175.944V209.607C309.16 210.26 308.63 210.789 307.976 210.789H271.309C270.655 210.789 270.125 210.26 270.125 209.608V175.944ZM271.309 175.354C270.982 175.354 270.717 175.618 270.717 175.944V209.608C270.717 209.934 270.982 210.198 271.309 210.198H307.976C308.303 210.198 308.568 209.934 308.568 209.607V175.944C308.568 175.618 308.303 175.354 307.976 175.354H271.309Z" fill="#E7E8FF"/>
                      <path d="M299.253 192.776C299.253 198.069 294.95 202.361 289.642 202.361C284.334 202.361 280.031 198.069 280.031 192.776C280.031 187.482 284.334 183.191 289.642 183.191C294.95 183.191 299.253 187.482 299.253 192.776Z" fill="#E7E8FF"/>
                      <path d="M292.918 231.551L287.457 234.443L287.457 228.658L292.918 231.551Z" fill="#032068"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M301.234 230.805V228.645H302.529V234.456H301.234V232.296L297.188 234.626V228.475L301.234 230.805Z" fill="#032068"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M278.414 230.805V228.645H277.12V234.456H278.414V232.296L282.461 234.626L282.461 228.475L278.414 230.805Z" fill="#032068"/>
                      <path d="M160.473 89.8479L157.838 95.8594C157.678 96.2252 157.328 96.4693 156.932 96.4925L151.89 96.7867C150.805 96.85 150.509 98.3206 151.482 98.8137L156.087 101.148C156.423 101.318 156.646 101.654 156.673 102.032L157.078 107.68C157.154 108.742 158.552 109.061 159.066 108.134L161.912 103.003C162.079 102.702 162.38 102.501 162.721 102.464L167.374 101.957C168.422 101.843 168.677 100.424 167.736 99.9381L163.654 97.8282C163.326 97.6587 163.107 97.3308 163.076 96.9612L162.504 90.1984C162.411 89.0924 160.917 88.8346 160.473 89.8479Z" fill="#F4AD5E"/>
                      <path d="M140.464 119.623L141.155 121.371C141.303 121.744 141.232 122.167 140.972 122.469L140.289 123.263C139.576 124.091 140.419 125.341 141.447 124.98L142.499 124.61C142.853 124.485 143.247 124.558 143.535 124.803L144.756 125.838C145.562 126.522 146.754 125.74 146.457 124.722L145.964 123.025C145.867 122.693 145.935 122.335 146.146 122.064L146.787 121.24C147.439 120.402 146.604 119.218 145.604 119.561L144.694 119.873C144.347 119.993 143.962 119.922 143.677 119.686L142.122 118.402C141.271 117.699 140.056 118.594 140.464 119.623Z" fill="#F4AD5E"/>
                      <path d="M324.436 131.182L322.459 133.743C322.339 133.899 322.143 133.974 321.951 133.937L319.507 133.474C318.982 133.375 318.664 134.043 319.07 134.395L320.99 136.064C321.131 136.186 321.197 136.373 321.165 136.557L320.683 139.308C320.592 139.825 321.222 140.145 321.578 139.763L323.551 137.649C323.667 137.525 323.835 137.465 324.002 137.488L326.285 137.803C326.799 137.874 327.09 137.225 326.699 136.88L325.001 135.382C324.865 135.261 324.8 135.078 324.829 134.897L325.365 131.593C325.452 131.052 324.769 130.75 324.436 131.182Z" fill="#F4AD5E"/>
                      <path d="M218.797 222.704C213.049 222.082 205.721 236.45 202.873 243.518H207.145C213.36 236.969 224.545 223.327 218.797 222.704Z" fill="#032068"/>
                      <path d="M217.825 201.502C206.484 202.436 199.636 229.642 197.629 243.129H205.008C214.006 228.864 229.166 200.568 217.825 201.502Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M220.585 202.727C220.049 202.143 219.194 201.819 217.861 201.929C215.198 202.148 212.741 203.916 210.504 206.768C208.272 209.614 206.309 213.481 204.625 217.765C201.341 226.123 199.16 235.981 198.127 242.7H204.773C209.252 235.579 215.196 225.081 218.647 216.457C220.394 212.091 221.477 208.264 221.445 205.611C221.429 204.287 221.135 203.328 220.585 202.727ZM219.441 216.775C215.93 225.551 209.875 236.216 205.371 243.358L205.245 243.558H197.133L197.206 243.066C198.212 236.302 200.433 226.095 203.829 217.451C205.526 213.131 207.526 209.178 209.831 206.239C212.131 203.306 214.783 201.322 217.791 201.075C219.292 200.951 220.445 201.307 221.215 202.147C221.971 202.972 222.284 204.182 222.301 205.601C222.335 208.437 221.192 212.401 219.441 216.775Z" fill="#032068"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M215.019 211.953C216.05 209.542 217.007 207.299 217.83 205.366L217.043 205.03C216.107 207.227 214.999 209.825 213.804 212.615C212.907 214.711 211.96 216.916 211.003 219.142L208.889 214.738L208.118 215.109L210.554 220.185C209.3 223.096 208.036 226.019 206.845 228.758C206.207 230.226 205.589 231.641 205.005 232.973L203.668 228.798L202.853 229.06L204.485 234.157C203.388 236.65 202.434 238.792 201.715 240.361C201.348 241.161 201.044 241.81 200.815 242.278C200.7 242.513 200.605 242.7 200.531 242.836C200.494 242.905 200.464 242.958 200.44 242.997C200.42 243.029 200.41 243.044 200.407 243.048C200.406 243.05 200.406 243.05 200.407 243.049L201.064 243.598C201.132 243.516 201.209 243.382 201.284 243.244C201.365 243.093 201.466 242.895 201.583 242.656C201.818 242.177 202.125 241.521 202.493 240.719C203.229 239.113 204.209 236.912 205.334 234.354C206.028 232.775 206.778 231.06 207.559 229.263L212.349 227.383L212.037 226.585L208.042 228.153C209.136 225.634 210.281 222.983 211.417 220.345C212.478 217.882 213.53 215.43 214.524 213.108L218.774 211.231L218.429 210.446L215.019 211.953Z" fill="#032068"/>
                      <path d="M161.006 138.848C153.667 144.031 156.751 153.244 158.134 156.814L157.528 164.099C158.156 166.392 160.432 170.979 164.516 170.979C168.6 170.979 170.472 167.063 170.898 165.106L170.26 159.578C175.046 157.85 175.365 150.94 175.046 145.413C174.667 138.848 167.47 134.284 161.006 138.848Z" fill="#EFCB96"/>
                      <path d="M158.365 155.008L157.73 161.272C157.518 160.808 157.03 159.462 156.776 157.792C156.458 155.704 151.372 147.699 152.961 141.783C154.233 137.05 157.73 137.259 159.319 137.955C160.273 136.215 165.995 130.299 174.261 130.995C182.526 131.691 183.48 143.523 176.804 145.263C171.463 146.655 165.465 145.843 163.134 145.263C162.922 145.727 162.307 147.282 161.545 149.788C160.591 152.92 160.591 153.616 160.273 151.528C159.955 149.44 158.683 145.611 157.094 149.44C155.822 152.502 157.412 154.428 158.365 155.008Z" fill="#032068"/>
                      <path d="M162.984 159.452C165.172 160.738 168.554 159.988 169.972 159.452L170.276 161.703C167.116 162.474 164.098 160.524 162.984 159.452Z" fill="#032068"/>
                      <path d="M164.236 167.7C168.697 167.7 171.064 165.26 171.827 163.817C171.911 163.658 172.075 163.549 172.255 163.552C186.28 163.781 196.036 180.056 199.228 188.337C199.258 188.415 199.264 188.497 199.248 188.58L195.428 207.942C195.418 207.994 195.417 208.045 195.425 208.098C196.813 216.726 199.209 234.654 197.788 237.965C196.363 241.285 182.942 243.598 171.956 243.598C152.953 243.598 128.426 243.123 125.338 240.04C121.478 236.186 126.526 188.157 135.137 176.002C141.874 166.492 152.094 163.39 156.522 163.535C156.701 163.541 156.849 163.66 156.924 163.822C157.587 165.266 159.779 167.7 164.236 167.7Z" fill="#F4AD5E"/>
                      <path d="M142.858 232.925C138.582 228.418 132.762 214.247 130.387 207.725C130.981 216.718 138.998 237.075 142.858 237.075C151.409 238.973 172.352 225.217 181.755 218.101C170.571 224.92 147.133 237.431 142.858 232.925Z" fill="#032068"/>
                      <path d="M187.99 182.524L190.959 191.419H193.038L187.99 182.524Z" fill="#032068"/>
                      <path d="M155.03 232.628C147.904 226.699 140.184 225.612 137.215 225.809C138.007 228.478 141.253 233.696 144.341 234.407C147.429 235.119 152.457 233.617 155.03 232.628Z" fill="#032068"/>
                      <path d="M157.999 206.242L154.733 190.529L161.265 205.353C167.204 203.871 173.637 203.871 177.003 203.871C163.344 206.242 156.812 208.911 150.576 210.689L157.999 206.242Z" fill="#032068"/>
                      <path d="M183.536 202.685C182.111 203.396 179.775 205.55 178.785 206.539L180.567 214.247C181.992 214.01 185.713 212.962 187.396 212.468L187.99 198.534C185.021 199.423 185.318 201.795 183.536 202.685Z" fill="#EFCB96"/>
                      <path d="M188.559 190.876L186.548 219.845C186.527 220.153 186.815 220.39 187.114 220.311L212.935 213.436C213.112 213.389 213.243 213.239 213.266 213.057L216.713 185.807C216.751 185.503 216.483 185.251 216.182 185.308L188.925 190.465C188.723 190.503 188.573 190.672 188.559 190.876Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M216.098 184.865C216.699 184.752 217.237 185.257 217.16 185.863L213.713 213.113C213.667 213.477 213.405 213.776 213.05 213.871L187.23 220.746C186.632 220.905 186.055 220.43 186.098 219.813L188.109 190.845C188.137 190.436 188.438 190.098 188.84 190.022L216.098 184.865L216.182 185.308L216.098 184.865ZM216.265 185.75L189.008 190.907L186.998 219.876L212.818 213.001L216.265 185.75Z" fill="#032068"/>
                      <path d="M205.61 203.725C204.765 206.889 202.538 209.044 200.637 208.537C198.736 208.031 197.88 205.055 198.725 201.891C199.57 198.727 201.797 196.573 203.699 197.079C205.6 197.586 206.456 200.561 205.61 203.725Z" fill="#F4AD5E"/>
                      <path d="M211.15 203.278C211.372 199.734 213.682 197.982 215.035 197.444C215.199 197.379 215.322 197.236 215.345 197.062L215.839 193.362C215.872 193.11 216.112 192.932 216.346 193.033C217.918 193.712 219.116 196.564 218.573 200.906C217.979 205.649 215.307 207.725 214.119 207.725C212.931 207.725 210.853 208.021 211.15 203.278Z" fill="#EFCB96"/>
                      <defs>
                        <filter id="filter0_d_6985_18508" x="0" y="0" width="448" height="324" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="5"/>
                          <feGaussianBlur stdDeviation="20"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6985_18508"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6985_18508" result="shape"/>
                        </filter>
                      </defs>
                    </svg>

                    <div class="px-4 pb-3">
                      <h5>Help when you need it</h5>
                      <small class="text-muted">Visit the “Knowledge base” for tutorials, FAQs, support and more.</small>

                      <div class="d-flex align-items-center mt-3">
                        <a 
                          @click="hidePopupKnowledgebase(true)"  
                          class="bg-info text-white rounded px-3 py-2 me-4">
                          Go to Knowledge Base
                        </a>

                        <a @click="hidePopupKnowledgebase(false)" class="text-muted">Okay, got it</a>
                      </div>
                    </div>
                  </b-popover>
                </li>
              </simple-bar>
            </div>
            <template v-if="layoutType === 'twocolumn'">
              <simple-bar
                class="navbar-nav"
                key="simplebar_twocolumn"
                id="navbar-nav"
              >
                <li class="menu-title">
                  <span data-key="t-menu">Menu</span>
                </li>
                <li
                  class="nav-item"
                  v-for="(m, i) in modules"
                  :key="`modules_${m.slug}_${i}`"
                >
                  <div
                    class="collapse menu-dropdown"
                    v-if="size(m.v3_menus) > 0 && m.type == 'indirect'"
                    :id="`sidebar_${m.slug}`"
                  >
                    <ul class="nav nav-sm nav-sm-menu flex-column">
                      <!-- Menu sidebar -->
                      <li
                        v-for="(menu, i) in m.v3_menus"
                        :key="`menus_${i}`"
                        class="nav-item"
                        :class="{ 'd-none': ![680,704].includes($store.getters.appId.decoded) && menu.slug == 'voip' }"
                      >
                        <!-- if menu null -->
                        <router-link
                          v-if="size(m.v3_menus) == 0"
                          :to="{ name: m.slug }"
                          class="collapsed nav-link nav-link-menu"
                          :class="
                            `${url[2]}/${url[3]}` == `${m.route}/${menu.url}`
                              ? 'active'
                              : `${url[2]}.${url[3]}_${m.route}.${menu.url}`
                          "
                          :data-key="`t-${m.slug}.${menu.slug}`"
                        >
                          <i :class="menu.icon"></i>
                          <!-- {{ $t(`t-${m.slug}.${menu.slug}`) }} -->
                          {{ menu.slug }}
                        </router-link>
                        <!-- if menu > 0 -->
                        <router-link
                          v-else
                          :to="{ name: `${m.slug}.${menu.slug}` }"
                          class="nav-link nav-link-menu collapsed text-dark text-uppercase fw-semibold"
                          role="button"
                          :class="
                            `${url[2]}/${url[3]}` == `${m.route}/${menu.url}`
                              ? 'active'
                              : `${url[2]}.${url[3]}_${m.route}.${menu.url}`
                          "
                          aria-expanded="true"
                          :aria-controls="`sidebar_${m.slug}_${menu.slug}`"
                          :data-key="`t-${m.slug}.${menu.slug}`"
                        >
                          <!-- <i :class="menu.icon"></i> -->
                          <!-- {{ $t(`t-${m.slug}.${menu.slug}`) }} -->
                          {{ menu.name }}
                        </router-link>
                        <!-- sub menu -->
                        <div
                          v-if="size(menu.submenus) > 0"
                          class="menu-dropdown"
                          :id="`sidebar_${m.slug}_${menu.slug}`"
                        >
                          <ul
                            class="nav nav-sm flex-column"
                            style="padding-left: 5px"
                          >
                            <li
                              class="nav-item"
                              v-for="(sm, idxSubMenu) in menu.submenus"
                              :key="`submenu_${idxSubMenu}`"
                              :class="{ 'd-none': ![680,704].includes($store.getters.appId.decoded) && sm.slug == 'call' }"
                            >
                              <router-link
                                @click="closeSidebar"
                                v-if="size(sm.sub_submenus) == 0 && !(sm.slug === 'create-ticket' && userRole?.slug === 'driver')"
                                class="nav-link text-dark"
                                style="padding-left: 0.5rem !important"
                                :class="
                                  `${$route.name}` == `${menu.slug}.${sm.slug}`
                                    ? 'active'
                                    : ''
                                "
                                :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}`"
                                :to="{
                                  name: `${m.slug}.${menu.slug}.${sm.slug}`,
                                }"
                              >
                                <i class="text-dark" :class="sm.icon"></i>
                                {{ `${sm.name}` }}
                              </router-link>
                              <!-- <a
                                v-if="size(sm.sub_submenus) > 0"
                                href="javascript:void(0)"
                                class="nav-link"
                                role="button"
                                aria-expanded="false"
                                :aria-controls="`sidebar_${menu.slug}_${sm.slug}`"
                                :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}`"
                              >
                                {{ $t(`t-${m.slug}.${menu.slug}.${sm.slug}`) }}
                              </a>
                              <div
                                v-if="size(sm.sub_submenus) > 0"
                                class="menu-dropdown"
                                :id="`sidebar_${menu.slug}_${sm.slug}`"
                              >
                                <ul class="nav nav-sm flex-column">
                                  <li
                                    class="nav-item"
                                    v-for="(ssm, ssmIndex) in sm.sub_submenus"
                                    :key="ssmIndex"
                                  >
                                    <a
                                      href="#"
                                      class="nav-link"
                                      :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}.${ssm.slug}`"
                                    >
                                      {{
                                        $t(
                                          `t-${m.slug}.${menu.slug}.${sm.slug}.${ssm.slug}`
                                        )
                                      }}
                                    </a>
                                  </li>
                                </ul>
                              </div> -->
                            </li>
                            <!-- <li
                              :key="`modules_article`"
                              :id="`#sidebartooltip_article`"
                              class="menu-article"
                            >
                              <i class="ri-article-line"></i>
                              <a
                                title="Documentation"
                                class="nav-link menu-link"
                                target="_blank"
                                href="https://lenna-v3.document360.io/docs/user-manual-lenna-platform-v3"
                              >
                                <i
                                  style="margin-left: 20px"
                                  class="ri-dashboard-2-fill"
                                ></i>
                                <span data-key="t-Documentation"
                                  >Documentation</span
                                >
                              </a>
                            </li> -->
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </simple-bar>
            </template>
          </div>
        </div>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div
        class="page-content"
        :style="hideFooter ? 'padding-bottom: 0px' : ''"
      >
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <BaseFooter v-if="!hideFooter" />
    </div>
    <RightBar />

    <div class="modal-backdrop fade show" v-if="popupKnowledge"></div>
  </div>
</template>
<script>
import router from "@/router";
import { layoutComputed } from "@/store/helpers";
import { SimpleBar } from "simplebar-vue3";
import Hashids from "hashids";
import NavBar from "@/components/nav-bar.vue";
import QuickStart from "@/components/QuickStart.vue";
import TrialAlert from "@/components/TrialAlert.vue";
import RightBar from "@/components/right-bar.vue";
import Footer from "@/components/footer.vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import {
  sortBy,
  filter,
  clone,
  find,
  findIndex,
  some,
  size,
  debounce,
} from "lodash";
import { mapGetters } from "vuex";
import Toast from "@/helpers/toast.helper";
import { shallowReactive } from "vue";
/**
 * Vertical layout
 */
export default {
  components: { 
    NavBar, 
    RightBar, 
    BaseFooter: Footer, 
    SimpleBar, 
    QuickStart, 
    TrialAlert
  },
  data() {
    return {
      size,
      debounce,
      isMenuCondensed: false,
      domain: window.location.hostname.split(".")[0],
      knowledgeBaseUrl: `${import.meta.env.VITE_URL}${this.$store.getters.appId.hashed}/knowledgebase`,
      popupKnowledge: false,
      quickStart: undefined,
      trialAlert: undefined,
      isShowTrialAlert: false,
    };
  },
  computed: {
    ...layoutComputed,
    modules() {
      let module = this.$store.getters["sidebar/modules"];
      return sortBy(module, ["order"]);
    },
    ...mapGetters({
      userRole: "accessibility/userRole",
      access: "accessibility/getAccess",
      hideFooter: "layout/hideFooter",
      loading: "loading/isLoading",
      userRole: "accessibility/userRole",
    }),
    accessibilityUrlV3() {
      return (
        import.meta.env.VITE_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/v3/accessibility`
      );
    },
    url() {
      return this.$route.path.split("/");
    },
    sidebarColor() {
      const domain = window.location.hostname.split(".")[0];
      if (domain == "damcorp") {
        return "#1E5A59";
      } else if (domain == 'pgd-omni') {
        return "#128145";
      } else {
        return "#14344C";
      }
    },
    subSidebarColor() {
      const domain = window.location.hostname.split(".")[0];
      if (domain == "damcorp") {
        return "#4b7b7a";
      } else {
        return "#f5f5f7";
      }
    },
  },
  created() {
    // document.body.removeAttribute("data-layout", "horizontal");
    // document.body.removeAttribute("data-topbar", "dark");
    // document.body.removeAttribute("data-layout-size", "boxed");
    // this.initActiveMenu();
    this.throttle();
  },
  mounted() {
    document.body.removeAttribute("data-layout", "vertical");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    this.initActiveMenu();
    // this.accessibilityChecker();

    const isPopupKnowledgebase = window.localStorage.getItem('_popup_knowledgebase') === 'true';
    if(isPopupKnowledgebase && this.$store.getters.appId) {
      setTimeout( () => {
        document.getElementById('popup-knowledge-base').click();
      }, 1_000);
    }
  },
  // beforeMount() {
  //   this.accessibilityChecker();
  // },
  methods: {
    closeSidebar() {
      this.toggleHamburgerMenu();
      console.log("congrats, your sub menu clicked :)");
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    throttle: debounce(function () {
      this.accessibilityChecker();
    }, 500),
    initActiveMenu() {
      const pathName = window.location.pathname;
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll("a.nav-link"));
        let activeItems = items.filter((x) => x.classList.contains("active"));
        this.removeActivation(activeItems);
        let matchingMenuItem = items.find((x) => {
          return x.getAttribute("href") === pathName;
        });
        if (matchingMenuItem) {
          this.activateParentDropdown(matchingMenuItem);
        } else {
          var id = pathName.replace("/", "");
          if (id) document.body.classList.add("twocolumn-panel");
          this.activateIconSidebarActive(pathName);
        }
      }
    },

    updateMenu(val) {
      // animate hamburger icon to arrow
      console.log("updateMenu", val);
      if (
        document.querySelector(".hamburger-icon") &&
        !document.querySelector(".open")
      ) {
        document.querySelector(".hamburger-icon").classList.toggle("open");
      }
      let haveBot = cookies.get("lenna_bot_id");
      // console.log("have bot", haveBot);
      if (val.type == "direct" || (!haveBot && val.url == "studio")) {
        return this.$router.push({ name: val.slug });
      }
      document.body.classList.remove("twocolumn-panel");
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll(".show"));
        items.forEach((item) => {
          item.classList.remove("show");
        });
      }
      const icons = document.getElementById("two-column-menu");
      if (icons) {
        const activeIcons = Array.from(
          icons.querySelectorAll(".nav-icon.active")
        );
        activeIcons.forEach((item) => {
          item.classList.remove("active");
        });
      }
      document.getElementById(`sidebar_${val.slug}`).classList.add("show");
      this.activateIconSidebarActive("#sidebar_" + val.slug);
    },

    removeActivation(items) {
      items.forEach((item) => {
        if (item.classList.contains("menu-link")) {
          if (!item.classList.contains("active")) {
            item.setAttribute("aria-expanded", false);
          }
          item.nextElementSibling.classList.remove("show");
        }
        if (item.classList.contains("nav-link")) {
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.remove("show");
          }
          item.setAttribute("aria-expanded", false);
        }
        item.classList.remove("active");
      });
    },

    activateIconSidebarActive(id) {
      var menu = document.querySelector(
        "#two-column-menu .simplebar-content-wrapper a[href='" +
          id +
          "'].nav-icon"
      );
      if (menu !== null) {
        menu.classList.add("active");
      }
    },

    activateParentDropdown(item) {
      // navbar-nav menu add active
      item.classList.add("active");
      let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
      if (parentCollapseDiv) {
        // to set aria expand true remaining
        parentCollapseDiv.classList.add("show");
        parentCollapseDiv.parentElement.children[0].classList.add("active");
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true"
        );
        if (
          parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
        ) {
          this.activateIconSidebarActive(
            "#" +
              parentCollapseDiv.parentElement
                .closest(".collapse.menu-dropdown")
                .getAttribute("id")
          );
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .classList.add("show");
          if (
            parentCollapseDiv.parentElement.closest(".collapse")
              .previousElementSibling
          )
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.classList.add("active");
          return false;
        }
        this.activateIconSidebarActive(
          "#" + parentCollapseDiv.getAttribute("id")
        );
        return false;
      }
      return false;
    },

    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },

    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },

    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },

    toAuth() {
      // window.location.href = import.meta.env.VITE_AUTH_URL;
      this.$router.push({ name: "auth.workspace" });
    },
    async accessibilityChecker() {
      // await this.$store.dispatch("sidebar/getMenus");
      // await this.$store.dispatch("accessibility/getRole");
      await this.$store.dispatch("accessibility/getRoleV3");
      // if (this.userRole.new_platform == false) {
      //   if (
      //     this.userRole.slug == "admin" ||
      //     this.userRole.slug == "super-admin"
      //   ) {
      //     return this.$router.push({ name: "update-access" });
      //   }
      //   return this.$router.push({ name: "contact-admin" });
      // }
      if (this.$store.getters.localStorage.decodedUserId) {
        await this.$store.dispatch("integration/getIntegrationData");
        this.fetchBotIntegration();
        const response = await this.$axios.get(this.accessibilityUrlV3);
        if (!response.data.success) {
          // console.log("not success");
          this.$router.replace({ name: "notfound" });
          // this.$router.push("/404");
          // this.$store.dispatch("sidebar/updateMenus", null);
          this.$store.dispatch("sidebar/updateModules", null);
          return Toast.error(response.data.message);
        }
        this.$store.dispatch(
          "accessibility/setAccessibility",
          response.data.data
        );
        // this.$store.dispatch("sidebar/updateMenus", this.access.menu);
        this.$store.dispatch("sidebar/updateModules", this.access.module);
        const listApps = some(this.access.apps, {
          id: this.$route.params.appId,
        });
        if (!listApps) {
          // console.log("cannot access project");
          this.$router.push({ name: "forbidden" });
          // this.$store.dispatch("sidebar/updateMenus", null);
          this.$store.dispatch("sidebar/updateModules", null);
          this.errorToast(
            "You need invited to this app before access, contact your admin!"
          );
        }
        const currentModuleUrl = this.url[2];
        const canAccessThisModuleUrl = some(this.access.module, function (o) {
          return o.url == currentModuleUrl;
        });
        if (!canAccessThisModuleUrl) {
          if (this.$router.name !== "billing") {
            // return this.$router.push({ name: "forbidden" });
            this.goToNearestModuleMenu(this.access.module);
          }
        } else {
          let currentModuleData = find(this.access.module, function (m) {
            return m.url == currentModuleUrl;
          });
          if (currentModuleData.v3_menus.length > 0) {
            const currentMenuUrl = this.url[3];
            const canAccessThisMenuUrl = some(
              currentModuleData.v3_menus,
              function (o) {
                return o.url == currentMenuUrl;
              }
            );
            if (!canAccessThisMenuUrl) {
              // return this.$router.push({ name: "forbidden" });
              this.goToNearestModuleMenu(this.access.module);
            }
          }
          // this.goToMenuOfCurrentModule(this.access.module);
        }
        await this.$store.dispatch("integration/getIntegrationData", "bot");
      } else {
        // console.log("notfound");
        // this.$router.push({ name: "404" });
        this.$router.push({ name: "login" });
      }
    },
    goToNearestModuleMenu(modules) {
      console.log("goToNearestModuleMenu");
      this.haveAccessToCurrentApp = true;
      const moduleUrl = this.url[2];
      const moduleIdx = findIndex(modules, function (module) {
        return module.url == moduleUrl;
      });
      // console.log("moduleIdx", moduleIdx);
      if (moduleIdx == -1) {
        if (size(modules[0].menus) > 0) {
          return this.moduleHaveMenus(modules, 0);
        }
        // uncomment this after module done
        // return this.goToFirstNearestModule(modules);
      }
      if (size(modules[moduleIdx].menus) > 0) {
        return this.moduleHaveMenus(modules, moduleIdx);
      }
    },
    goToFirstModule(modules) {
      // console.log("goToFirstModule");
      this.$router
        .push({
          name: modules[0].route,
        })
        .catch((err) => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
    },
    moduleHaveMenus(modules, moduleIdx) {
      console.log("moduleHaveMenus");
      const menuUrl = this.url[3];
      const menuIdx = findIndex(modules[moduleIdx].v3_menus, function (menu) {
        return menu.slug == menuUrl;
      });
      if (menuIdx !== -1) {
        this.goToCurrentMenuOfCurrentModule(modules, moduleIdx, menuIdx);
      } else {
        this.goToFirstMenuOfCurrentModule(modules, moduleIdx);
      }
    },
    goToMenuOfCurrentModule(modules) {
      console.log("goToMenuOfCurrentModule", modules);
      const menuUrl = this.url[3];
      const moduleUrl = this.url[2];
      console.log("this.url", this.url);
      this.haveAccessToCurrentApp = true;
      const moduleIdx = findIndex(modules, function (module) {
        return module.url == moduleUrl;
      });
      if (size(modules[moduleIdx].v3_menus) > 0) {
        if (menuUrl !== undefined) {
          const menuIdx = findIndex(
            modules[moduleIdx].v3_menus,
            function (menu) {
              return menu.url == menuUrl;
            }
          );
          console.log("menuIdx", menuIdx);
          if (menuIdx !== -1) {
            return this.goToCurrentMenuOfCurrentModule(
              modules,
              moduleIdx,
              menuIdx
            );
          }
        }
      }
      this.goToFirstMenuOfCurrentModule(modules, moduleIdx);
    },
    // goToSubmenuOfCurrent
    goToCurrentMenuOfCurrentModule(modules, moduleIdx, menuIdx) {
      console.log("goToCurrentMenuOfCurrentModule modul", moduleIdx);
      console.log("goToCurrentMenuOfCurrentModule menu", menuIdx);
      const submenuUrl = this.url[4];
      if (size(modules[moduleIdx].v3_menus[menuIdx].submenus) > 0) {
        const submenuIdx = findIndex(
          modules[moduleIdx].v3_menus[menuIdx].submenus,
          function (s) {
            return s.url == submenuUrl;
          }
        );
        if (submenuIdx !== -1) {
          return this.$router
            .push({
              name: `${modules[moduleIdx].slug}.${modules[moduleIdx].v3_menus[menuIdx].slug}.${modules[moduleIdx].v3_menus[menuIdx].submenus[submenuIdx].slug}`,
            })
            .catch((err) => {
              if (err.name != "NavigationDuplicated") {
                throw err;
              }
            });
        }
      }
      this.$router
        .push({
          name: `${modules[moduleIdx].v3_menus[menuIdx].slug}`,
        })
        .catch((err) => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
    },
    goToFirstMenuOfCurrentModule(modules, moduleIdx) {
      console.log("goToFirstMenuOfCurrentModuleIdx", moduleIdx);
      // console.log("goToFirstMenuOfCurrentModule modules", modules);
      // if (size(modules[moduleIdx].v3_menus) == 0) {
      //   this.$router
      //     .push({
      //       // name: modules[moduleIdx].v3_menus[0].slug
      //       name: this.url[3] ? `${this.url[2]}.${this.url[3]}` : this.url[2],
      //     })
      //     .catch((err) => {
      //       if (err.name != "NavigationDuplicated") {
      //         throw err;
      //       }
      //     });
      // } else if (size(modules[moduleIdx].v3_menus) > 0) {
      //   this.$router
      //     .push({
      //       name: this.url[3] ? `${this.url[2]}.${this.url[3]}` : this.url[2],
      //     })
      //     .catch((err) => {
      //       if (err.name != "NavigationDuplicated") {
      //         throw err;
      //       }
      //     });
      // } else {
      //   this.goToFirstModule(modules);
      // }
    },
    goToFirstNearestModule(modules) {
      if (this.$route.name !== "billing") {
        console.log("goToFirstNearestModule", modules);
        this.$router
          .push({
            name: `${modules[0].slug}`,
          })
          .catch((err) => {
            if (err.name != "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },
    validateActiveBot(channels, activeBotId) {
      let result = false;
      for(const channel of channels) {
        for(const integration of channel.integration) {
          if(integration.bot_id == activeBotId) {
            result = true;
          }
        }
      }

      return result;
    },
    async fetchBotIntegration() {
      const integrations = this.$store.getters["integration/integrations"];
      const botIntegration = filter(integrations, function (o) {
        return o.name == "bot";
      });
      if (size(botIntegration[0].integration) > 0) {
        const botId = clone(
          botIntegration[0].integration[0].integration_data.botId
        );
        let hashids = new Hashids("", 6);
        var hashedBotId = hashids.encode(botId);
        this.$store.dispatch("bot/getBotList");
        
        if(!cookies.get('lenna_bot_id')) {
          this.$store.dispatch("bot/setBotId", hashedBotId);
        } else {
          const activeBotId = hashids.decode(cookies.get('lenna_bot_id'));
          if(!this.validateActiveBot(integrations, activeBotId[0])) {
            this.$store.dispatch("bot/setBotId", hashedBotId);
          }
        }

      } else {
        cookies.remove("lenna_bot_id");
      }
      // const fullBotStudioUrl = `${this.botStudioUrl}/${hashedBotId}/stories`;
      // this.$store.dispatch("sidebar/alterUrlModule", {
      //   slug: "conversation-studio",
      //   url: fullBotStudioUrl
      // });
    },
    showPopupKnowledgebase() {
      this.popupKnowledge = true;
      setTimeout( () => {
        const $popover = document.querySelector('.popover.show');

        if($popover) {
          $popover.classList.add('popover-knowledgebase');
        }
      }, 100);
    },
    hidePopupKnowledgebase(redirect) {
      this.popupKnowledge = false;
      window.localStorage.removeItem('_popup_knowledgebase');

      if(redirect) {
        window.open('https://faq.lenna.ai/lenna', '_blank');
      }
    },
    setAlertTopbar({ quick_start, trial_alert }) {
      this.quickStart = quick_start;
      this.trialAlert = trial_alert;
      this.isShowTrialAlert = !quick_start || trial_alert === 0;
    }
  },
};
</script>
<style lang="scss" scoped>
.navbar-menu .navbar-nav .nav-sm .nav-sm .nav-link:before {
  display: none;
}
.menu-name {
  color: #abbae8;
  display: block;
  font-size: 10px;
}

.menu-article {
  position: absolute !important;
  bottom: 15px;
  left: 15px;
}

.menu-doc a {
  background-color: #E5F2FC;
  width: 35px !important;
  height: 35px !important;
}

.app-menu .navbar-menu {
  margin-top: 0.3rem;
  margin-left: 0.1rem;
}
</style>

<style lang="scss">
.popover.popover-knowledgebase {
  max-width: 330px !important;
  border-radius: 16px !important;

  .popover-body {
    padding: 0 !important;
  }

  a {
    font-size: 0.7125rem !important;
  }
}

div#layout-wrapper.showTopbarQuickstart {
  header#page-topbar,
  .app-menu.navbar-menu {
    top: 55px !important;
  }

  div.main-content {
    margin-top: 55px !important;
  }
}

div#layout-wrapper.showTopbarTrialAlert {
  header#page-topbar,
  .app-menu.navbar-menu {
    top: 43px !important;
  }

  div.main-content {
    margin-top: 43px !important;
  }
}
</style>