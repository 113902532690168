import { MentionService } from "@/services";
import { filter, find, findIndex, assignIn } from "lodash";

const state = {
  mentions: [],
  count: 0,
  selectedMention: null,
  loading: false,
  accountIntegration: [],
  sort: 'desc',
}

const getters = {
  mentions(state) {
    return state.mentions;
  },
  count(state) {
    return state.count;
  },
  sortedMentions(state) {
     return state.mentions
      .toSorted( (a, b) => {
        if(state.sort == 'desc') {
          return new Date(b.updated_at) - new Date(a.updated_at);
        } else {
          return new Date(a.updated_at) - new Date(b.updated_at);
        }
      });
  },
  unreadCount(state) {
    let total = 0;
    for(const item of state.mentions) {
      total += item.unread_count;
    }

    return total;
  },
  selectedMention(state) {
    return state.selectedMention;
  },
  loading(state) {
    return state.loading;
  },
  accountIntegration(state) {
    return state.accountIntegration;
  },
  sort(state) {
    return state.sort;
  }
}

const mutations = {
  SET_MENTIONS: (state, payload) => {
    state.mentions = payload;
  },
  SET_COUNT: (state, payload) => {
    state.count = payload;
  },
  SET_SELECTED_MENTION: (state, payload) => {
    state.selectedMention = payload;
  },
  SET_READ_AT: (state, payload) => {
    for(const item of state.mentions) {
      if(item.id == payload.id) {
        item.read_at = (new Date()).getTime();
        item.unread_count = 0;
      }
    }
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  ADD_REPLIES_MENTION: (state, payload) => {
    if(state.selectedMention && state.selectedMention.id == payload.parent_id) {
      state.selectedMention.replies.unshift(payload);
    }

    const mention = find(state.mentions, (item) => item.id == payload.parent_id);
    if(mention) {
      mention.updated_at = payload.created_at;
      if(Array.isArray(mention.replies)) {
        mention.replies.push(payload);
      } else {
        mention.replies = payload; 
      }
    }
  },
  SET_ACCOUNT_INTEGRATION: (state, payload) => {
    state.accountIntegration = payload;
  },
  ADD_NEW_MENTIONS: (state, payload) => {
    const findExists = filter(state.mentions, (item) => {
      return item.id == payload.id;
    });

    if(findExists.length == 0) {
      state.mentions.push(payload);
    }

    state.count++;
  },
  SET_SORT: (state, payload) => {
    state.sort = payload;
  }
}

const actions = {
  async getMentions({ commit, state }, payload) {
    try {
      assignIn(payload, { sort_by: state.sort });
      const { data } = await MentionService.getMentionList(payload);

      commit('SET_MENTIONS', [
        ...state.mentions,
        ...data
      ]);

      return data;

    } catch(e) {
      console.error('error getMentions', e);
    }
  },
  async getMentionCount({ commit, state }, payload) {
    try {
      const { data } = await MentionService.getMentionCount();
      commit('SET_COUNT', data.count);
      return data.count;

    } catch(e) {
      console.error('error getMentionCount', e);
    }
  },
  async selectMention({ commit, state }, payload) {
    const mentionId = payload.id;
    if(!mentionId) {
      // handle untuk "back" button di mobile responsive
      commit('SET_SELECTED_MENTION', null);
      return;
    }

    if(mentionId == state.selectedMention?.id || state.loading) {
      // jika dia select ke mention yg sama, atau spam click
      return;
    }
    
    try {
      commit('SET_LOADING', true);

      const { data } = await MentionService.getMentionDetail(mentionId);
      commit('SET_READ_AT', { id: mentionId });
      commit('SET_SELECTED_MENTION', data);
      commit('SET_LOADING', false);

    } catch(e) {
      console.error('error getMentionDetail', e);
    }
  },
  async fetchAccountIntegration({ commit }, payload) {
    try {
      const { data } = await MentionService.getAccountIntegration(payload);
      commit('SET_ACCOUNT_INTEGRATION', data);

    } catch(e) {
      console.error('error fetchAccountIntegration', e);
    }
  },
  handleNewMention({ commit }, payload) {
    if(payload.parent_id) {
      commit('ADD_REPLIES_MENTION', payload);
    } else {
      commit('ADD_NEW_MENTIONS', payload);
    }
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};