import { IntegrationService } from "@/services";
import find from "lodash/find";

const state = {
  integrationData: null,
  qrCodeMobileOmni: null,
  integrations: [],
  integrationChannel: null, // only intended for channel integration only.
  error: null,
};
const getters = {
  integrationData(state) {
    return state.integrationData;
  },
  // only intended for channel integration only.
  integrationChannel(state) {
    return state.integrationChannel;
  },
  qrCodeMobileOmni(state) {
    return state.qrCodeMobileOmni;
  },
  integrations(state) {
    return state.integrations;
  },
  error(state) {
    return state.error;
  },
};
const mutations = {
  SET_INTEGRATION_DATA: (state, payload) => {
    let res = payload.data;
    let channelData = find(res, function (item) {
      return item.name == payload.name;
    });
    if (channelData) {
      state.integrationData = channelData.integration;
      // console.log("get data by channel", state.integrationData);
    }
    state.integrations = payload.data;
  },
  SET_INTEGRATION_CHANNEL: (state, payload) => {
    let res = payload.data;
    let channelData = find(res, function (item) {
      return item.name == payload.name;
    });
    if (channelData) {
      state.integrationChannel = channelData.integration;
      console.log("get data by channel", state.integrationChannel);
    }
    state.integrations = payload.data;
  },
  SET_QR_CODE_MOBILE_OMNI_DATA: (state, payload) => {
    state.qrCodeMobileOmni = payload;
  },
  SET_ERROR: (state, payload) => {
    state.error = payload;
  },
};
const actions = {
  async getIntegrationData({ commit }, payload) {
    try {
      const { data: response } = await IntegrationService.getIntegration();
      commit("SET_INTEGRATION_DATA", {
        data: response.data,
        name: payload,
      });
      return response;
    } catch (error) {
      commit("SET_ERROR", error);
      console.log("Error", error);
      return error;
    }
  },
  async saveMobileOmniUserData(context) {
    try {
      const { data: response } =
        await IntegrationService.getMobileOmniUserData();
      context.commit("SET_QR_CODE_MOBILE_OMNI_DATA", response.data);
    } catch (error) {
      context.commit("SET_ERROR", error);
      console.log("Error", error);
      return error;
    }
  },
  async listIntegration(context, payload) {
    try {
      const { data: response } = await IntegrationService.listIntegration(
        payload
      );
      return response.data;
    } catch (error) {
      context.commit("SET_ERROR", error);
      console.log("Error", error);
      return error;
    }
  },
  async enableDisableIntegration(context, payload) {
    try {
      const { data: response } =
        await IntegrationService.enableDisableIntegration(payload);
      return response.data;
    } catch (error) {
      context.commit("SET_ERROR", error);
      console.log("Error", error);
      return error;
    }
  },
  async setV3TwitterWebhookUrl(context, payload) {
    const response = await IntegrationService.v3TwitterSetWebhookUrl(payload);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },
  async deleteV3TwitterWebhookUrl(context, payload) {
    const response = await IntegrationService.v3TwitterDeleteWebhookUrl(
      payload
    );
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },
  async v3GenerateIntegration(context, data) {
    try {
      const { data: response } = await IntegrationService.v3GenerateIntegration(
        data
      );
      return response;
    } catch (error) {
      context.commit("SET_ERROR", error);
      console.log("Error", error);
      return error;
    }
  },
  async saveIntegration(context, data) {
    const response = await IntegrationService.saveIntegration(data);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },

  async updateIntegration(context, data) {
    const response = await IntegrationService.updateIntegration(data);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },
  async deleteIntegration(context, data) {
    const response = await IntegrationService.deleteIntegration(data);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },

  async savePostmarkIntegration(context, data) {
    const response = await IntegrationService.savePostmarkIntegration(data);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },

  async updatePostmarkIntegration(context, data) {
    const response = await IntegrationService.updateIntegration(data);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },

  async deletePostmarkIntegration(context, data) {
    const response = await IntegrationService.deletePostmarkIntegration(data);
    if (!response.data.success) {
      context.commit("SET_ERROR", response.data.message);
    }
    return response;
  },
  // only intended for channel integration only.
  async getIntegrationChannel({ commit }, payload) {
    try {
      const { data: response } = await IntegrationService.getIntegration();
      commit("SET_INTEGRATION_CHANNEL", {
        data: response.data,
        name: payload,
      });
      return response;
    } catch (error) {
      commit("SET_ERROR", error);
      console.log("Error", error);
      return error;
    }
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
