const facebookAppId = import.meta.env.VITE_FACEBOOK_APP_ID;

export function initFacebookSdk() {
  return new Promise((resolve) => {
    console.log("fb init");
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      FB.init({
        appId: 1593133457695828,
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });

      FB.AppEvents.logPageView();

      // auto authenticate with the api if already logged in with facebook
      //   FB.getLoginStatus(({ authResponse }) => {
      //     if (authResponse) {
      //       console.log("response auth", authResponse);
      //       resolve(authResponse);
      //     } else {
      //       resolve();
      //     }
      //   });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
