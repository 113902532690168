<template>
  <div id="layout-wrapper">
    <NavBar />
    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div
          class="navbar-brand-box align-items-center d-flex justify-content-center"
        >
          <!-- Dark Logo-->
          <a href="/" class="logo logo-dark">
            <div
              class="logo-lg d-flex align-items-center"
              v-if="domain == 'damcorp'"
            >
              <!-- <div class="flex-shrink-0"> -->
              <img
                src="@/assets/images/damcorp-workspace.png"
                alt=""
                height="30"
              />
            </div>
            <div
              class="logo-lg d-flex align-items-center"
              v-if="domain == 'bawaslu'"
            >
              <!-- <div class="flex-shrink-0"> -->
              <img
                src="@/assets/images/logo-bawaslu.png"
                alt=""
                height="30"
              />
            </div>
            <div
              class="logo-lg d-flex align-items-center"
              v-if="domain == 'pgd-omni'"
            >
              <!-- <div class="flex-shrink-0"> -->
              <img
                src="@/assets/images/pegadaian-workspace.png"
                alt=""
                height="30"
              />
            </div>
            <div class="logo-lg d-flex align-items-center" v-else>
              <div class="flex-shrink-0">
                <img src="@/assets/images/logo-icon.svg" alt="" height="30" />
              </div>
              <div class="flex-grow-1 ms-2 mt-2"><h5>Lenna Workspace</h5></div>
            </div>
          </a>
          <!-- Light Logo-->
          <a href="/" class="logo logo-light">
            <!-- <span class="logo-sm">
              <img src="@/assets/images/logo-icon.svg" alt="" height="22" />
            </span> -->
            <div
              class="logo-lg d-flex align-items-center"
              v-if="domain == 'damcorp'"
            >
              <!-- <div class="flex-shrink-0"> -->
              <img
                src="@/assets/images/damcorp-workspace.png"
                alt=""
                height="30"
              />
              <!-- </div> -->
              <!-- <div class="flex-grow-1 ms-2 mt-2"><h5>Lenna Workspace</h5></div> -->
            </div>
            <div
              class="logo-lg d-flex align-items-center"
              v-else-if="domain == 'bawaslu'"
            >
              <!-- <div class="flex-shrink-0"> -->
              <img
                src="@/assets/images/logo-bawaslu.png"
                alt=""
                height="30"
              />
              <!-- </div> -->
              <!-- <div class="flex-grow-1 ms-2 mt-2"><h5>Lenna Workspace</h5></div> -->
            </div>
            <div
              class="logo-lg d-flex align-items-center"
              v-else-if="domain == 'pgd-omni'"
            >
              <!-- <div class="flex-shrink-0"> -->
              <img
                src="@/assets/images/pegadaian-workspace.png"
                alt=""
                height="30"
              />
            </div>
            <div class="logo-lg d-flex align-items-center" v-else>
              <div class="flex-shrink-0">
                <img src="@/assets/images/logo-icon.svg" alt="" height="30" />
              </div>
              <div class="flex-grow-1 ms-2 mt-2"><h5>Lenna Workspace</h5></div>
            </div>
          </a>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div class="m-3 p-2 shadow-lg">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-sm p-1">
                <img
                  v-if="this.avatar"
                  :src="this.avatar"
                  class="avatar-navbar rounded-circle object-cover"
                  alt="profile"
                  width="40"
                  height="40"
                />
                <div
                  v-else
                  class="avatar-title bg-soft-success text-success rounded-circle fs-12"
                >
                  {{ acronymText(profile.name) }}
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h6 class="mb-0">{{ truncateText(profile.name, 21) }}</h6>
              <p class="text-muted mb-0">
                {{ currentRole.length > 0 ? currentRole[0].name : "Workspace" }}
              </p>
            </div>
          </div>
        </div>
        <!-- W S P D -->
        <div id="scrollbar" class="h-100" ref="scrollbar">
          <div class="container-fluid">
            <div id="two-column-menu"></div>
            <ul class="navbar-nav" id="navbar-nav">
              <li class="nav-item active-custom">
                <a title="Dashboard" class="nav-link menu-link" href="/">
                  <i class="ri-home-3-fill"></i>
                  <span data-key="t-Dashboard">Workspace</span>
                </a>
              </li>
              <li class="nav-item">
                <a title="Support" class="nav-link menu-link" href="/">
                  <i
                    style="margin-left: 20px"
                    class="ri-customer-service-2-line"
                  ></i>
                  <span data-key="t-Support">Support</span>
                </a>
              </li>
              <li class="nav-item">
                <a title="Plans" class="nav-link menu-link" href="/">
                  <i
                    style="margin-left: 20px"
                    class="ri-money-dollar-circle-line"
                  ></i
                  ><span data-key="t-Plans">Plans</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  title="Documentation"
                  class="nav-link menu-link"
                  target="_blank"
                  href="https://lenna-v3.document360.io/docs/user-manual-lenna-platform-v3"
                >
                  <i style="margin-left: 20px" class="ri-dashboard-2-fill"></i>
                  <span data-key="t-Documentation">Documentation</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- Sidebar -->
        </div>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div
        class="page-content"
        :style="hideFooter ? 'padding-bottom: 0px' : ''"
      >
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <Footer v-if="hideFooter" />
    </div>
    <RightBar />
  </div>
</template>
<script>
import router from "@/router";
import { SimpleBar } from "simplebar-vue3";
import { layoutComputed } from "@/store/helpers";
import { StorageService } from "@/services/auth";
import { mapGetters } from "vuex";

import NavBar from "@/components/nav-bar";
import Menu from "@/components/menu.vue";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";

/**
 * Vertical layout
 */
export default {
  components: { NavBar, RightBar, Footer, SimpleBar, Menu },
  data() {
    return {
      isMenuCondensed: false,
      profile: {
        name: StorageService.get("name"),
        email: StorageService.get("email"),
        id: StorageService.get("id"),
      },
      user: this.$store.getters.localStorage.user,
      domain: window.location.hostname.split(".")[0],
    };
  },
  computed: {
    ...layoutComputed,
    hideFooter() {
      return this.$store.getters["layout/hideFooter"];
    },
    currentRole() {
      let givenRole = this.user.given_roles.filter(
        (o) => o.app_id == this.$store.getters.appId.decoded
      );
      return givenRole;
    },
    ...mapGetters({
      avatar: "agent/profilePicture",
    }),
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.active-custom {
  i {
    color: #3f5189;
  }
  font-weight: bolder;
  background-color: #f1f3f6;
  margin: 0 20px;
  border-radius: 8px;
}
.nav-link,
.menu-link {
  transition-timing-function: ease-in-out;
  transition: 0.25s;
  &:hover {
    color: rgb(115, 115, 115) !important;
    font-weight: 700;
    transition-timing-function: ease-in-out;
    transition: 0.25s;
  }
}
</style>
