<template>
  <div id="project-section" class="border-bottom py-2">
    <div class="dropdown-header px-0 d-flex">
      <h6 class="text-overflow text-muted mb-1 text-uppercase flex-grow-1">
        Project
      </h6>
    </div>
    <template v-if="data.length > 0">
      <ul class="list-unstyled vstack gap-2 mb-0">
        <li v-for="(app, i) in data" :key="i">
          <a
            href="javascript:void(0);"
            @click.prevent="syncRole(app)"
            class="dropdown-item notify-item"
            style="display: block"
          >
            <div class="d-flex">
              <div class="flex-shrink-0 avatar-xxs text-primary">
                <i class="ri-building-2-line"></i>
              </div>
              <div class="flex-grow-1">
                <h6 class="mb-0">{{ app.name }}</h6>
                <small class="text-muted">{{ app.name }}</small>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="alert alert-light rounded-pill text-center" role="alert">
        <strong>No data available</strong>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup() {},
  components: {},
  data() {
    return {};
  },
  computed: {
    urlTemp() {
      let url = import.meta.env.VITE_APP_URL;
      if (window.location.hostname.split('.')[0] == 'damcorp') {
          url = import.meta.env.VITE_DAMCORP_URL;
      } else if (window.location.hostname.split('.')[0] == 'bawaslu') {
          url = import.meta.env.VITE_BAWASLU_URL;
      } else if (window.location.hostname.split('.')[0] == 'pdg-omni') {
          url = import.meta.env.VITE_PEGADAIAN_URL;
      } else {
          url = import.meta.env.VITE_APP_URL;
      }
      return url;
    }
  },
  methods: {
    async syncRole(app) {
      this.$store.dispatch("loading/updateIsLoading", true);
      this.$store.commit("app/ADD_RECENT_SEARCHES", this.search);
      await this.$store.dispatch("bot/getActiveBot", app.hashed_id);
      this.$store.dispatch("loading/updateIsLoading", false);

      window.location.href = `${this.urlTemp}/${app.hashed_id}/dashboard`;
      // });
    },
  },
  created() {},
};
</script>
<style lang="scss">
#project-section {
  max-height: 13rem;
  overflow-y: auto;
  overflow-x: auto;
}
</style>
