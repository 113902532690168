<template>
  <!-- <div
    :class="`overlay ${!showChat && 'active'}`"
    @click="showChat = false"
  ></div> -->

  <div class="chat">
    <div :class="`chat__container ${!showChat && 'hide'}`">
      <div class="chat__room">
        <div class="chat__room-header">
          <div class="chat__room-header__profile">
            <div class="img-profile" style="margin-right: 5px">
              <ImgLazy
                v-if="user && user.picture"
                :src="user.picture"
                :alt="user?.name || ''"
                errorImg="/img/user-dummy-img.jpg"
              />
              <img
                v-else
                src="../assets/images/users/user-dummy-img.jpg"
                alt="default user"
              />
            </div>
            <div>
              <span class="chat__room-header__profile-name">
                {{ user?.name }}
              </span>
              <span>Online</span>
            </div>
          </div>
        </div>

        <div class="chat__room-filter">
          <div
            :class="['chat__room-filter-search', filter.showSearch && 'show']"
          >
            <input v-model="filter.search" type="text" />
            <button type="button" @click="closeSearch">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div class="chat__room-filter-type">
            <button
              type="button"
              class="btn-search"
              @click="filter.showSearch = true"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.031 14.6168L20.3137 18.8995L18.8995 20.3137L14.6168 16.031C13.0769 17.263 11.124 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18 11.124 17.263 13.0769 16.031 14.6168ZM14.0247 13.8748C15.2475 12.6146 16 10.8956 16 9C16 5.1325 12.8675 2 9 2C5.1325 2 2 5.1325 2 9C2 12.8675 5.1325 16 9 16C10.8956 16 12.6146 15.2475 13.8748 14.0247L14.0247 13.8748Z"
                  fill="#878A96"
                />
              </svg>
            </button>

            <button
              v-for="r in types"
              :key="`type-${r.value}`"
              :class="`btn-filter ${r.value === filter.type && 'active'}`"
              @click="filter.type = r.value"
            >
              {{ r.label }}
              <span>({{ r.total }})</span>
            </button>
          </div>
        </div>

        <div class="chat__room-body">
          <span class="chat__room-body-title">
            {{ selectedType.label }} ({{ selectedType.total }})
          </span>

          <template v-if="!loadingContactList">
            <template v-if="roomFiltered.length">
              <ChatItem
                v-for="roomItem in roomFiltered"
                :key="roomItem.id"
                :image="roomItem.user?.picture"
                :name="roomItem.user.name"
                :lastMessage="roomItem.message"
                :lastMessageAt="roomItem.createdAt"
                :countUnread="roomItem.countUnread"
                :isRead="roomItem.isRead"
                :isGroup="roomItem.isGroup"
                :color="roomItem.user?.color || 0"
                :status="roomItem.statusOnline"
                @click="
                  openChatDetail({
                    id: roomItem.user.id,
                    user: {
                      name: roomItem.user.name,
                      picture: roomItem.user.picture,
                      status_login: roomItem.statusOnline,
                    },
                    isGroup: roomItem.isGroup,
                  })
                "
              />
            </template>

            <div v-else class="chat__room-body-no-data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path
                    d="M9 10h.01M15 10h.01M9.5 15.25a3.5 3.5 0 0 1 5 0m3.066 2.356a2 2 0 1 0 2.897.03L19 16l-1.434 1.606z"
                  />
                  <path
                    d="M20.865 13.517A8.937 8.937 0 0 0 21 12a9 9 0 1 0-9 9c.69 0 1.36-.076 2-.222"
                  />
                </g>
              </svg>
            </div>
          </template>
          <div v-else class="text-center py-2">Loading...</div>
        </div>

        <button
          type="button"
          class="btn-show-contact"
          @click="showContact = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.333 10.334V0.333984H13.6663V10.334H23.6663V13.6673H13.6663V23.6673H10.333V13.6673H0.333008V10.334H10.333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <ChatContactList
        :show="showContact"
        @back="showContact = false"
        @minimize="showContact = false"
        @itemClick="
          (toId, toName, avatar, statusLogin) => {
            openChatDetail({
              id: toId,
              user: {
                name: toName,
                picture: avatar,
                statusLogin,
              },
              isGroup: false,
            });
            showContact = false;
          }
        "
      />

      <ChatDetail
        v-if="chat.to.id"
        :show="chat.show"
        :toId="chat.to.id"
        :name="chat.to.name"
        :image="chat.to.image"
        :isGroup="chat.to.isGroup"
        :statusLogin="chat.to.statusLogin"
        :chats="
          chats.filter((r) =>
            chat.to.isGroup
              ? r.groupId === chat.to.id
              : r.fromId === chat.to.id || r.toId === chat.to.id
          )
        "
        @back="closeDetailChat"
        @minimize="closeDetailChat"
        @send="sendChat"
      />
    </div>

    <audio ref="chatComingAudio">
      <source src="/audio/chat-coming.mp3" type="audio/mp3" />
    </audio>

    <button type="button" @click="toggleChat" class="btn-show-message">
      <svg
        v-if="!showChat"
        width="20"
        height="20"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 9.49061C0 4.52524 4.01481 0.5 9.00099 0.5H20.999C25.9701 0.5 30 4.54217 30 9.49061V27.5H9.00099C4.02988 27.5 0 23.4578 0 18.5094V9.49061ZM18 12.5V15.5H21V12.5H18ZM9 12.5V15.5H12V12.5H9Z"
          fill="white"
        />
      </svg>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>

      <span v-if="countUnread > 0" class="badge-unread-message">
        {{ countUnread }}
      </span>
    </button>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  reactive,
  computed,
  inject,
} from "vue";
import { useStore } from "vuex";

import ChatItem from "./ChatItem.vue";
import ChatDetail from "./ChatDetail.vue";
import ChatContactList from "./ChatContactList.vue";
import ImgLazy from "./base/ImgLazy.vue";

export default defineComponent({
  components: {
    ChatItem,
    ChatContactList,
    ChatDetail,
    ImgLazy,
  },
  setup() {
    const socket = inject("socket");

    const store = useStore();

    const chatComingAudio = ref();
    const showChat = ref(false);
    const showContact = ref(false);

    const filter = reactive({
      showSearch: false,
      search: "",
      type: null,
    });

    const chat = reactive({
      show: false,
      to: {
        id: null,
        name: "",
        image: "",
        isGroup: false,
        statusLogin: false,
      },
      data: [],
    });

    const countUnread = computed(() => {
      let total = 0;
      rooms.value.map((r) => {
        total += r.countUnread;
      });

      return total;
    });

    const chats = computed(() => store.getters["internalChat/getChats"]);
    const groups = computed(() => store.getters["internalChat/getGroups"]);

    const user = computed(() => store.getters["localStorage"]?.user || null);
    const userId = computed(() => store.state["localStorage"].decodedUserId);
    const appId = computed(() => store.getters["appId"].hashed);

    const contactList = computed(
      () => store.getters["internalChat/getContactList"]
    );

    const loadingContactList = computed(
      () => store.getters["internalChat/getLoading"]
    );

    const onlineMembers = computed(
      () => store.getters["internalChat/getOnlineMembers"]
    );

    const rooms = computed(() => store.getters["internalChat/getRooms"]);
    const roomGroups = computed(
      () => store.getters["internalChat/getRoomGroups"]
    );

    let dataRooms = computed(() => {
      const data = rooms.value
        .map((r) => {
          const user = contactList.value.find(
            (contact) => contact?.user?.id === r.userId
          );

          let statusOnline = false;
          if (!r.isGroup && user) {
            const check = onlineMembers.value.find(
              (r) => r.userId === user.user_id
            );
            statusOnline = check?.status || false;
          }

          return {
            ...r,
            statusOnline,
            user: (!r.isGroup ? user?.user : user) || null,
          };
        })
        .filter((r) => r.userId);

      return data;
    });

    const roomFiltered = computed(() => {
      let dataGroups = groups.value.map((r) => {
        const selectedGroup = roomGroups.value.find((roomGroup) => {
          return roomGroup.userId === r.id;
        });

        return {
          user: r,
          isGroup: true,
          message: selectedGroup?.message || null,
          isRead: selectedGroup?.isRead || true,
          countUnread: selectedGroup?.countUnread || 0,
          createdAt: selectedGroup?.createdAt || null,
        };
      });

      return [...dataRooms.value, ...dataGroups]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((r) => {
          if (filter.type === "dm") return !r.isGroup;
          if (filter.type === "group") return r.isGroup;
          return true;
        })
        .filter((r) => {
          return (
            r.user?.name.toUpperCase().indexOf(filter.search.toUpperCase()) > -1
          );
        });
    });

    const types = computed(() => {
      const countUser = dataRooms.value.length;
      const countGroup = groups.value.length;

      return [
        {
          label: "All Message",
          total: countUser + countGroup,
          value: null,
        },
        {
          label: "Direct Message",
          total: countUser,
          value: "dm",
        },
        {
          label: "Group",
          total: countGroup,
          value: "group",
        },
      ];
    });

    const selectedType = computed(() =>
      types.value.find((r) => r.value === filter.type)
    );

    const closeSearch = () => {
      filter.showSearch = false;
      filter.search = "";
    };

    const toggleChat = () => {
      showChat.value = !showChat.value;

      filter.showSearch = false;
      filter.search = "";
      filter.type = null;

      chat.show = false;
      chat.to.id = null;
      chat.to.name = null;
      chat.to.image = null;
      chat.to.isGroup = false;
      chat.to.statusLogin = false;

      store.commit("internalChat/SET_CHAT_ACTIVE", {
        id: null,
        isGroup: null,
      });
    };

    const openChatDetail = (room) => {
      chat.to.id = room.id;
      chat.to.name = room.user.name;
      chat.to.image = room.user.picture;
      chat.to.statusLogin = room.user.status_login;
      chat.to.isGroup = room.isGroup;

      store.commit("internalChat/SET_CHAT_ACTIVE", {
        id: room.id,
        isGroup: room.isGroup,
      });

      socket?.emit("get-message", appId.value, room.id, chat.to.isGroup);
      socket?.on("get-message", async (data) => {
        const check = await store.dispatch(
          "internalChat/addLoadedMessage",
          `${chat.to.isGroup ? "group" : "user"}-${room.id}`
        );
        if (check) {
          store.dispatch("internalChat/appendChat", data);
        }

        const indexRoom = rooms.value.findIndex((r) => r.userId === room.id);
        if (indexRoom > -1) {
          store.commit("internalChat/CHANGE_ROOM", {
            index: indexRoom,
            data: {
              isRead: true,
              countUnread: 0,
            },
          });
        }

        if (!chat.to.isGroup) socket?.emit("read-message", room.id);
      });

      setTimeout(() => {
        chat.show = true;
      }, 200);
    };

    const checkWindowsKeyDown = (e) => {
      if (e.keyCode === 27) {
        closeSearch();
        chat.show = false;
      }
    };

    const closeDetailChat = () => {
      chat.show = false;

      store.commit("internalChat/SET_CHAT_ACTIVE", {
        id: null,
        isGroup: null,
      });
    };

    const sendChat = (message) => {
      socket?.emit(
        chat.to.isGroup ? "group-message" : "message",
        appId.value,
        chat.to.id,
        message
      );
    };

    const getContact = async () => {
      const members = await store.dispatch("internalChat/getContactList");
      socket?.emit(
        "get-status-online",
        members.map((r) => r.user_id)
      );
    };

    onMounted(() => {
      window.addEventListener("keydown", checkWindowsKeyDown);

      socket?.on("group-message", async (data) => {
        const index = roomGroups.value.findIndex(
          (r) => r.userId === data.groupId
        );

        if (index >= 0) {
          const onRoom = !!(
            chat.to.id === roomGroups.value[index].userId && chat.show
          );

          store.dispatch("internalChat/updateRoomGroup", {
            index,
            message: data.message,
            createdAt: data.createdAt,
            isRead: onRoom,
            countUnread: onRoom ? 0 : roomGroups.value[index].countUnread + 1,
          });
          store.dispatch("internalChat/addChat", data);
        } else {
          store.dispatch("internalChat/addRoomGroup", {
            createdAt: data.createdAt,
            message: data.message,
            userId:
              data.fromId !== parseInt(userId.value) ? data.fromId : data.toId,
          });
          await store.dispatch(
            "internalChat/addLoadedMessage",
            `group-${data.groupId}`
          );
          store.dispatch("internalChat/appendChat", [data]);
        }
      });

      getContact();
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", checkWindowsKeyDown);
    });

    return {
      showChat,
      showContact,
      types,
      rooms,
      dataRooms,
      roomGroups,
      groups,
      filter,
      chat,
      chats,
      user,
      appId,
      countUnread,
      selectedType,
      roomFiltered,
      contactList,
      loadingContactList,
      chatComingAudio,

      closeSearch,
      toggleChat,
      openChatDetail,
      sendChat,
      closeDetailChat,
    };
  },
});
</script>
