<template>
  <label class="lenna-label" :style="computeClass" @click="$emit('click')">
    {{ text }}<span style="color: red" v-if="required">*</span>
  </label>
</template>
<script>
export default {
  name: "BaseLabel",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "12px",
    },
    text: {
      type: String,
      default: "Label",
    },
  },
  computed: {
    computeClass() {
      return `font-size: ${this.size}`;
    },
  },
};
</script>
<style lang="scss" scoped>
// refer on scss/component/label.scss
</style>
