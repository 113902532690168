const state = {
  qrCodeAvailable: [],
  contacts: [],
};

const getters = {
  qrCode: (state) => state.qrCodeAvailable,

  receiveContact: (state) => state.contacts,
};

const mutations = {
  SET_QRCODE: (state, payload) => {
    const index = state.qrCodeAvailable.findIndex(
      (r) => r.name === payload.name
    );

    if (index >= 0) state.qrCodeAvailable[index].qr = payload.qr;
    else {
      state.qrCodeAvailable = [
        ...state.qrCodeAvailable,
        { name: payload.name, qr: payload.qr, status: false },
      ];
    }
  },

  SET_READY: (state, payload) => {
    const index = state.qrCodeAvailable.findIndex(
      (r) => r.name === payload.name
    );

    if (index >= 0) {
      state.qrCodeAvailable[index].status = true;
    } else {
      state.qrCodeAvailable = [
        ...state.qrCodeAvailable,
        { name: payload.name, qr: null, status: true },
      ];
    }
  },

  INIT_CONTACT: (state, payload) => {
    const index = state.contacts.findIndex(
      (r) => r.integration_id === payload.integration_id
    );

    if (index >= 0) {
      state.contacts[index].contacts = [];
      state.contacts[index].loading = true;
    } else {
      state.contacts = [
        ...state.contacts,
        {
          integration_id: payload.integration_id,
          contacts: [],
          loading: true,
        },
      ];
    }
  },

  SET_CONTACTS: (state, payload) => {
    const index = state.contacts.findIndex(
      (r) => r.integration_id === payload.integration_id
    );

    if (index >= 0) {
      state.contacts[index].contacts = payload.contacts;
      state.contacts[index].loading = false;
    } else {
      state.contacts = [
        ...state.contacts,
        {
          integration_id: payload.integration_id,
          contacts: payload.contacts,
          loading: false,
        },
      ];
    }
  },
};

const actions = {
  addQrCode: ({ commit }, payload) => {
    commit("SET_QRCODE", payload);
  },

  setReady: ({ commit }, payload) => {
    commit("SET_READY", payload);
  },

  initContact: ({ commit }, payload) => {
    commit("INIT_CONTACT", payload);
  },

  setContacts: ({ commit }, payload) => {
    commit("SET_CONTACTS", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
