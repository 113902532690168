export const getUrl = (endpoint) => {
  const hashedUser = JSON.parse(localStorage.getItem("user")).id;
  return `${endpoint}/${hashedUser}`;
};
export const getUniqueArray = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
export const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export function isColorLight(hexColor) {
  if (!hexColor) {
    return hexColor
  }
  hexColor = hexColor.replace('#', '');

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 128
}