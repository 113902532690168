import { httpClientBot } from "@/libraries";
import router from "@/router";

const state = {
  listAssistant: [],
  files: [],
  playground: []
};

const getters = {
  assistant(state) {
    return state.listAssistant;
  },
  files(state) {
    return state.files;
  },
  playground(state) {
    return state.playground;
  }
};

const mutations = {
  SET_ASSISTANT(state, data) {
    state.listAssistant = data;
  },
  SET_FILES(state, data) {
    state.files = data;
  },
  ADD_PLAYGROUND(state, data) {
    state.playground.push(data);
  },
  CLEAR_PLAYGROUND(state) {
    state.playground = [];
  }
};

const actions = {
  async listAssistant({ commit, rootGetters }) {
    const resp = await httpClientBot.get('assistant/'+rootGetters.appId.hashed);
    console.log(resp)
    commit('SET_ASSISTANT', resp.data.data) 
  },
  async create({ commit }, payload) {
    const resp = httpClientBot.post('assistant', payload);
    console.log(resp)
  },
  async getById({ commit }, id) {
    const resp = await httpClientBot.get('assistant/detail/' + id);
    console.log(resp)
    return resp.data.data;
  },
  async update(_, payload) {
    const resp = await httpClientBot.put('assistant/'+payload.id, payload);
    console.log(resp)
  },
  async delete({ commit }, id) {
    const resp = await httpClientBot.delete('assistant/'+id);
    console.log(resp)
  },
  async getFile({rootGetters, commit}) {
    const resp = await httpClientBot.get('assistant/file/'+rootGetters.appId.hashed);
    commit('SET_FILES', resp.data.data)
  },
  async uploadFile({ commit }, payload) {
    const resp = await httpClientBot.post('assistant/file', payload);
    console.log(resp) 
  },
  async deleteFile({ commit }, id) {
    const resp = httpClientBot.delete('assistant/file/'+id);
    console.log(resp) 
  },
  async attach(_, payload) {
    const resp = await httpClientBot.post('assistant/attach-file', payload);
    console.log(resp)
  },
  async detach(_, payload) {
    const resp = await httpClientBot.post('assistant/detach-file', payload);
    console.log(resp)
  },
  async sendPlayground(_, payload) {
    const resp = await httpClientBot.post('assistant/playground/send', payload);
    console.log(resp.data)
    return resp.data.data;
  },
  clearPlayground({commit}) {
    commit('CLEAR_PLAYGROUND')
  }
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};