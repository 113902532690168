import { httpClientOmni } from "@/libraries";
export default {
  async uploadFile(payload) {
    return await httpClientOmni.post("upload-file", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async uploadFilePrivateNote(payload) {
    return await httpClientOmni.post("upload-file-private-note", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async uploadFileDiscussion(payload) {
    return await httpClientOmni.post("upload-file", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async deleteFile(payload) {
    return await httpClientOmni.post("delete-file", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
