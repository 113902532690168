<template>
  <div id="quick-start" class="d-flex w-100 bg-white justify-content-between align-items-center px-4 py-2">
    <h6 class="text-primary mb-0">Quick Start</h6>

    <div class="d-flex">
      <base-button 
        v-for="(item, key) in items"
        :key="key"
        :customClass="'quick-start-item px-3 me-3' + (item.status ? ' active' : '')" 
        type="button"
        @click="navigateTo(item.url, item.status)">
        <span class="number-step me-3 rounded-circle">
          <i class="ri-check-line label" v-if="item.status"></i>
          <span class="label" v-else>{{ key + 1 }}</span>
        </span>
        <small>{{ item.label }}</small>
      </base-button>
    </div>

    <b-button
      class="btn"
      type="button"
      size="sm"
      :variant="''"
      :class="['btn-outline-secondary px-4 btn-demo']"
      @click="contactSales"
    >
      <small>Ask for demo</small>
    </b-button>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { httpPlain } from '@/libraries';

const router = useRouter();
const route = useRoute();

const props = defineProps({
  items: Array
});

const navigateTo = (url, status) => {
  if(status) return;
  router.push({ name: url, params: { appId: route.params.appId } });
}

const contactSales = async() => {
  const { data: { data } } = await httpPlain.get(`user-platform/user-data`);
  
  if(data.company) {
    const url = 'https://api.whatsapp.com/send/?phone=6285773749263&text=Halo%2C%20saya%20' + encodeURIComponent(data.name) + '%20dari%20' + encodeURIComponent(data.company.company_name) + '%20ingin%20menjadwalkan%20demo%20meeting%20untuk%20mengetahui%20solusi%20Lenna.ai%20lebih%20lanjut&type=phone_number';
    window.open(url, '_blank');

  } else {
    toast.error('Company data not found');
  }
}
</script>

<style lang="scss">
#quick-start {
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(to right, #f0f9ff, #e5f4ff, #daf0ff, #cfebff, #c4e6ff);
}

.quick-start-item {
  border-radius: 100px !important;
  background-color: rgba(32, 178, 156, 0.1) !important;
  border-color: transparent !important;
  color: #20B29C !important;
  display: flex !important;
  align-items: center;
  transition: 0.20s ease-in-out !important;

  .number-step {
    display: flex;
    align-items: center;
    background-color: white;
    width: 20px;
    height: 20px;
    justify-content: center;

    span.label {
      font-size: 0.7125rem;
    }
  }

  &:hover,
  &.active {
    background-color: #29ACB4 !important;
    color: white !important;

    span > .label {
      color: #29ACB4 !important;
    }
  }
}

.btn-demo {
  border-radius: 16px !important;
}
</style>