<template>
  <div id="ticket-section" class="border-bottom py-2">
    <div class="dropdown-header px-0 d-flex">
      <h6 class="text-overflow text-muted mb-1 text-uppercase flex-grow-1">
        Tickets
      </h6>
      <a
        :href="
          $router.resolve({
            name: 'ticketing.tickets.tickets-list',
            params: {
              appId: $store.getters.appId.hashed,
            },
            query: {
              filter: this.searchText,
            },
          }).href
        "
        ><span class="text-secondary flex-shrink-0"> View all </span></a
      >
    </div>
    <div class="text-center p-4" v-if="isLoading">
      <b-spinner small label="Spinning" variant="primary"></b-spinner>
    </div>
    <div class="max-sec" v-else>
      <template v-if="tickets.length > 0">
        <ul class="list-unstyled vstack gap-2 mb-0">
          <li v-for="(row, i) in tickets" :key="i">
            <a
              class="dropdown-item notify-item"
              style="display: block"
              :href="
                $router.resolve({
                  name: 'ticketing.manage.edit',
                  params: {
                    ticketId: row.id,
                    appId: $store.getters.appId.hashed,
                  },
                }).href
              "
            >
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-ticket-2-line text-warning"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ handleTextOver(row.subject) }}</h6>
                  <small class="text-muted">{{
                    formatDate(row.created_at, "dddd, DD MMMM YYYY")
                  }}</small>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="alert alert-light rounded-pill text-center" role="alert">
          <strong>No data available</strong>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  setup() {},
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      tickets: "app/tickets",
      isLoading: "app/searchLoading",
      searchText: "app/searchText",
    }),
  },
  methods: {
    handleTextOver(value) {
      if (value.length > 50) {
        return value.substr(0, 50) + "...";
      } else {
        return value;
      }
    },
  },
  created() {},
};
</script>
<style lang="scss">
#ticket-section {
  max-height: 13rem;
  overflow-y: auto;
  overflow-x: auto;
}
</style>
