import { httpClientOmni } from "@/libraries";

export default {
  async getIntegration() {
    const response = await httpClientOmni.get(`integrate`);
    return response;
  },
  async updateIntegration(data) {
    const response = await httpClientOmni.put(`integrate/update`, data);
    return response;
  },
  async saveIntegration(data) {
    const response = await httpClientOmni.post(`integrate`, data);
    return response;
  },
  async getMobileOmniUserData() {
    const response = await httpClientOmni.post(`mobile-omni`);
    return response;
  },
  async listIntegration(data) {
    const response = await httpClientOmni.get(`v3/integration?channel=${data}`);
    return response;
  },
  async enableDisableIntegration(data) {
    const response = await httpClientOmni.post(`v3/toggle-integration`, data);
    return response;
  },
  async v3TwitterSetWebhookUrl(data) {
    const response = await httpClientOmni.post(`v3/setWebhookTwitter`, data);
    return response;
  },
  async v3TwitterDeleteWebhookUrl(data) {
    const response = await httpClientOmni.post(`v3/deleteWebhookTwitter`, data);
    return response;
  },
  async v3GenerateIntegration(data) {
    const response = await httpClientOmni.post(`v3/generate-integration`, data);
    return response;
  },
  async deleteIntegration(id) {
    const response = await httpClientOmni.delete(`integration/delete/${id}`);
    return response;
  },
  async savePostmarkIntegration(data) {
    const response = await httpClientOmni.post(`v3/postmark/sender-signatures/create`, data);
    return response;
  },
  async updatePostmarkIntegration(data) {
    const response = await httpClientOmni.put(`v3/postmark/sender-signatures/update`, data);
    return response;
  },
  async deletePostmarkIntegration(id) {
    const response = await httpClientOmni.delete(`v3/postmark/sender-signatures/delete/${id}`);
    return response;
  },
};
