const knowledgebaseRoute = [
  {
    path: "/:appId/knowledge-base",
    name: "knowledge-base.knowledge-base",
    component: () => import("@/views/knowledgebase/pages/MenuArticle.vue"),
    meta: { title: "Menu Article" },
    redirect: {
      name: "knowledge-base.article.article",
    },
  },
  {
    path: "/:appId/knowledge-base",
    name: "knowledge-base.knowledge-base.category",
    component: () => import("@/views/knowledgebase/pages/MenuArticle.vue"),
    meta: { title: "Menu Article" },
    redirect: {
      name: "knowledge-base.article.category",
    },
  },
  {
    path: "/:appId/knowledge-base",
    name: "knowledge-base.knowledge-base.knowledge-configuration",
    component: () => import("@/views/knowledgebase/pages/MenuArticle.vue"),
    meta: { title: "Menu Article" },
    redirect: {
      name: "knowledge-base.article.knowledge-configuration",
    },
  },
  {
    path: "/:appId/knowledge-base",
    name: "knowledge-base.knowledge-base.article",
    component: () => import("@/views/knowledgebase/pages/MenuArticle.vue"),
    meta: { title: "Menu Article" },
    redirect: {
      name: "knowledge-base.article.article",
    },
  },
  {
    path: "/:appId/knowledge-base/article",
    name: "knowledge-base.article.article",
    component: () => import("@/views/knowledgebase/pages/MenuArticle.vue"),
    meta: { title: "Menu Article" },
  },
  {
    path: "/:appId/knowledge-base/article/create",
    name: "knowledge-base.knowledge-base.create",
    component: () => import("@/views/knowledgebase/pages/CreateArticle.vue"),
    meta: { title: "Create Article" },
  },
  {
    path: "/:appId/knowledge-base/configuration",
    name: "knowledge-base.article.knowledge-configuration",
    component: () => import("@/views/knowledgebase/pages/CustomKnowledge.vue"),
    meta: { title: "Custom Knowledge-base " },
  },
  {
    path: "/:appId/knowledge-base/categories",
    name: "knowledge-base.article.category",
    component: () => import("@/views/knowledgebase/pages/CategoriesEdit.vue"),
    meta: { title: "Categories Edit" },
  },
  {
    path: "/:appId/knowledge-base/categories",
    name: "knowledge-base.knowledge-base.faq-setting",
    component: () => import("@/views/knowledgebase/pages/CategoriesEdit.vue"),
    meta: { title: "Categories Edit" },
  },
];
export default knowledgebaseRoute;
