import { httpClientOmni } from "@/libraries";

export default {
  async getCountRoomByStatus(data) {
    return await httpClientOmni.post("/room/get-count-room-by-status", data);
  },
  async getRoomList(params) {
    return await httpClientOmni.get("/room/get-room-list-new-v2", {
      params,
    });
  },
  async getRoomListV3(body) {
    return await httpClientOmni.post("/room/get-room-list-v3", body);
  },
  async setLivechatTicket(payload) {
    return await httpClientOmni.post("/livechat/set-ticket", payload);
  },
  async getCustomer(userId) {
    const response = await httpClientOmni.post("livechat/handle", {
      agent_id: userId,
    });
    return response;
  },
  async fetchUnservedRoom() {
    const response = await httpClientOmni.post("livechat/unserved-room");
    return response;
  },
  async getRoomLink(payload) {
    let res = "";
    await httpClientOmni
      .post("v3/livecall/get-room", {
        room_id: payload,
      })
      .then((response) => {
        console.log("response.data.data", response.data.data);
        res = response.data.data;
      });

    return res;
  },

  async chatCount() {
    const response = await httpClientOmni.post("room/count");
    return response;
  },

  setLabelToSession(payload) {
    const response = httpClientOmni.post(
      "settings/conversation-label/set-to-session", {
        room_id: payload.room_id,
        conversation_label_id: payload.id,
      }
    );
    return response;
  },
};
