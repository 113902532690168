import heic2any from "heic2any";

async function convertHeicToJpeg(heicFile) {
    try {
      const jpegBlob = await heic2any({
        blob: heicFile,
        toType: "image/jpeg",
      });
      if (jpegBlob instanceof Blob) {
        return jpegBlob;
      } else {
        throw new Error("Conversion did not result in a Blob object.");
      }
    } catch (error) {
      console.error("Error converting HEIC to JPEG:", error);
      throw error;
    }
  }
export default convertHeicToJpeg;