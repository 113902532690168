import { createStore } from "vuex";

import modules from "./modules";
import Router from "@/router/index";
import { httpClientOmni } from "@/libraries";

import Hashids from "hashids";

let hashids = new Hashids("", 6);

const store = createStore({
  state: {
    appUrl: import.meta.env.VITE_OMNICHANNEL_URL,
    localStorage: {
      user: JSON.parse(localStorage.getItem("user")),
      decodedUserId: localStorage.getItem("user")
        ? hashids.decode(JSON.parse(localStorage.getItem("user")).id)[0]
        : null,
    },
    windowWidth: window.innerWidth,
    notificationSound: "default",
  },
  getters: {
    localStorage(state) {
      return state.localStorage;
    },
    router() {
      return Router.currentRoute;
    },
    appId() {
      return {
        // hashed: getters.router.params.appId,
        // decoded: hashids.decode(getters.router.params.appId)[0],
        hashed: window.location.pathname.split("/")[1],
        decoded: hashids.decode(window.location.pathname.split("/")[1])[0],
      };
    },
    notificationSound(state) {
      return state.notificationSound;
    },
  },
  mutations: {
    UPDATE_WIDTH: (state) => {
      state.windowWidth = window.innerWidth;
    },
    UPDATE_NOTIFICATION_SOUND: (state, payload) => {
      console.log({ payload });
      state.notificationSound = payload;
    },
  },
  actions: {
    getNotificationSound: ({ commit }) => {
      console.log("get notification sound");

      httpClientOmni
        .get("agent/notification-sound")
        .then((res) => {
          console.log(res.data);
          commit("UPDATE_NOTIFICATION_SOUND", res.data.data);
        })
        .catch();
    },
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
