import { httpClientOmni } from "@/libraries";

export default {
  async updateUnreadMessage(payload) {
    const response = await httpClientOmni.put(
      "message/update-unread-message",
      payload
    );
    return response;
  },
  async getMoreMessages(payload) {
    const response = await httpClientOmni.post(
      "message/get-more-message",
      payload
    );
    return response;
  },
  async sendMessage(payload) {
    const response = await httpClientOmni.post("chat/send-message", payload);
    return response;
  },

  async setPrivateNote(payload) {
    const response = await httpClientOmni.post(
      "chat/set-private-note",
      payload
    );
    return response;
  },

  async setPrivateNoteImage(payload) {
    const response = await httpClientOmni.post(
      "chat/send-message-private-note",
      payload
    );
    return response;
  },

  async searchMessage(payload) {
    const response = await httpClientOmni.post("chat/search-message", payload);
    return response;
  },
  async checkWa24Hours(payload) {
    const response = await httpClientOmni.post(
      "chat/wa-24-hour-check",
      payload
    );
    return response;
  },
};
