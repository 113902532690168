import Push from "push.js";
import { isNull, last, truncate } from "lodash";
import store from "../store";
class NotificationHelper {
  constructor(data) {
    this.vm = data.vm;
    if (!Push.Permission.has("granted")) {
      Push.Permission.request();
    }
  }
  show(data) {
    if (
      isNull(this.vm.$store.getters["chat/selectedRoom"]) ||
      this.vm.$store.getters["chat/selectedRoom"].id != data.room.id
    ) {
      this.floatingNotification(data);
    }
  }
  desktopNotification(data) {
    Push.create(data.notifTitle, {
      body: data.notifBody,
      icon:
        import.meta.env.VITE_OMNICHANNEL_URL + "/images/icons/lenna-logo.png",
      timeout: 4000,
      onClick: function () {
        window.focus();
        this.vm.close();
      },
    });
  }
  webNotification(data) {
    this.vm.$notify({
      group: "chat-notif",
      title: data.notifTitle,
      text: truncate(data.notifBody, {
        length: 70,
        omission: "...",
      }),
    });
  }

  floatingNotification(data) {
    let notificationBody = "New notification";
    switch (data.notificationType) {
      case "chat-message":
        var lastContent = last(data.message.content);
        notificationBody =
          lastContent.text || lastContent.type || "New message";
        break;
      case "live-request":
        notificationBody = "Want to live chat";
        break;
      case "chat-message-discussion":
        var contentLast = data.message.content;
        notificationBody =
          contentLast.text || contentLast.type || "New message discussion";
        break;
      case "new-ticket":
        var contentTicket = data.ticket;
        notificationBody =
          contentTicket.text || contentTicket.type || "New Ticket";
        break;
    }
    if (Push.Permission.has("granted")) {
      this.desktopNotification({
        notifTitle: data.user.name,
        notifBody: notificationBody,
      });
    } else {
      this.webNotification({
        notifTitle: data.user.name,
        notifBody: notificationBody,
      });
    }
    this.updateNotificationList({
      ...data,
      notifTitle: data.user.name,
      notifBody: notificationBody,
    });
  }
  updateNotificationList(data) {
    let notif;
    switch (data.notificationType) {
      case "chat-message":
        notif = {
          id: "chat-message" + data.room.id,
          type: "chat-message",
          unread_count: 1,
          data: {
            room: data.room,
          },
          items: [
            {
              title: data.notifTitle,
              body: data.notifBody,
              created_at: data.created_at,
            },
          ],
        };
        break;

      case "live-request":
        notif = {
          id: "live-request" + data.room.id,
          type: "live-request",
          unread_count: 1,
          data: {
            room: data.room,
          },
          items: [
            {
              title: data.notifTitle,
              body: data.notifBody,
              created_at: data.created_at,
            },
          ],
        };
        break;
    }
    store.dispatch("notification/updateNotifList", notif);
  }
}
export default NotificationHelper;
