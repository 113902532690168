<template>
  <b-modal
    v-model="modalShow"
    :title="title"
    hide-footer
    modal-class="flip"
    class="v-modal-custom"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
    centered
  >
    <form action="javascript:void(0);">
      <b-row class="g-3">
        <!-- form start -->
        <b-col xxl="12">
          <div>
            <label for="IntegrationName" class="form-label"
              >Integration Name<span class="text-danger">*</span></label
            >
            <input
              v-model="this.form.integration_data.IntegrationName"
              type="text"
              class="form-control"
              id="IntegrationName"
              placeholder="Integration Name"
              required
            />
          </div>
        </b-col>
        <b-col xxl="12">
          <div>
            <label for="chatUrl" class="form-label"
              >Chat URL / Domain Name<span class="text-danger">*</span></label
            >
            <input
              v-model="this.form.integration_data.chatUrl"
              type="text"
              class="form-control"
              id="chatUrl"
              placeholder="Chat URL / Domain Name"
              required
            />
          </div>
        </b-col>
        <b-col xxl="12">
          <div>
            <label for="ApiKey" class="form-label">API KEY<span class="text-danger">*</span></label>
            <input
              v-model="this.form.integration_data.ApiKey"
              type="text"
              class="form-control"
              id="ApiKey"
              placeholder="Api key"
              required
            />
          </div>
        </b-col>
        <!-- form end -->
        <!-- form default (dont edit) start -->
        <!-- bot -->
        <div class="col-lg-12 mt-3">
          <div>
            <label class="form-label">Bot</label>
            <v-select
              label="name"
              name="bot"
              class="bg-white"
              :options="botList"
              :placeholder="`Select bot`"
              v-model="this.bot"
              @option:selected="changeValueBotList"
            />
          </div>
        </div>
        <!-- business unit -->
        <div class="col-lg-12 mt-3">
          <div>
            <label class="form-label">Business Unit</label>
            <v-select
              label="label"
              name="branch_office"
              class="bg-white"
              :options="businessUnit"
              :placeholder="`Select bussines unit`"
              v-model="this.branch_office"
              @option:selected="changeValueBusinessUnit"
            />
          </div>
        </div>
        <!-- group -->
        <div class="col-lg-12 mt-3">
          <div>
            <label class="form-label">Groups</label>
            <v-select
              label="label"
              name="group"
              class="bg-white"
              :options="groupManagement"
              :placeholder="`Select group`"
              v-model="this.group"
              @option:selected="changeValueGroupManagement"
            />
          </div>
        </div>
        <!-- form default (dont edit) end -->
        <!-- bottom start-->
        <b-col lg="12">
          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="handleCloseModal">
              cancel</b-button
            >
            <b-button
              type="submit"
              variant="success"
              class="px-4"
              @click="handleSubmit"
              >Save</b-button
            >
          </div>
        </b-col>
        <!-- bottom end-->
      </b-row>
    </form>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
export default {
  components: {
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: "Modal Integration",
    },
  },
  data() {
    return {
      modalShow: this.showModal,
      webhookUrl: "",
      form: {
        integration_data: {},
      },
      branch_office: null,
      group: null,
      bot: null,
      omniBaseUrl: import.meta.env.VITE_OMNICHANNEL_URL,
    };
  },
  computed: {
    // dont forget this only getting data from vuex, ==================================== ||
    // make sure you call action to update this data before its show in modal box ======= ||
    ...mapGetters({
      businessUnit: "ticket/getBranchOffice",
      groupManagement: "ticket/getGroupManagement",
      botList: "bot/botList",
    }),
  },
  watch: {
    // ================================================================================== ||
    // SHOW MODAL FUNC ================================================================== ||
    // first func running aftar modalbox trigged ======================================== ||
    // Set the value of modalShow to newVal ============================================= ||
    // Deep clone the data object and assign it to form ================================= ||
    // Set branch_office, group, and bot properties based on form data ================== ||
    // Generate the webhook URL using omniBaseUrl and form.id =========================== ||
    showModal(newVal) {
      this.modalShow = newVal;
      console.log("data form dari parent ", this.data);
      if (this.data != null) {
        this.form = cloneDeep(this.data);
        this.branch_office = this.form.business_unit?.branch_office || null;
        this.group = this.form.group?.name || null;
        this.bot = this.form.bot?.name || null;
        this.webhookUrl =
          this.omniBaseUrl + "/api/webhook/freshchat/" + this.form.id;
      }
    },
  },
  methods: {
    // ================================================================================== ||
    // handleCloseModal FUNC ============================================================ ||
    handleCloseModal() {
      this.$emit("onClose"); // Emit an event named "onClose" to notify parent components
      this.modalShow = false; // Set the value of modalShow to false, hiding the modal
    },
    // ================================================================================== ||
    // handleSubmit FUNC ================================================================ ||
    // The && operator short-circuits if the left operand is falsy, ===================== ||
    // so the $emit call is only executed if the validate method returns a truthy value = ||
    handleSubmit() {
      this.validate() && this.$emit("onSubmit", this.form);
    },

    // ================================================================================== ||
    // validate FUNC ==================================================================== ||
    validate() {
      // this is shorthand ============================================================== ||
      // If integration_data is undefined or null, set it to an empty object ============ ||
      const data = this.form.integration_data || {};

      // If all three fields are truthy, the expression inside the !! operator ========== ||
      // evaluates to true, and !! converts it to the boolean value true. =============== ||
      // If any of the fields are falsy, the expression evaluates to false, ============= ||
      // and !! converts it to the boolean value false. ================================= ||
      return !!(data.IntegrationName && data.ApiKey && data.chatUrl);
    },
    // ================================================================================== ||
    // changeValueBotList FUNC ========================================================== ||
    changeValueBotList(val) {
      console.log("bot:", val);
      this.form.bot_id = val.id;
      this.bot = val.name;
    },
    // ================================================================================== ||
    // changeValueGroupManagement FUNC ================================================== ||
    changeValueGroupManagement(val) {
      console.log("group:", val);
      this.form.group_id = val.value;
      this.group = val.label;
    },
    // ================================================================================== ||
    // changeValueBusinessUnit FUNC ===================================================== ||
    changeValueBusinessUnit(val) {
      console.log("business_unit:", val);
      this.form.business_unit_id = val.id;
      this.branch_office = val.label;
    },
  },
};
</script>
