import { httpClientOmni } from "@/libraries";
import { transFormer } from "@/utils";

export default {
  async getMentionList(params) {
    const response = await httpClientOmni.get(
      "/mention/get", { params }
    );

    return transFormer.response(response);
  },
  async getMentionDetail(id) {
    const response = await httpClientOmni.get(
      "/mention/detail/" + id
    );
    
    return transFormer.response(response);
  },
  async getMentionCount() {
    const response = await httpClientOmni.get(
      "/mention/count"
    );
    
    return transFormer.response(response);
  },
  async sendReply(params) {
    const response = await httpClientOmni.post(
      "/mention/reply", params
    );

    return transFormer.response(response);
  },
  async getAccountIntegration(params) {
    const response = await httpClientOmni.post(
      "/mention/account-integration", params
    );

    return transFormer.response(response);
  },
}