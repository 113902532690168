import { httpClientOmni } from "@/libraries";

const state = () => ({
  products: [],
  productCategories: [],
});

const getters = {
  selectProductCategories: (state) => {
    return state.productCategories.map((r) => {
      return {
        id: r.id,
        label: r.name,
      };
    });
  },
};

const mutations = {
  SET_PRODUCT: (state, data) => {
    state.products = [...state.products, ...data];
  },

  ADD_PRODUCT: (state, product) => {
    state.products = [...state.products, product];
  },

  SET_PRODUCT_CATEGORIES: (state, data) => {
    state.productCategories = data;
  },
};

const actions = {
  addProduct({ state, commit }, data) {
    const check = state.products.find((product) => product.slug === data.slug);
    if (!check) {
      commit("ADD_PRODUCT", data);
    }
  },

  async getProducts({ state, commit }, slug = []) {
    const slugFiltered = slug.filter((r) => {
      return !state.products.find((product) => product.slug === r);
    });

    const endpoint = `v3/sales/products?slug=${slugFiltered.join(
      "|"
    )}&without_pagination=1`;

    const req = await httpClientOmni.get(endpoint);

    if (req.status === 200) {
      commit("SET_PRODUCT", req.data);
    }
  },

  async getProductCategories({ state, commit }) {
    if (!state.productCategories.length) {
      try {
        const endpoint = "v3/sales/product-categories?get_all=1";
        const req = await httpClientOmni.get(endpoint);
        commit("SET_PRODUCT_CATEGORIES", req.data);
      } catch (error) {
        if (error.response) console.log(error.response.data);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
