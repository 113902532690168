const salesRoutes = [
  {
    path: "/:appId/sales/stores",
    name: "sales.sales.stores",
    component: () => import("@/views/sales/store/Index.vue"),
    meta: { title: "Store" },
  },
  {
    path: "/:appId/sales",
    component: () => import("@/views/sales/LayoutSales.vue"),
    children: [
      {
        path: "/:appId/sales/orders",
        name: "sales.sales.orders",
        component: () => import("@/views/sales/order/Index.vue"),
        meta: { title: "Order" },
      },
      {
        path: "/:appId/sales/orders/create",
        name: "sales.sales.orders.create",
        component: () => import("@/views/sales/order/Form.vue"),
        meta: { title: "Create Order" },
      },
      {
        path: "/:appId/sales/setting",
        name: "sales.sales.settings",
        component: () => import("@/views/sales/setting/Index.vue"),
        meta: { title: "Setting" },
      },
      {
        path: "/:appId/sales/product-categories",
        name: "sales.sales.product-categories",
        component: () => import("@/views/sales/product-category/Index.vue"),
        meta: { title: "Product Categories" },
      },
      {
        path: "/:appId/sales/products",
        name: "sales.sales.products",
        component: () => import("@/views/sales/product/Index.vue"),
        meta: { title: "Product" },
      },
      {
        path: "/:appId/sales/payment-gateway-integrations",
        name: "sales.sales.payment-gateway-integrations",
        component: () =>
          import("@/views/sales/payment-gateway-integration/Index.vue"),
        meta: { title: "Payment gateway integration" },
      },
    ],
  },
];

export default salesRoutes;
