<template>
  <div class="card-body">
    <div class="file-manager-content w-100 p-4 pb-0">
      <div class="row">
        <div class="col-9 col-lg-9 col-md-9">
          <h5 class="fw-semibold mb-4">
            <ImgLazy
              :src="`/img/new-channels/postmark.png`"
              error-img="/img/new-channels/integration.png"
              class="me-2"
              style="width: 40px; height: 40px"
            />
            Integration with Postmark
          </h5>
        </div>
        <div class="col-3 col-lg-3 col-md-3 d-flex flex-row-reverse">
          <div class="hstack gap-2">
            <button class="d-flex btn btn-outline-primary" @click="showModalCreate">
              <i class="ri-add-line me-2"></i> Create new Integration
            </button>
          </div>
        </div>
      </div>

      <div class="todo-content position-relative px-4 mx-n4">
        <div class="todo-task">
          <div class="table-responsive">
            <base-table
              :columns="columns"
              :url="'/v3/postmark/sender-signatures/list?channel=postmark'"
              @selectedData="selectData"
              :row-selection="false"
            >
              <template #slot-column-0="{ rowData }">
                <label class="form-check-label" for="todo15">
                  {{ rowData.integration_data.integration_name }}
                </label>
                <p v-if="rowData.integration_data.is_verified">
                  Identity is verified and ready to use
                </p>
              </template>
              <template #slot-column-1="{ rowData }">
                <p>
                  {{ rowData.integration_data.email }}
                </p>
              </template>
              <template #slot-column-2="{ rowData }">
                <p>
                  {{
                    rowData.integration_data.domain
                      ? rowData.integration_data.domain
                      : "-"
                  }}
                </p>
              </template>
              <template #slot-column-3="{ rowData }">
                <span v-if="rowData.status" class="badge bg-success">
                  Active
                </span>
                <span v-else class="badge bg-danger">Not Active</span>
              </template>
              <template #slot-column-4="{ rowData }">
                <i v-if="rowData.is_primary" class="ri-shield-check-fill fs-4 text-secondary"></i>
              </template>
              <template #slot-column-5="{ rowData }">
                <span>
                  <div class="dropdown text-center">
                    <button
                      class="btn btn-light btn-sm dropdown"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="ri-more-fill"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <a class="dropdown-item" @click="showModalEdit(rowData)"
                          ><i
                            class="ri-apps-fill align-bottom me-2 text-muted"
                          ></i>
                          Manage
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item remove-item-btn"
                          @click="showModalDelete(rowData)"
                        >
                          <i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </template>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- card list integration start -->
  <Modal
    :showModal="show.add"
    :loading="show.loading"
    @onClose="show.add = false"
    @onSubmit="onSubmit"
    title="Setup Your Postmark Integration"
  />
  <Modal
    :data="form"
    :showModal="show.edit"
    @onClose="show.edit = false"
    @onSubmit="onUpdate"
    @onSetPrimary="onSetPrimary"
    title="Edit Your Postmark Integration"
  />
  <modal-delete
    :showModal="show.delete"
    :isLoading="show.loading"
    :isInformation="`Do you really want to delete these records? This process cannot be undone.`"
    @onSubmit="onDelete"
    @onHidden="show.delete = false"
  ></modal-delete>
</template>

<script>
import { nextTick } from "vue";
import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
import { mapGetters, mapActions } from "vuex"; // best practice for using actions and actions.
import Modal from "@/views/integration/components/postmark/Modal"; // modal box for edit and create.
import ImgLazy from "@/components/base/ImgLazy.vue";

export default {
  components: {
    Modal,
    ImgLazy,
  },
  data() {
    return {
      show: {
        delete: false,
        edit: false,
        add: false,
        loading: false,
      },
      form: {},
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: 0,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: 1,
          sorter: true,
          ellipsis: true,
        },
        {
          title: "Domain",
          dataIndex: "domain",
          key: 2,
        },
        {
          title: "Status",
          dataIndex: "is_verified",
          key: 3,
        },
        {
          title: "Primary",
          dataIndex: "is_primary",
          key: 4,
          align: "center",
          width: "5%"
        },
        {
          title: "Actions",
          key: 5,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      listIntegration: "integration/integrationChannel",
    }),
  },
  async created() {
    this.getIntegrations("postmark");
    this.$store.dispatch("ticket/getBranchOffice"); // dipanggil disini jadi ntar modal kebuka langsung ada
  },
  methods: {
    // ====================================================== ||
    // key di mapActions bisa di panggil seperti method biasa ||
    ...mapActions({
      getIntegrations: "integration/getIntegrationChannel",
      updateIntegration: "integration/updatePostmarkIntegration",
      saveIntegration: "integration/savePostmarkIntegration",
      deleteIntegration: "integration/deletePostmarkIntegration",
    }),
    // ====================================================== ||
    // on click add button ================================== ||
    // modal will appear after this method triggered. ======= ||
    showModalCreate() {
      this.form = false; // reset
      this.show.add = true;
    },
    // ====================================================== ||
    // on click edit button, ================================ ||
    // edit modal will appear after this method triggered. == ||
    // use cloneDeep if you want to edit data from vuex. ==== ||
    showModalEdit(data) {
      this.form = cloneDeep(data);
      this.show.edit = true;
    },
    // ====================================================== ||
    // on click delete button, ============================== ||
    // confirm modal will appear after this method triggered. ||
    // use cloneDeep if you want to edit data from vuex. ==== ||
    showModalDelete(data) {
      this.form = cloneDeep(data);
      this.show.delete = true;
    },
    // ====================================================== ||
    // submit integration =================================== ||
    // this will processess after submit button clicked ===== ||
    // first we restructure the data from modal box. ======== ||
    // and send it to create func =========================== ||
    // nextTick is a vue func =============================== ||
    // to make sure getIntegrations run after create func done||
    async onSubmit(data) {
      this.show.loading = true;
      this.reform(data); // prepare the data
      await this.create(); // send to app project
      await nextTick();
      this.show.loading = false;
      this.emitter.emit("table::onRefresh");
    },

    async onUpdate(data) {
      this.reform(data); // prepare the data
      await this.update(); // send to app project
      await nextTick();
      this.loading = false;
      this.emitter.emit("table::onRefresh");
    },
    async onSetPrimary() {
      this.show.edit = false;
      this.emitter.emit("table::onRefresh");
    },
    // ====================================================== ||
    // delete integration =================================== ||
    // this will processess after delete button clicked ===== ||
    // and send it to delete func =========================== ||
    // nextTick is a vue func =============================== ||
    // to make sure getIntegrations run after create func done||
    async onDelete() {
      this.show.loading = true;
      await this.delete(); // send to app project
      await nextTick();
      this.show.loading = false;
      this.show.delete = false;
      this.emitter.emit("table::onRefresh");
    },
    // ====================================================== ||
    // create func ========================================== ||
    // send form data to backend ============================ ||
    async create() {
      try {
        await this.saveIntegration(this.form); // add integration.
        this.successToast("our data has been successfully added.");
        this.show.add = false;
      } catch (error) {
        console.log("error data:", error);
        this.errorToast("Oops! Your integration could not be added.");
      }
    },
    // ====================================================== ||
    // update func ========================================== ||
    // send form data to backend ============================ ||
    async update() {
      try {
        let response = await this.updateIntegration(this.form); // update integration.
        if (response.data.success) {
          this.successToast("Status has been successfully updated.");
          this.show.edit = false;
        }
      } catch (error) {
        this.errorToast("Oops! Your integration could not be updated.");
      }
    },
    // ====================================================== ||
    // delete func ========================================== ||
    // send form data to backend ============================ ||
    async delete() {
      let response = await this.deleteIntegration(this.form.id); // delete integration.
      if (response.data.success) {
        this.successToast("Your data has been successfully deleted.");
      } else {
        this.errorToast("Oops! Your integration could not be deleted.");
      }
    },
    // ====================================================== ||
    // reform is a helper func ============================== ||
    // its help to refactor modalbox data to form data ====== ||
    reform(data) {
      this.form = data;
      this.form.status = this.form.status ? this.form.status : false;
      this.form.channelName = "postmark";
      this.form.integrationId = this.form.id; // for update purpose
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.list-move, /* apply transition to moving elements */
  .list-enter-active,
  .list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
     animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>

<!-- || ================================================================== || -->
<!-- || Note :  ========================================================== || -->
<!-- || If you want to re-use this page to other channel ================= || -->
<!-- || Just edit some points below ====================================== || -->
<!-- ||  - edit card-title inside template =============================== || -->
<!-- ||  - use your model base on your channel =========================== || -->
<!-- ||  - Parsing your channel to getIntegrations func ================== || -->
<!-- ||  - change reform func inside methods depending your data structure || -->
<!-- || ================================================================== || -->
