import { httpClientOmni } from "@/libraries";

const state = {
  stores: [],
  selectedStore: null,

  productCategories: {
    loading: false,
    data: [],
  },
};

const getters = {
  stores: (state) => state.stores,
  selectedStoreId: (state) => state.selectedStore,
  selectedStore: (state) => state.find((r) => r.id === state.selectedStore),
  productCategories: (state) => state.productCategories.data,
  loadingProductCategories: (state) => state.productCategories.loading,
};

const mutations = {
  SET_STORES: (state, payload) => (state.stores = payload),
  SET_STORE: (state, payload) => (state.selectedStore = payload),
  SET_LOADING_PRODUCT_CATEGORIES: (state, payload) => {
    state.productCategories.loading = payload;
  },
  APPEND_PRODUCT_CATEGORIES: (state, payload) => {
    state.productCategories.data = [
      ...state.productCategories.data,
      ...payload,
    ];
  },
  REPLACE_PRODUCT_CATEGORIES: (state, payload) => {
    state.productCategories.data = state.productCategories.data.filter(
      (r) => r.store_id !== payload.store_id
    );

    state.productCategories.data = [
      ...state.productCategories.data,
      ...payload.data,
    ];
  },
};

const actions = {
  async fetchStores({ commit, getters }, refresh = false) {
    if (!getters.stores.length || refresh) {
      try {
        const endpoint = "v3/sales/stores/all";
        const req = await httpClientOmni.get(endpoint);
        const data = req.data.data;

        commit("SET_STORES", data);
        if (data.length) commit("SET_STORE", data[0].id);
      } catch (_) {
        /* empty */
      }
    }
  },

  setStore({ commit, getters }, payload) {
    const check = getters.stores.find((r) => r.id === payload);
    if (check) commit("SET_STORE", payload);
  },

  async fetchProductCategories({ commit, getters }, payload) {
    const check = getters.productCategories.filter(
      (r) => r.store_id === payload.store_id
    ).length;

    if (!check || payload?.refresh) {
      commit("SET_LOADING_PRODUCT_CATEGORIES", true);
      try {
        const endpoint = `v3/sales/product-categories/all?store_id=${payload.store_id}`;
        const req = await httpClientOmni.get(endpoint);
        const data = req.data.data.map((r) => ({ ...r, label: r.name }));

        commit("REPLACE_PRODUCT_CATEGORIES", {
          store_id: payload.store_id,
          data,
        });
      } catch (_) {
        /* empty */
      }
      commit("SET_LOADING_PRODUCT_CATEGORIES", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
