<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header shadow">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img
                  src="@/assets/images/logo-icon.svg"
                  alt="logo-sm-dark"
                  style="height: 22px"
                />
              </span>
              <span class="logo-lg">
                <img
                  src="@/assets/images/logo-icon.svg"
                  alt="logo-lg-dark"
                  style="height: 30px"
                />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img
                  src="@/assets/images/logo-icon.svg"
                  alt="logo-sm-light"
                  style="height: 22px"
                />
              </span>
              <span class="logo-lg">
                <img
                  src="@/assets/images/logo-icon.svg"
                  alt="logo-lg-light"
                  style="height: 30px"
                />
              </span>
            </router-link>
          </div>
          <div
            class="app-search pt-4"
            style="margin-left: -15px; margin-right: 30px"
            v-show="layoutType == 'horizontal'"
          >
            <h5>Lenna Workspace</h5>
          </div>

          <button
            type="button"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
            v-show="layoutType == 'twocolumn'"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
          <search
            class="search-position"
            :class="search !== '' ? 'd-block' : 'd-none'"
            :project="projectList"
            :ticket="tickets"
            :customer="customers"
          ></search>
          <!-- App Search-->
          <form
            class="app-search d-none d-md-block"
            v-if="layoutType == 'twocolumn'"
          >
            <div class="position-relative">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                autocomplete="off"
                id="search-options"
                v-model="search"
              />
              <span class="mdi mdi-magnify search-widget-icon"></span>
              <span
                role="button"
                @click.prevent="search = ''"
                class="mdi mdi-close-circle search-widget-icon search-widget-icon-close"
                :class="search !== '' ? 'd-block' : 'd-none'"
                id="search-close-options"
              ></span>
            </div>
            <div
              class="dropdown-menu dropdown-menu-lg"
              :class="'d-none'"
              id="search-dropdown"
            >
              <!-- :class="search !== '' ? 'd-block' : 'd-none'" -->

              <div class="text-center p-4" v-if="loadingTicket">
                <b-spinner small label="Spinning" variant="primary"></b-spinner>
              </div>
              <SimpleBar data-simplebar style="max-height: 320px" v-else>
                <!-- item-->
                <div
                  class="dropdown-header"
                  v-if="recentSearches && recentSearches.length > 0"
                >
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Recent Searches
                  </h6>
                </div>

                <div
                  class="dropdown-item bg-transparent text-wrap"
                  v-if="recentSearches && recentSearches.length > 0"
                >
                  <a
                    v-for="(item, i) in recentSearches"
                    :key="i"
                    @click.prevent="search = item"
                    href="javascript:void(0)"
                    class="btn btn-soft-secondary btn-sm btn-rounded me-1 mb-1"
                    >{{ item }} <i class="mdi mdi-magnify ms-1"></i
                  ></a>
                </div>
                <!-- item-->
                <div class="dropdown-header mt-2" v-if="projectList.length > 0">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Projects
                  </h6>
                </div>

                <!-- item-->
                <a
                  v-for="(app, i) in projectList"
                  :key="i"
                  @click.prevent="syncRole(app)"
                  href="javascript:void(0);"
                  class="dropdown-item notify-item"
                >
                  <!-- <i
                    class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"
                  ></i> -->
                  <span>{{ app.name == "" ? "Loading" : app.name }}</span>
                </a>

                <!-- item-->
                <div class="dropdown-header mt-2" v-if="ticket != []">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Ticket
                  </h6>
                </div>

                <!-- item-->
                <div v-for="(item, i) in ticket" :key="i" href="">
                  <a
                    :href="
                      $router.resolve({
                        name: 'ticketing.manage.edit',
                        params: { ticketId: item.id },
                      }).href
                    "
                    class="dropdown-item notify-item"
                  >
                    <span>{{
                      item.ticket_id +
                      " | " +
                      item.subject +
                      " | " +
                      item.status
                    }}</span>
                  </a>
                </div>

                <!-- <div
                  class="dropdown-header mt-2"
                  v-if="ticketSubject.length > 0"
                >
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Ticket Subject
                  </h6>
                </div>

                <a v-for="(item, i) in ticketSubject" :key="i" href="">
                  <router-link
                    :to="{
                      name: 'ticketing.manage.edit',
                      params: { ticketId: item.id },
                    }"
                    class="dropdown-item notify-item"
                  >
                    <span>{{
                      item.subject == "" ? "Loading" : item.subject
                    }}</span>
                  </router-link>
                </a> -->

                <div class="dropdown-header mt-2" v-if="ticket != []">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Ticket Name
                  </h6>
                </div>

                <a v-for="(item, i) in ticket" :key="i" href="">
                  <a
                    :href="
                      $router.resolve({
                        name: 'ticketing.manage.edit',
                        params: { ticketId: item.id },
                      }).href
                    "
                    class="dropdown-item notify-item"
                  >
                    <span>{{ item.name + " | " + item.subject }}</span>
                  </a>
                </a>

                <div class="dropdown-header mt-2" v-if="ticket != []">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Ticket Customer
                  </h6>
                </div>

                <a v-for="(item, i) in ticket" :key="i" href="">
                  <a
                    :href="
                      $router.resolve({
                        name: 'ticketing.manage.edit',
                        params: { ticketId: item.id },
                      }).href
                    "
                    class="dropdown-item notify-item"
                  >
                    <span>{{
                      item.user.name +
                      " | " +
                      item.user.email +
                      " | " +
                      item.user.phone
                    }}</span>
                  </a>
                </a>
                <!-- item-->
                <!-- <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-2 text-uppercase">
                    Members
                  </h6>
                </div>

                <div class="notification-list">
                  <a
                    href="javascript:void(0);"
                    class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                      src="@/assets/images/users/avatar-2.jpg"
                      class="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">Angela Bernier</h6>
                      <span class="fs-11 mb-0 text-muted">Manager</span>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      class="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">David Grasso</h6>
                      <span class="fs-11 mb-0 text-muted">Web Designer</span>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                      src="@/assets/images/users/avatar-5.jpg"
                      class="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">Mike Bunch</h6>
                      <span class="fs-11 mb-0 text-muted">React Developer</span>
                    </div>
                  </a>
                </div> -->
              </SimpleBar>

              <!-- <div class="text-center pt-3 pb-1">
                <router-link
                  to="/pages/search-results"
                  class="btn btn-primary btn-sm"
                  >View All Results <i class="ri-arrow-right-line ms-1"></i
                ></router-link>
              </div> -->
            </div>
          </form>
          <div class="header-item ms-2" v-if="decodedAppId !== undefined">
            <!-- <span class="d-flex btn btn-light app-label border">
              <i class="ri-ghost-fill me-2"></i>
              <span class="text-muted fw-400 m-0 align-self-center">
                {{ currentProject }}
              </span>
            </span> -->
            <h6 class="font-poppins">{{ currentProject }}</h6>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="ms-1 header-item d-none d-sm-flex gap-1">
            <span
              class="dot"
              :class="{
                'bg-success': socketStatus == 'Connected',
                'bg-danger': socketStatus == 'Disconected',
              }"
            ></span>
            <span class="text-muted">{{ socketStatus }}</span>
          </div>
          <div class="ms-1 header-item d-none d-sm-flex">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
              @click="toggleDarkMode"
            >
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-notifications-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-bell fs-22"></i>
              <span
                class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"
                v-if="unreadNotifCount"
              >
                {{ unreadNotifCount
                }}<span class="visually-hidden">unread messages</span></span
              >
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications-dropdown"
            >
              <div class="dropdown-head bg-primary bg-pattern rounded-top">
                <div class="p-3">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 fs-16 fw-semibold text-white">
                        Notifications
                      </h6>
                    </div>
                    <div class="col-auto dropdown-tabs">
                      <span class="badge badge-soft-light fs-13">{{
                        notifList.total
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="px-2 pt-2">
                  <ul
                    class="nav nav-tabs dropdown-tabs nav-tabs-custom"
                    data-dropdown-tabs="true"
                    id="notificationItemsTab"
                    role="tablist"
                    auto-close="outside"
                    @click.capture.stop
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-bs-toggle="tab"
                        href="#all-noti-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        All ({{ notifList.total }})
                      </a>
                    </li>
                    <!-- <li class="nav-item">
                      <a
                        class="nav-link"
                        data-bs-toggle="tab"
                        href="#messages-tab"
                        role="tab"
                        aria-selected="false"
                      >
                        Messages
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-bs-toggle="tab"
                        href="#alerts-tab"
                        role="tab"
                        aria-selected="false"
                      >
                        Alerts
                      </a>
                    </li> -->
                  </ul>
                </div>
              </div>

              <div class="tab-content" id="notificationItemsTabContent">
                <div
                  class="tab-pane fade show active py-2 ps-2"
                  id="all-noti-tab"
                  role="tabpanel"
                >
                  <div class="text-center p-4" v-if="!notifList">
                    <b-spinner
                      small
                      label="Spinning"
                      variant="primary"
                    ></b-spinner>
                  </div>
                  <SimpleBar
                    data-simplebar
                    style="max-height: 300px; overflow-y: auto"
                    class="pe-2"
                    v-else
                  >
                    <!-- <div v-for="(item, i) in notifList.data" :key="i"> -->
                    <InfiniteScroll :items="notifList" @refetch="fetchNotif">
                      <template v-slot:item="{ item }">
                        <div
                          class="position-relative text-reset notification-item dropdown-item d-block"
                          :style="
                            item.read == false
                              ? 'background-color: #ebebeb'
                              : 'background-color: #ffffff'
                          "
                        >
                          <div class="d-flex">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle bg-light text-primary"
                              >
                                {{ item.name.substr(0, 2).toUpperCase() }}
                              </div>
                            </div>
                            <div class="flex-1">
                              <a
                                :href="url + item.url"
                                @click="handleReadNotif(item.id)"
                                class="stretched-link"
                                ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                  {{ item.name }}
                                </h6></a
                              >
                              <div class="fs-13 text-muted">
                                <p class="mb-1">
                                  {{ item.title }}
                                </p>
                              </div>
                              <p
                                class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                              >
                                <span
                                  ><i class="mdi mdi-clock-outline"></i
                                  >{{ changeDate(item.created_at) }}</span
                                >
                              </p>
                            </div>
                            <div class="px-2 fs-15">
                              <input class="form-check-input" type="checkbox" />
                            </div>
                          </div>
                        </div>
                      </template>
                    </InfiniteScroll>
                    <!-- </div> -->
                    <div
                      v-if="notifList.total > per_page"
                      v-observe-visibility="handleToScrolledToBottom"
                    ></div>
                    <div
                      class="text-center p-4"
                      v-if="notifList.total > per_page"
                    >
                      <b-spinner
                        small
                        label="Spinning"
                        variant="primary"
                      ></b-spinner>
                    </div>
                  </SimpleBar>
                </div>

                <div
                  class="tab-pane fade py-2 ps-2"
                  id="messages-tab"
                  role="tabpanel"
                  aria-labelledby="messages-tab"
                >
                  <SimpleBar
                    data-simplebar
                    style="max-height: 300px"
                    class="pe-2"
                  >
                    <div
                      class="text-reset notification-item d-block dropdown-item"
                    >
                      <div class="d-flex">
                        <img
                          src="@/assets/images/users/avatar-3.jpg"
                          class="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div class="flex-1">
                          <a href="#!" class="stretched-link"
                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              James Lemire
                            </h6></a
                          >
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p
                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                          >
                            <span
                              ><i class="mdi mdi-clock-outline"></i> 30 min
                              ago</span
                            >
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div
                      class="text-reset notification-item d-block dropdown-item"
                    >
                      <div class="d-flex">
                        <img
                          src="@/assets/images/users/avatar-2.jpg"
                          class="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div class="flex-1">
                          <a href="#!" class="stretched-link"
                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Angela Bernier
                            </h6></a
                          >
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Answered to your comment on the cash flow
                              forecast's graph 🔔.
                            </p>
                          </div>
                          <p
                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                          >
                            <span
                              ><i class="mdi mdi-clock-outline"></i> 2 hrs
                              ago</span
                            >
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div
                      class="text-reset notification-item d-block dropdown-item"
                    >
                      <div class="d-flex">
                        <img
                          src="@/assets/images/users/avatar-6.jpg"
                          class="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div class="flex-1">
                          <a href="#!" class="stretched-link"
                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Kenneth Brown
                            </h6></a
                          >
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Mentionned you in his comment on 📃 invoice
                              #12501.
                            </p>
                          </div>
                          <p
                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                          >
                            <span
                              ><i class="mdi mdi-clock-outline"></i> 10 hrs
                              ago</span
                            >
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div
                      class="text-reset notification-item d-block dropdown-item"
                    >
                      <div class="d-flex">
                        <img
                          src="@/assets/images/users/avatar-8.jpg"
                          class="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div class="flex-1">
                          <a href="#!" class="stretched-link"
                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Maureen Gibson
                            </h6></a
                          >
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p
                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                          >
                            <span
                              ><i class="mdi mdi-clock-outline"></i> 3 days
                              ago</span
                            >
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="my-3 text-center">
                      <button type="button" class="btn btn-soft-success">
                        View All Messages
                        <i class="ri-arrow-right-line align-middle"></i>
                      </button>
                    </div>
                  </SimpleBar>
                </div>
                <div
                  class="tab-pane fade p-4"
                  id="alerts-tab"
                  role="tabpanel"
                  aria-labelledby="alerts-tab"
                >
                  <div class="w-25 w-sm-50 pt-3 mx-auto">
                    <img
                      src="@/assets/images/svg/bell.svg"
                      class="img-fluid"
                      alt="user-pic"
                    />
                  </div>
                  <div class="text-center pb-5 mt-2">
                    <h6 class="fs-18 fw-semibold lh-base">
                      Hey! You have no any notifications
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
              @click="recordScreen()"
              id="btn-record"
            >
              <i class="ri-record-circle-line fs-22"></i>
            </button>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="/img/icons/download.svg"
                alt="download"
                style="width: 22px"
              />
            </button>

            <div class="dropdown-menu dropdown-menu-end px-2">
              <a
                v-for="downloadItem in download.data"
                :key="downloadItem.label"
                :href="downloadItem.link"
                target="_blank"
                class="dropdown-item"
              >
                <img
                  :src="downloadItem.img"
                  :alt="downloadItem.label"
                  style="width: 20px; height: 20px; margin-right: 20px"
                />

                <span>{{ downloadItem.label }}</span>
              </a>
            </div>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user">
            <button
              type="button"
              class="btn"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="d-flex align-items-center">
                <div class="avatar-sm p-1">
                  <img
                    v-if="this.avatar"
                    :src="this.avatar"
                    class="avatar-navbar rounded-circle object-cover"
                    alt="profile"
                    width="40"
                    height="40"
                  />
                  <div
                    v-else
                    class="avatar-title bg-soft-success text-success rounded-circle fs-12"
                  >
                    {{ acronymText(profile.name) }}
                  </div>
                </div>
                <span class="text-start ms-xl-2">
                  <span
                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                  >
                    {{ truncateText(profile.name, 21) }}
                  </span>
                  <span
                    class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                  >
                    {{ currentRole.length > 0 ? currentRole[0].name : "" }}
                  </span>
                </span>
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end px-2"
              id="page-header-user-dropdown-menu"
            >
              <p class="dropdown-header">
                {{ truncateText(profile.email, 21) }} <br />
              </p>
              <div class="dropdown-divider" v-if="$route.params.appId"></div>
              <b-button-group
                vertical
                role="group"
                aria-label="Vertical radio toggle button group"
                v-if="$route.params.appId"
              >
                <div v-for="(val, key) in stateList" :key="key">
                  <input
                    type="radio"
                    class="btn-check"
                    name="vbtn"
                    :id="'vbtn-radio' + key"
                  />
                  <label
                    class="btn dropdown-item"
                    :class="{ active: val.key == userState }"
                    style="text-align: left"
                    :for="'vbtn-radio' + 1"
                    @click="changeState(val.key)"
                  >
                    <span class="dot" :class="val.color"></span> {{ val.label }}
                    <i class="ri-check-fill" v-if="val.key == userState"></i>
                  </label>
                </div>
              </b-button-group>
              <div class="dropdown-divider"></div>
              <a
                v-if="decodedAppId !== undefined"
                @click="goToProfile"
                class="dropdown-item"
                ><i
                  class="mdi mdi-account-circle-outline text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Profile</span>
              </a>
              <a
                href="javascript:void(0)"
                @click="backToWorkspace"
                class="dropdown-item"
              >
                <i
                  class="ri ri-keyboard-line text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Workspace</span>
              </a>
              <a
                v-if="$route.params.appId"
                href="javascript:void(0)"
                @click="goToNotificationSound"
                class="dropdown-item"
              >
                <i
                  class="ri ri-volume-up-line text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Notification Sound</span>
              </a>
              <a
                v-if="decodedAppId !== undefined"
                @click="goToUrlBilling"
                href="javascript:void(0)"
                class="dropdown-item"
              >
                <i
                  class="ri ri-bill-line text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Billing</span>
              </a>
              <a class="dropdown-item" href="#" @click="handleUserLogout"
                ><i
                  class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Logout</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <base-modal
    :isOpen="modal.change"
    modalTitle="Change Status"
    :modalFooter="false"
    @hide="modal.change = false"
    @hidden="modal.change = false"
    @onClose="modal.change = false"
  >
    <div class="container py-3 px-4">
      <Form
        @submit="handleChangeStatus"
        :validation-schema="schema"
        v-slot="{ errors }"
        autocomplete="off"
      >
        <div class="row">
          <div class="col-12">
            <base-label text="Reason" />
            <!-- <small class="d-flex mb-2">
              Story name will be your story's identity and can be describe what
              user will do
            </small> -->
            <Field
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.reason }"
              id="reason"
              @input="isError = false"
              v-model="reason"
              placeholder="Enter Reason"
              name="reason"
              required
              as="textarea"
            />
          </div>
        </div>
        <div class="col-12 mt-4 d-flex justify-content-end">
          <base-button
            type="submit"
            :variant="'primary'"
            :loading="modal.loading"
          >
            Submit
          </base-button>
        </div>
      </Form>
    </div>
  </base-modal>
</template>
<script>
import { SimpleBar } from "simplebar-vue3";
import { StorageService, AuthService } from "@/services/auth";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
// import Hashids from "hashids";
// import { cloneDeep } from "lodash";
import { httpClientOmni } from "@/libraries";
import { debounce } from "lodash";
import i18n from "../i18n";
import InfiniteScroll from "./InfiniteScroll.vue";
import Divider from "ant-design-vue/lib/divider"; // for js
import "ant-design-vue/lib/divider/style/css"; // for css
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Search from "@/views/auth/components/Search.vue";

/**
 * Nav-bar Component
 */
export default {
  components: {
    SimpleBar,
    InfiniteScroll,
    "a-divider": Divider,
    Form,
    Field,
    Search,
  },
  emits: ["setAlertTopbar"],
  data() {
    return {
      debounce,
      languages: [
        {
          flag: require("@/assets/images/flags/us.svg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.svg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.svg"),
          language: "sp",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/china.svg"),
          language: "ch",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/germany.svg"),
          language: "gr",
          title: "Deutsche",
        },
        {
          flag: require("@/assets/images/flags/russia.svg"),
          language: "ru",
          title: "русский",
        },
      ],
      url: this.urlTemp + "/" + this.$store.getters.appId.hashed + "/",
      app_id: this.$store.getters.appId.hashed,
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      profile: {
        name: StorageService.get("name"),
        email: StorageService.get("email"),
        id: StorageService.get("id"),
        picture: StorageService.get("picture"),
      },
      // ticketId: [],
      ticket: [],
      search: "",
      user: this.$store.getters.localStorage.user,
      decodedAppId: this.$store.getters.appId.decoded,
      per_page: 10,
      loadingTicket: false,
      stateList: [
        {
          key: "online",
          label: "Online",
          color: "bg-success",
        },
        {
          key: "break",
          label: "Break",
          color: "bg-opacity-50",
        },
        {
          key: "offline",
          label: "Offline",
          color: "bg-danger",
        },
      ],
      modal: {
        change: false,
        loading: false,
      },
      schema: Yup.object().shape({
        reason: Yup.string().required("Reason is required"),
      }),
      isError: false,
      reason: null,
      changedState: null,
      download: {
        show: false,
        data: [
          {
            img: "/img/icons/playstore.png",
            label: "Download Omni Mobile on Playstore",
            link: "https://play.google.com/store/apps/details?id=com.omnimobilev2",
          },
          {
            img: "/img/icons/app-store.png",
            label: "Download Omni Mobile on Appstore",
            link: "https://apps.apple.com/us/app/lenna-chat/id6445840405",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("app", {
      userApp: (state) => state.userApp,
      data: (state) => state.data,
    }),
    ...mapGetters({
      layoutType: "layout/layoutType",
      recentSearches: "app/recentSearches",
      notifList: "app/notifList",
      unreadNotifCount: "app/unread_notif_count",
      avatar: "agent/profilePicture",
      socketStatus: "agent/socketStatus",
      tickets: "app/tickets",
      customers: "app/customers",
      userState: "agent/getState",
    }),
    projectList() {
      if (!this.search) {
        return this.userApp.filter((o, k) => k < 5);
      } else {
        return this.userApp
          .filter((each_app) =>
            each_app.name.toLowerCase().match(this.search.toLowerCase())
          )
          .filter((o, k) => k < 5);
      }
    },
    currentUrlParams() {
      return this.$route.params;
    },
    currentProject() {
      if (this.currentUrlParams.appId) {
        var appId = this.currentUrlParams.appId;
        let data = this.userApp.filter(function (q) {
          return q.hashed_id === appId;
        });
        if (data.length > 0) {
          return data[0].name;
        }
      }
      return "";
    },
    // ticketList() {
    //   return this.ticket.length > 0
    //     ? this.ticket.filter(
    //         (each_ticket) =>
    //           each_ticket.ticket_id
    //             .toLowerCase()
    //             .match(this.search.toLowerCase()) ||
    //           each_ticket.subject
    //             .toLowerCase()
    //             .match(this.search.toLowerCase()) ||
    //           each_ticket.status.toLowerCase().match(this.search.toLowerCase())
    //       )
    //     : [];
    // },
    // ticketName() {
    //   // if (this.search !== "") {
    //   //   var vm = this;
    //   // return this.ticket.filter(function (each_ticket) {
    //   //   // console.log("each_ticket.name", each_ticket.name.toLowerCase());
    //   //   each_ticket.name.toLowerCase().match(this.search.toLowerCase());
    //   // });
    //   // }
    //   // return [];
    //   return this.ticket.length > 0
    //     ? this.ticket.filter((each_ticket) =>
    //         each_ticket.name.toLowerCase().match(this.search.toLowerCase())
    //       )
    //     : [];
    // },
    // ticketSubject() {
    //   if (this.ticket.length > 0) {
    //     return this.ticket.filter((each_ticket) =>
    //       each_ticket.subject.toLowerCase().match(this.search.toLowerCase())
    //     );
    //   } else {
    //     return this.ticket;
    //   }
    // },
    // ticketStatus() {
    //   if (this.ticket.length > 0) {
    //     return this.ticket.filter((each_ticket) =>
    //       each_ticket.status.toLowerCase().match(this.search.toLowerCase())
    //     );
    //   } else {
    //     return this.ticket;
    //   }
    // },
    // ticketSubject() {
    //   if (this.ticket.length > 0) {
    //     return this.ticket.filter((each_ticket) =>
    //       each_ticket.subject.toLowerCase().match(this.search.toLowerCase())
    //     );
    //   }
    //   return [];
    // },
    // ticketStatus() {
    //   if (this.ticket.length > 0) {
    //     return this.ticket.filter((each_ticket) =>
    //       each_ticket.status.toLowerCase().match(this.search.toLowerCase())
    //     );
    //   }
    //   return [];
    // },
    currentRole() {
      let givenRole = this.user.given_roles.filter(
        (o) => o.app_id == this.$store.getters.appId.decoded
      );
      return givenRole;
    },
    urlTemp() {
      let url = import.meta.env.VITE_APP_URL;
      if (window.location.hostname.split(".")[0] == "damcorp") {
        url = import.meta.env.VITE_DAMCORP_URL;
      } else if (window.location.hostname.split(".")[0] == "bawaslu") {
        url = import.meta.env.VITE_BAWASLU_URL;
      } else if (window.location.hostname.split(".")[0] == "pgd-omni") {
        url = import.meta.env.VITE_PEGADAIAN_URL;
      } else {
        url = import.meta.env.VITE_APP_URL;
      }
      return url;
    },
  },
  watch: {
    search() {
      console.log("TEST ", this.search);
      this.searchName();
    },
    ticket() {
      this.ticket;
      // this.getUserData();
    },
  },
  created() {
    let payloadNotif = {
      user_id: this.$store.getters.localStorage.decodedUserId,
      per_page: 10,
    };
    this.$store.dispatch("app/getUserApp", this.user.id);
    this.$store.dispatch("app/getNotif", payloadNotif);
    // this.$store.dispatch("app/list", "");
    // this.getData();
  },
  async mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);
    // this.isCustomDropdown();
    await this.getUserData();
    await this.getCurrentState();

    this.emitter.on("quickstart:update", () => {
      this.getUserData();
    });
  },
  methods: {
    async getUserData() {
      try {
        // console.log("get data");
        const userData = await this.$store.dispatch("agent/getProfilePicture");

        if (
          userData.subscription_remaining === 0 &&
          this.$route.name != "subscription.expired" &&
          this.$route.name != "auth.workspace"
        ) {
          return (window.location.href =
            this.urlTemp +
            "/" +
            this.$store.getters.appId.hashed +
            "/subscription-expired");
        }

        if (
          this.$route.name == "subscription.expired" &&
          (userData.subscription_remaining === undefined ||
            userData.subscription_remaining > 0)
        ) {
          return (window.location.href =
            this.urlTemp +
            "/" +
            this.$store.getters.appId.hashed +
            "/dashboard");
        }

        if (userData.quick_start || userData.subscription_plan) {
          this.$emit("setAlertTopbar", {
            quick_start: userData.quick_start,
            trial_alert: userData.subscription_remaining,
          });
        }
      } catch (error) {
        console.log("get data gagal");
      }
    },
    async getCurrentState() {
      this.$store.dispatch(
        "agent/getOnline",
        this.$store.getters.localStorage.decodedUserId
      );
    },
    async recordScreen() {
      let btn = document.querySelector("#btn-record");
      btn.addEventListener("click", async function () {
        let stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });

        const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
          ? "video/webm; codecs=vp9"
          : "video/webm";
        let mediaRecorder = new MediaRecorder(stream, {
          mimeType: mime,
        });

        let chunks = [];
        mediaRecorder.addEventListener("dataavailable", function (e) {
          chunks.push(e.data);
        });

        mediaRecorder.addEventListener("stop", function () {
          let blob = new Blob(chunks, {
            type: chunks[0].type,
          });
          let url = URL.createObjectURL(blob);

          // let video = document.querySelector("video")
          // video.src = url

          let a = document.createElement("a");
          a.href = url;
          a.download = "video.webm";
          a.click();

          console.log("download ", a);
        });

        mediaRecorder.start();
      });
    },
    async handleReadNotif(value) {
      try {
        await this.$store.dispatch("app/readNotifList", value);
      } catch (error) {
        console.log("error", error);
      }
    },
    changeDate(value) {
      return moment(value).startOf("day").fromNow();
    },
    async handleToScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      console.log("notiflist", this.notifList);
      if (this.per_page > this.notifList.total) {
        return;
      }
      this.per_page = this.per_page + 10;
      let payloadNotif = {
        user_id: this.$store.getters.localStorage.decodedUserId,
        per_page: this.per_page,
      };
      try {
        await this.$store.dispatch("app/getNotif", payloadNotif);
      } catch (error) {
        console.log("error", error);
      }
      // this.$emit("refetch", this.per_page);
    },
    async fetchNotif(per_page) {
      if (per_page > this.notifList.last_page) {
        return;
      }
      console.log("per page", per_page);
      let payloadNotif = {
        user_id: this.$store.getters.localStorage.decodedUserId,
        per_page: per_page,
      };
      try {
        await this.$store.dispatch("app/getNotif", payloadNotif);
      } catch (error) {
        console.log("error", error);
      }
    },
    searchName() {
      this.filterName(this.search, this);
    },
    filterName: debounce(async (search, vm) => {
      try {
        localStorage.setItem("filterText", vm.search);
        await vm.$store.dispatch("app/search", {
          agentId: vm.$store.getters.localStorage.decodedUserId,
          filterText: search,
        });
      } catch (err) {
        console.log("error search ", err);
      }
    }, 500),
    // isCustomDropdown() {
    //   //Search bar
    //   var searchOptions = document.getElementById("search-close-options");
    //   var dropdown = document.getElementById("search-dropdown");
    //   var searchInput = document.getElementById("search-options");

    //   searchInput.addEventListener("focus", () => {
    //     var inputLength = searchInput.value.length;
    //     if (inputLength > 0) {
    //       dropdown.classList.add("show");
    //       searchOptions.classList.remove("d-none");
    //     } else {
    //       dropdown.classList.remove("show");
    //       searchOptions.classList.add("d-none");
    //     }
    //   });

    //   searchInput.addEventListener("keyup", () => {
    //     var inputLength = searchInput.value.length;
    //     if (inputLength > 0) {
    //       dropdown.classList.add("show");
    //       searchOptions.classList.remove("d-none");
    //     } else {
    //       dropdown.classList.remove("show");
    //       searchOptions.classList.add("d-none");
    //     }
    //   });

    //   searchOptions.addEventListener("click", () => {
    //     searchInput.value = "";
    //     dropdown.classList.remove("show");
    //     searchOptions.classList.add("d-none");
    //   });

    //   document.body.addEventListener("click", (e) => {
    //     if (e.target.getAttribute("id") !== "search-options") {
    //       dropdown.classList.remove("show");
    //       searchOptions.classList.add("d-none");
    //     }
    //   });
    // },
    // async getData(value) {
    //   this.loadingTicket = true;
    //   try {
    //     const responseTicket = await httpClientOmni.post(`/ticket/get-all`, {
    //       agentId: this.$store.getters.localStorage.decodedUserId,
    //       filterText: value,
    //     });
    //     this.ticket = responseTicket.data;
    //     this.loadingTicket = false;
    //   } catch (err) {
    //     this.ticket = [];
    //     this.loadingTicket = false;
    //   }
    // },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
      }
    },
    async handleUserLogout() {
      this.$store.dispatch("loading/updateIsLoading", true);

      const { access_token } = StorageService.get("token");
      await AuthService.logout(access_token);

      this.$store.dispatch("loading/updateIsLoading", false);
      this.$router.push("/auth/login");
    },
    backToWorkspace() {
      window.location.href = `${this.urlTemp}/workspace`;
    },

    goToNotificationSound() {
      if (this.$route.params.appId) {
        this.$router.push({
          name: "settings.notification-sound",
          params: {
            appId: this.$route.params.appId,
          },
        });
      }
    },

    goToUrlBilling() {
      this.$router.push({
        name: "billing",
        params: {
          appId: this.$store.getters.appId.hashed,
        },
      });
    },
    goToProfile() {
      this.$router.push({
        name: "settings.general.profile-setting",
      });
    },
    changeState(val) {
      this.changedState = val;
      if (this.changedState != "online") {
        this.modal.change = true;
      } else {
        this.handleChangeStatus();
      }
    },
    async handleChangeStatus() {
      this.modal.loading = true;
      this.$store.dispatch("loading/updateIsLoading", true);

      await this.$store.dispatch("agent/updateOnline", {
        state: this.changedState,
        reason_offline: this.reason,
      });

      this.reason = null;
      this.modal.loading = false;
      this.modal.change = false;
      this.$store.dispatch("loading/updateIsLoading", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.app-label {
  fill: var(--background-1, #f5f7f9);
  stroke-width: 1px;
  stroke: #e0e0e0;
  min-width: 6rem;
  height: 38px;
  flex-shrink: 0;
  text-align: left;
  span {
    color: var(--grey-text, #878a96);
    font-family: "Poppins", sans-serif;
    font-size: 8pt;
    font-style: normal;
    font-weight: 450;
    letter-spacing: 0.1px;
  }

  i {
    color: var(--grey-text, #878a96);
  }
}

#page-header-user-dropdown-menu {
  .dropdown-item,
  .dropdown-header {
    padding: 0.35rem 0.5rem;
  }
}
.navbar-header {
  padding: 0 0 0 0.75rem !important;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.search-position {
  position: absolute;
  top: 3rem;
  left: 4rem;
  width: 35%;
}
</style>
