import { httpClientOmni } from "@/libraries";
const state = {
  mail_list: [],
  mail_list_ticket: [],
  mail_list_ticket_unread: [],
  integrations: [],
  primary_integration: [],
};

const getters = {
  getMailList(state) {
    return state.mail_list;
  },
  fetchMailList(state) {
    return state.mail_list;
  },
  getMailByTicketId(state) {
    return state.mail_list_ticket;
  },
  getMailTicketUnread(state) {
    return state.mail_list_ticket_unread;
  },
  getIntegrations(state) {
    return state.integrations;
  },
  getPrimaryIntegrations(state) {
    return state.primary_integration;
  },
};

const mutations = {
  GET_MAIL: (state, payload) => {
    state.mail_list = payload;
  },
  FETCH_MAIL: (state, payload) => {
    state.mail_list = payload;
  },
  GET_MAIL_TICKET: (state, payload) => {
    state.mail_list_ticket = payload;
  },
  GET_MAIL_TICKET_UNREAD: (state, payload) => {
    state.mail_list_ticket_unread = payload;
  },
  GET_EMAIL_INTEGRATION: (state, payload) => {
    state.integrations = payload;
  },
  GET_PRIMARY_INTEGRATION: (state, payload) => {
    state.primary_integration = payload;
  },
  INSERT_TO_MAIL_LIST: (state, payload) => {
    state.mail_list.push(payload);
  },
  ADD_MAIL_INQUIRES: (state, payload) => {
    // state.mail_list_ticket.push(payload);
    // state.mail_list.pust(payload);
    console.log("mails ", payload);
    if (payload.is_seen) {
      state.mail_list_ticket = [...state.mail_list_ticket, payload];
    } else {
      state.mail_list_ticket_unread = [
        ...state.mail_list_ticket_unread,
        payload,
      ];
    }
  },
};

const actions = {
  async getMailList(context) {
    let response = await httpClientOmni.get(`/v3/mail`);
    context.commit("GET_MAIL", response.data.data);
  },
  async insertToMailList(context, payload) {
    context.commit("INSERT_TO_MAIL_LIST", payload);
  },
  async setReadInquiries(context, ticketId) {
    await httpClientOmni.get(`/v3/mail/set-read/${ticketId}`);
    // context.commit("GET_MAIL", response.data.data);
  },
  async fetchMailList(context) {
    let response = await httpClientOmni.get(`/v3/mail/fetch`);
    context.commit("FETCH_MAIL", response.data.data);
  },
  async getMailByTicketId(context, ticketId) {
    let response = await httpClientOmni.post(`/v3/mail/ticket`, {
      ticket_id: ticketId,
    });
    context.commit("GET_MAIL_TICKET", response.data.data);
  },
  async getMailTicketUnread(context, ticketId) {
    let response = await httpClientOmni.post(`/v3/mail/ticket-unread`, {
      ticket_id: ticketId,
    });
    context.commit("GET_MAIL_TICKET_UNREAD", response.data.data);
  },
  async addMail(context, data) {
    context.commit("ADD_MAIL_INQUIRES", data);
  },
  async getIntegrations(context) {
    let response = await httpClientOmni.get(
      `email/get-integration?status=true`
    );
    context.commit("GET_EMAIL_INTEGRATION", response.data.data);
  },
  async getPrimaryIntegrations(context) {
    let response = await httpClientOmni.get(`integration/get-primary/13`);
    context.commit("GET_PRIMARY_INTEGRATION", response.data);
  },
  async setRead(context, payload) {
    await httpClientOmni.get(`v3/mail/read/${payload}`);
  },

  async setFavorite(context, payload) {
    await httpClientOmni.post(`v3/mail/favorite/${payload.id}`, {
      value: payload.value,
    });
  },

  async setTicketId(context, payload) {
    await httpClientOmni.post(`v3/mail/set-ticket`, {
      id: payload.mail_id,
      ticket_id: payload.ticket_id,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
