<template>
  <base-modal
    @onClose="onHideModal"
    modalTitle="Add Integration Twitter DM"
    :key="componentKey"
    :isOpen="modal.isOpen"
    @onSubmit="submitForm"
  >
    <div class="container py-3 px-4">
      <div class="row g-2">
        <div class="col-lg-6">
          <base-label text="Consumer API Key" />
          <base-input
            placeholder="Credentials act as the username for your Twitter App"
            v-model="form.apiKey"
          />
        </div>
        <div class="col-lg-6">
          <base-label text="Consumer API Secret" />
          <base-input
            placeholder="Credentials act as the password for your Twitter App"
            v-model="form.apiKeySecret"
          />
        </div>
        <div class="col-lg-12">
          <base-label text="Bearer Token" />
          <textarea
            class="form-control"
            placeholder="Credentials act as the password for your Twitter App"
            v-model="form.bearerToken"
          ></textarea>
        </div>
        <div class="col-lg-6">
          <base-label text="Access Token" />
          <base-input
            placeholder="Your Twitter access token"
            v-model="form.accessToken"
          />
        </div>
        <div class="col-lg-6">
          <base-label text="Secret Token" />
          <base-input
            placeholder="Your Twitter secret token"
            v-model="form.accessTokenSecret"
          />
        </div>
        <div class="col-lg-12">
          <base-label text="Environtment Name" />
          <base-input
            placeholder="Your Twitter App environtment name"
            v-model="form.environtment"
          />
        </div>
        <div class="col-lg-12" v-if="form.status">
          <base-label text="Webhook URL" />
          <div class="input-group">
            <base-input
              id="webhook-url"
              v-model="form.webhookUrl"
              placeholder="Webhook URL"
              readonly
            />
            <i
              class="ri-file-copy-line img-prepend pointer"
              @click="handleCopy"
            ></i>
            <div class="input-group-append">
              <base-button
                class="col"
                :loading="loadingSetWebhook"
                @click="setDebounceTwitter(this)"
              >
                Set Webhook
              </base-button>
            </div>
          </div>
          <small v-if="form.webhookId">
            {{ `Your Webhook id : ${form.webhookId}` }}
          </small>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { debounce, cloneDeep } from "lodash";
export default {
  props: {
    defaultData: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      default: "create",
    },
    componentKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      debounce,
      modal: {
        isOpen: false,
      },
      form: {
        apiKey: "",
        apiKeySecret: "",
        bearerToken: "",
        accessToken: "",
        accessTokenSecret: "",
        environtment: "",
        webhookId: "",
        status: true,
        webhookUrl: "-",
        channelName: "twitter",
      },
      integration: null,
      loading: false,
      loadingSetWebhook: false,
    };
  },
  computed: {
    errorIntegration() {
      return this.$store.getters["integration/error"];
    },
    webhookUrl() {
      return (
        import.meta.env.VITE_OMNICHANNEL_URL +
        "/api/" +
        this.$store.getters.appId.hashed +
        "/webhook/twitter/{integrationId}"
      );
      // return (
      //   "https://staging.lenna.ai/app/public" +
      //   "/api/" +
      //   this.$store.getters.appId.hashed +
      //   "/webhook/twitter/{integrationId}"
      // );
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
  methods: {
    onHideModal() {
      this.$emit("hide");
      this.modal.isOpen = false;
      this.resetForm();
    },
    onShowModal() {
      if (this.mode == "update") {
        this.integration = cloneDeep(this.defaultData.integration);
        this.form = cloneDeep(this.defaultData);
      }
      this.modal.isOpen = true;
      this.$emit("show");
    },
    resetForm() {
      this.resetObjectValue(this.form);
      this.integration = null;
      this.form.channelName = "twitter";
      this.form.status = false;
      this.loading = false;
      this.loadingSetWebhook = false;
    },
    setDebounceTwitter: debounce(async (vm) => {
      vm.setWebhookTwitter();
    }, 250),
    async setWebhookTwitter() {
      this.loadingSetWebhook = true;
      this.form.webhookUrl = this.webhookUrl.replace(
        "{integrationId}",
        this.integration.id
      );
      try {
        const response = await this.$store.dispatch(
          `integration/setV3TwitterWebhookUrl`,
          {
            webhookUrl: this.form.webhookUrl,
            id: this.integration.id,
            environtment: this.form.environtment,
          }
        );
        console.log("setWebhookTwitter", response);
        if (response.data.success) {
          this.successToast("Success integrate twitter dm");
        } else {
          this.errorToast(response.data.message);
        }
        this.loadingSetWebhook = false;
      } catch (error) {
        // this.resetForm();
        this.loadingSetWebhook = false;
        const err = error ? error.response.data.message : error;
        this.errorToast(err);
        // this.onHideModal();
      }
    },
    handleCopy() {
      let copyCode = document.querySelector("#webhook-url");
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },
    async updateIntegration() {
      const valid = this.validateForm();
      if (!valid.success) {
        this.errorToast(valid.message);
        return;
      }
      this.loading = true;
      try {
        this.loading = true;
        const { data: response } = await this.$store.dispatch(
          "integration/updateIntegration",
          {
            ...this.form,
          }
        );
        if (response.success) {
          this.successToast(response.message);
          console.log("form status", this.form.status);
          if (this.form.status == true) {
            await this.setWebhookTwitter();
          }
        }
        this.resetForm();
        this.onHideModal();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorToast("Failed to update integration twitter dm");
        this.resetForm();
        this.onHideModal();
      }
      this.loading = false;
    },
    async createIntegration() {
      const valid = this.validateForm();
      if (!valid.success) {
        this.errorToast(valid.message);
        return;
      }
      this.loading = true;
      try {
        this.loading = true;
        const { data: response } = await this.$store.dispatch(
          "integration/saveIntegration",
          {
            ...this.form,
          }
        );
        if (response.success) {
          this.successToast(response.message);
          this.integration = response.data.integration;
          if (this.form.status) {
            await this.setWebhookTwitter();
          }
        }
        this.resetForm();
        this.onHideModal();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorToast("Failed to create integration twitter dm");
        this.resetForm();
        this.onHideModal();
      }
      this.loading = false;
    },
    async submitForm() {
      if (this.mode == "create") {
        this.form.status = true;
        this.createIntegration();
      } else {
        this.updateIntegration();
      }
    },
    validateForm() {
      if (this.form.apiKey === "") {
        return {
          success: false,
          message: "Consumer API Key must be filled",
        };
      }
      if (this.form.apiKeySecret === "") {
        return {
          success: false,
          message: "Consumer API Secret must be filled",
        };
      }
      if (this.form.bearerToken === "") {
        return {
          success: false,
          message: "Bearer Token must be filled",
        };
      }
      if (this.form.accessToken === "") {
        return {
          success: false,
          message: "Access Token be filled",
        };
      }
      if (this.form.accessTokenSecret === "") {
        return {
          success: false,
          message: "Secret Token be filled",
        };
      }
      return {
        success: true,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.img-prepend {
  position: relative;
  float: right;
  right: 25px;
  top: 7px;
  width: 20px;
  height: 20px;
}
</style>
