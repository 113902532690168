const audios = [
  {
    title: "Default",
    slug: "default",
    path: "/audio/default.mp3",
    duration: 0,
  },
  {
    title: "Stairs",
    slug: "stairs",
    path: "/audio/stairs.mp3",
    duration: 0,
  },
  {
    title: "Chat Coming",
    slug: "chat-coming",
    path: "/audio/chat-coming.mp3",
    duration: 0,
  },
  {
    title: "Interface",
    slug: "interface",
    path: "/audio/interface.mp3",
    duration: 1,
  },
  {
    title: "Bell",
    slug: "bell",
    path: "/audio/bell.mp3",
    duration: 1,
  },
  {
    title: "Livechat",
    slug: "livechat",
    path: "/audio/livechat.mp3",
    duration: 1,
  },
  {
    title: "Message Incoming",
    slug: "message-incoming",
    path: "/audio/message-incoming.mp3",
    duration: 1,
  },
  {
    title: "Tap",
    slug: "tap",
    path: "/audio/tap.mp3",
    duration: 0,
  },
  {
    title: "Start",
    slug: "start",
    path: "/audio/start.mp3",
    duration: 0,
  },
  {
    title: "Game Scenes",
    slug: "game-scenes",
    path: "/audio/game-scenes.mp3",
    duration: 2,
  },
];

export default audios;
