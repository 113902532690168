<template>
  <button
    :class="['chat__item', isGroup && 'group', !isRead && 'unread-message']"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <div class="position-relative">
      <ImgLazy
        v-if="image"
        :src="image"
        :alt="name"
        class="chat__item-img"
        errorImg="/img/user-dummy-img.jpg"
      />

      <div v-else :class="`chat__item-img-title ${getRandomColor()}`">
        {{ getSortName(name) }}
      </div>

      <span
        v-if="!isGroup"
        :class="`dot ${status ? 'bg-success' : 'bg-danger'}`"
      ></span>
    </div>

    <div class="flex-1" style="padding-left: 10px">
      <span class="chat__item-name">{{ name }}</span>
      <span
        :class="`chat__item-last-message ${!lastMessage ? 'fst-italic' : ''}`"
      >
        {{ lastMessage || "no conversation yet" }}
      </span>
    </div>

    <div class="detail">
      <small class="d-block" style="color: #4b5563">
        {{ getLastMessageAt }}
      </small>

      <small v-if="countUnread > 0" class="badge-count-unread">
        {{ countUnread }}
      </small>
    </div>
  </button>
</template>

<script>
import { defineComponent, computed } from "vue";

import ImgLazy from "./base/ImgLazy";

export default defineComponent({
  components: {
    ImgLazy,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    lastMessage: String,
    lastMessageAt: String,
    isRead: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    countUnread: {
      type: Number,
      default: 0,
    },
    color: {
      type: Number,
      default: 0,
    },
  },
  emits: ["hold"],
  setup(props, ctx) {
    let timeoutHold;

    const getLastMessageAt = computed(() => {
      if (!props.lastMessageAt) return "";

      const date = new Date(props.lastMessageAt);

      const now = new Date();
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      const twoDaysAgo = new Date(now);
      twoDaysAgo.setDate(now.getDate() - 2);

      if (date.toDateString() === now.toDateString()) {
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
      } else if (date.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else if (date >= twoDaysAgo) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        return `${day}-${month}`;
      } else {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }
    });

    const handleMouseDown = () => {
      timeoutHold = setTimeout(() => {
        ctx.emit("hold");
      }, 1000);
    };

    const handleMouseUp = () => {
      clearTimeout(timeoutHold);
    };

    const colors = ["success", "warning", "danger", "info"];

    const getRandomColor = () => {
      return `bg-soft-${colors[props.color]} text-${colors[props.color]}`;
    };

    const getSortName = (name = "") => {
      const split = name.split(" ");
      if (split.length > 1) {
        return `${split[0][0]}${split[1][0]}`;
      }
      return name.substr(0, 2);
    };

    return {
      getLastMessageAt,

      handleMouseDown,
      handleMouseUp,
      getRandomColor,
      getSortName,
    };
  },
});
</script>

<style scoped>
.dot {
  position: absolute;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  bottom: 2px;
  right: 2px;
}
</style>
