const genRoute = [
    {
        path: '/:appId/gen-studio/:assistantId',
        name: 'gen-studio-detail',
        component: () => import('@/views/gen-studio/pages/Assistant.vue'),
        meta: { title: 'Gen AI Studio' }
    },
    {
        path: '/:appId/gen-studio/assistant',
        name: 'gen-studio.gen-studio',
        component: () => import('@/views/gen-studio/pages/ListAssistant.vue'),
        meta: { title: 'Assistant' }
    },
    {
        path: '/:appId/gen-studio/assistant',
        name: 'gen-studio.gen-studio.assistant',
        component: () => import('@/views/gen-studio/pages/ListAssistant.vue'),
        meta: { title: 'Assistant' }
    },
    {
        path: '/:appId/gen-studio/file-manager',
        name: 'gen-studio.gen-studio.file-manager',
        component: () => import('@/views/gen-studio/pages/FileManager.vue'),
        meta: { title: 'Assistant' }
    }
];
export default genRoute;
