<template>
  <div :class="['chat__detail', show && 'active', isGroup && 'group']">
    <div class="chat__detail-header">
      <div class="mt-1">
        <button type="button" class="btn-action" @click="$emit('back')">
          <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.43819 9.00093L11.0379 15.6005L9.15233 17.4861L0.666992 9.00093L9.15233 0.515625L11.0379 2.40124L4.43819 9.00093Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <div v-if="isGroup" class="chat__contact-header__profile">
        <span class="chat__contact-header__profile-name">{{ name }}</span>

        <div
          v-if="groupSelected && groupSelected.agent_group"
          class="avatar-group"
        >
          <a
            v-for="item in groupSelected.agent_group.filter((r, i) => i < 3)"
            :key="item.id"
            href="javascript:void(0)"
            class="avatar-group-item"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            :title="item.user_platform.name"
          >
            <!-- <img
              v-if="item.user_platform.picture"
              :src="item.user_platform.picture"
              :alt="item.user_platform.name"
              class="rounded-circle avatar-xxs"
            /> -->
            <ImgLazy
              v-if="item.user_platform.picture"
              :src="item.user_platform.picture"
              :alt="item.user_platform.name"
              errorImg="/img/user-dummy-img.jpg"
              style="width: 25px; height: 25px; border-radius: 50%"
            />
            <div
              v-else
              :class="`rounded-circle avatar-xxs d-inline-flex justify-content-center align-items-center text-uppercase ${getRandomColor(
                false,
                dataUser?.color || 0
              )}`"
            >
              <div>
                {{ getSortName(item.user_platform.name) }}
              </div>
            </div>
          </a>

          <a
            v-if="groupSelected.agent_group.length > 3"
            href="#addmemberModal"
            data-bs-toggle="modal"
            class="avatar-group-item"
          >
            <div class="avatar-xxs">
              <div
                style="
                  font-size: 0.8em;
                  color: black;
                  font-weight: 700;
                  background-color: #e6eef9;
                "
                class="avatar-title rounded-circle"
              >
                +{{ groupSelected.agent_group.length - 3 }}
              </div>
            </div>
          </a>
        </div>
      </div>

      <div v-else class="chat__detail-header__profile">
        <div class="img-profile">
          <ImgLazy
            v-if="image"
            :src="image"
            :alt="name"
            errorImg="/img/user-dummy-img.jpg"
          />
          <div
            v-else
            :class="[
              'd-flex h-100 w-100 justify-content-center align-items-center text-uppercase',
              getRandomColor(true, dataUser?.color || 0),
            ]"
            style="font-size: 1.4rem"
          >
            {{ getSortName(name) }}
          </div>
        </div>

        <div>
          <span class="chat__detail-header__profile-name">
            {{ dataUser?.user.name || "" }}
          </span>
          <span>{{ isOnline ? "Online" : "Offline" }}</span>
        </div>
      </div>

      <div class="mt-1">
        <button type="button" class="btn-action" @click="$emit('minimize')">
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0V2H14V0H0Z" fill="white" />
          </svg>
        </button>
      </div>
    </div>

    <div id="chatBody" class="chat__detail-body">
      <template v-for="r in chatList" :key="r">
        <div v-if="r.fromId === user" class="chat__detail-body-sender">
          <div class="message">
            <span
              v-for="(messageItem, messageKey) in r.messages"
              :key="`${r._id}-${messageKey}`"
              v-html="formatMessage(messageItem)"
            >
            </span>
            {{ r.createdAt }}
          </div>
        </div>

        <div v-else class="chat__detail-body-receiver">
          <template v-if="isGroup">
            <img v-if="image" :src="image" :alt="name" />
            <img
              v-else
              src="../assets/images/users/user-dummy-img.jpg"
              :alt="name"
            />
          </template>

          <div class="message">
            <span
              v-for="(messageItem, messageKey) in r.messages"
              :key="`${r._id}-${messageKey}`"
              v-html="formatMessage(messageItem)"
            >
            </span>
            {{ r.createdAt }}
          </div>
        </div>
      </template>

      <div id="chatBodyBottom"></div>
    </div>

    <div class="chat__detail-form" style="overflow: hidden">
      <input
        v-model="message"
        type="text"
        class="input"
        :placeholder="placeholder"
        @keydown="send"
      />

      <div
        class="position-absolute"
        :style="`
          bottom: 50px;
          right: 30px;
          transition: all 300ms ease-in-out;
          ${
            !showEmoji
              ? 'transform: translateY(50px); opacity: 0; pointer-events: none'
              : ''
          }
        `"
      >
        <EmojiPicker :native="true" @select="selectedIcon" />
      </div>

      <button type="button" class="btn-action" @click="showEmoji = !showEmoji">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM6 11H14C14 13.2091 12.2091 15 10 15C7.79086 15 6 13.2091 6 11ZM6 9C5.17157 9 4.5 8.3284 4.5 7.5C4.5 6.67157 5.17157 6 6 6C6.82843 6 7.5 6.67157 7.5 7.5C7.5 8.3284 6.82843 9 6 9ZM14 9C13.1716 9 12.5 8.3284 12.5 7.5C12.5 6.67157 13.1716 6 14 6C14.8284 6 15.5 6.67157 15.5 7.5C15.5 8.3284 14.8284 9 14 9Z"
            fill="#3F5189"
          />
        </svg>
      </button>

      <!-- <button type="button" class="btn-action">
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8287 5.75789L7.1718 11.4148C6.78127 11.8053 6.78127 12.4384 7.1718 12.829C7.56232 13.2195 8.1955 13.2195 8.586 12.829L14.2429 7.1721C15.4144 6.00053 15.4144 4.10104 14.2429 2.92946C13.0713 1.75789 11.1718 1.75789 10.0002 2.92946L4.34337 8.58629C2.39075 10.5389 2.39075 13.7048 4.34337 15.6574C6.29599 17.61 9.4618 17.61 11.4144 15.6574L17.0713 10.0005L18.4855 11.4148L12.8287 17.0716C10.095 19.8053 5.66283 19.8053 2.92916 17.0716C0.195489 14.3379 0.195489 9.90579 2.92916 7.1721L8.586 1.51525C10.5386 -0.437374 13.7045 -0.437374 15.6571 1.51525C17.6097 3.46787 17.6097 6.6337 15.6571 8.58629L10.0002 14.2432C8.8287 15.4148 6.92916 15.4148 5.75759 14.2432C4.58601 13.0716 4.58601 11.1721 5.75759 10.0005L11.4144 4.34368L12.8287 5.75789Z"
            fill="#3F5189"
          />
        </svg>
      </button> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch, computed, ref } from "vue";
import { useStore } from "vuex";

import EmojiPicker from "vue3-emoji-picker";
import ImgLazy from "./base/ImgLazy.vue";

export default defineComponent({
  components: {
    EmojiPicker,
    ImgLazy,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    toId: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: null,
    },
    isGroup: {
      type: Boolean,
      required: true,
    },
    chats: {
      type: Array,
      required: true,
    },
    statusLogin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["back", "minimize", "send"],
  setup(props, ctx) {
    const store = useStore();

    const placeholder = computed(
      () => `Message to "${props.name}" ${props.isGroup ? "Group" : ""}`
    );

    const body = ref();

    const message = ref("");
    const showEmoji = ref(false);

    const user = computed(() => store.state["localStorage"].decodedUserId);
    const groups = computed(() => store.getters["internalChat/getGroups"]);
    const groupSelected = computed(() => {
      if (!props.toId) return null;

      return groups.value.find((r) => r.id === props.toId);
    });

    const dataUser = computed(() => {
      const data = store.getters["internalChat/getContactList"].find(
        (r) => r.user_id.toString() === props.toId.toString()
      );

      return data;
    });

    const isOnline = computed(() => {
      const check = store.getters["internalChat/getOnlineMembers"].find(
        (r) => r.userId === props.toId
      );

      return check?.status || false;
    });

    const chatList = computed(() => {
      const data = [];
      props.chats.forEach((r) => {
        if (data.length) {
          if (
            data[data.length - 1] &&
            data[data.length - 1].fromId === r.fromId
          ) {
            data[data.length - 1].messages.push(r.message);
            data[data.length - 1].createdAt = formatTime(r.createdAt);
          } else {
            data.push({
              ...r,
              messages: [r.message],
              createdAt: formatTime(r.createdAt),
            });
          }
        } else {
          data.push({
            ...r,
            messages: [r.message],
            createdAt: formatTime(r.createdAt),
          });
        }
      });

      return data;
    });

    const selectedIcon = (icon) => {
      message.value = message.value + icon.i;
    };

    const formatTime = (str) => {
      const now = new Date();
      const date = new Date(str);

      const prefix = (num) => (num > 9 ? num : `0${num}`);

      if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate()
      ) {
        return `${prefix(date.getHours())}:${prefix(date.getMinutes())}`;
      }

      return `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()} ${prefix(date.getHours())}:${prefix(
        date.getMinutes()
      )}`;
    };

    const send = (e) => {
      if (e.keyCode === 13) {
        ctx.emit("send", message.value);
        showEmoji.value = false;

        message.value = "";
      }
    };

    const scrollDown = () => {
      const body = document.getElementById("chatBody");
      const bodyBottom = document.getElementById("chatBodyBottom");

      if (body) {
        body.scrollTo({
          top: bodyBottom.offsetTop,
          behavior: "smooth",
        });
      }
    };

    const colors = ["success", "warning", "danger", "info"];

    const getRandomColor = (soft = false, index = null) => {
      let randomIndex =
        index === null ? Math.floor(Math.random() * colors.length) : index;

      return `bg-${soft ? "soft-" : ""}${colors[randomIndex]} ${
        soft ? `text-${colors[randomIndex]}` : "text-white"
      }`;
    };

    const getSortName = (name = "") => {
      const split = name.split(" ");
      if (split.length > 1) {
        return `${split[0][0]}${split[1][0]}`;
      }
      return name.substr(0, 2);
    };

    const formatMessage = (str = "") => {
      return str
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(
          /(\b(?:https?|ftp):\/\/\S+)/gi,
          '<a href="$1" target="_blank">$1</a>'
        );
    };

    watch(
      () => props.toId,
      () => {
        scrollDown();
      }
    );

    watch(
      () => props.chats,
      () => {
        setTimeout(() => {
          scrollDown();
        }, 200);
      }
    );

    onMounted(() => {
      scrollDown();
    });

    return {
      placeholder,
      body,
      message,
      showEmoji,
      user,
      dataUser,
      isOnline,
      chatList,
      groups,
      groupSelected,
      selectedIcon,

      send,
      scrollDown,
      getRandomColor,
      getSortName,
      formatMessage,
    };
  },
});
</script>
