const ticketRoute = [
  // {
  //   path: "/:appId/tickets/dashboard",
  //   name: "ticket-dashboard.ticket-dashboard-main",
  //   component: () => import("@/views/ticketing/pages/Dashboard/DashboardReportPage.vue"),
  //   meta: { title: "Ticket Dashboard" },
  // },
  {
    path: "/:appId/ticketing",
    name: "ticketing",
    component: () =>
      import("@/views/ticketing/pages/Dashboard/DashboardTicket.vue"),
    meta: { title: "Ticket Dashboard" },
    redirect: {
      name: "ticketing.dashboard.ticket-dashboard",
    },
  },
  {
    path: "/:appId/ticketing/dashboard/main",
    name: "ticketing.dashboard",
    component: () =>
      import("@/views/ticketing/pages/Dashboard/DashboardTicket.vue"),
    meta: { title: "Ticket Dashboard" },
    redirect: {
      name: "ticketing.dashboard.ticket-dashboard",
    },
  },
  {
    path: "/:appId/ticketing/dashboard/main",
    name: "ticketing.dashboard.ticket-dashboard",
    component: () =>
      import("@/views/ticketing/pages/Dashboard/DashboardTicket.vue"),
    meta: { title: "Ticket Dashboard" },
  },
  {
    path: "/:appId/ticketing/manage/list",
    name: "ticketing.tickets.tickets-list",
    component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
    redirect: {
      name: "ticketing.tickets.tickets-list",
    },
  },
  {
    path: "/:appId/ticketing/manage",
    name: "ticketing.tickets",
    component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
    redirect: {
      name: "ticketing.tickets.tickets-list",
    },
  },
  {
    path: "/:appId/ticketing/manage/list",
    name: "ticketing.tickets.tickets-list",
    component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
    meta: {
      title: "Tickets",
    },
  },
  {
    path: "/:appId/ticketing/manage/create",
    name: "ticketing.tickets.create-ticket",
    component: () => import("@/views/ticketing/pages/CreateTicketPage.vue"),
    meta: { title: "Create Ticket" },
  },
  // {
  //   path: "/:appId/ticketing/manage/create-new",
  //   name: "ticketing.tickets.create-ticket-new",
  //   component: () => import("@/views/ticketing/pages/CreateTicketPageNew.vue"),
  //   meta: { title: "Create Ticket" },
  // },

  {
    path: "/:appId/ticketing/view/:ticketId",
    name: "ticketing.tickets.tickets-view",
    component: () => import("@/views/ticketing/pages/ViewTicketPage.vue"),
    meta: { title: "View Ticket" },
  },
  {
    path: "/:appId/ticketing/edit/:ticketId",
    name: "ticketing.manage.edit",
    component: () => import("@/views/ticketing/pages/EditTicketPage.vue"),
    meta: { title: "Edit Ticket" },
  },
  {
    path: "/:appId/ticketing/customer",
    name: "ticketing.customers",
    component: () => import("@/views/customer/pages/CustomerList.vue"),
    meta: { title: "Customer List" },
    redirect: {
      name: "ticketing.customers.customers-list",
    },
    children: [
      {
        path: "/:appId/ticketing/customers",
        name: "ticketing.customers.customers-list",
        component: () => import("@/views/customer/pages/CustomerList.vue"),
        meta: { title: "Customer List" },
      },
      {
        path: "/:appId/ticketing/customers/create",
        name: "ticketing.customers.customers-create",
        component: () => import("@/views/customer/pages/CreateCustomer.vue"),
        meta: { title: "Create Customer Ticket" },
      },

      // {
      //   path: "/:appId/ticketing/customers/create",
      //   name: "ticketing.customers.customers-create",
      //   component: () => import("@/views/customer/pages/CreateCustomer.vue"),
      //   meta: { title: "Create Customer Ticket" },
      // },
    ],
  },
  // {
  //   path: "/:appId/ticketing/customers",
  //   name: "ticketing.customers.customers-list",
  //   component: () => import("@/views/customer/pages/CustomerList.vue"),
  //   meta: { title: "Customer List" },
  // },
  // {
  //   path: "/:appId/ticketing/customers/create",
  //   name: "ticketing.customers.customers-create",
  //   component: () => import("@/views/customer/pages/CreateCustomer.vue"),
  //   meta: { title: "Create Customer Ticket" },
  // },
  {
    path: "/:appId/ticketing/customers/edit",
    name: "ticketing.customers.customers-edit",
    component: () => import("@/views/common/components/NotFound.vue"),
    meta: { title: "Create Customer Ticket" },
  },
  {
    path: "/:appId/ticketing/reporting",
    name: "reporting",
    component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
    redirect: {
      name: "reporting",
    },
    children: [
      {
        path: "/:appId/ticketing/reporting/agent-performance",
        name: "reporting.agent-performance",
        component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
      },
      {
        path: "/:appId/ticketing/reporting/group-performance",
        name: "reporting.group-performance",
        component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
      },
      {
        path: "/:appId/ticketing/reporting/ticket-sheet-summary",
        name: "reporting.ticket-sheet-summary",
        component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
      },
      {
        path: "/:appId/ticketing/reporting/ticket-lifecycle",
        name: "reporting.ticket-lifecycle",
        component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
      },
    ],
  },
  {
    path: "/:appId/ticketing/setting",
    name: "ticketing.ticket.setting",
    component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
    redirect: {
      name: "ticket.setting.general-setting",
    },
    children: [
      {
        path: "/:appId/ticketing/setting/general-setting",
        name: "ticket.setting.general-setting",
        component: () => import("@/views/ticketing/pages/TicketMainPage.vue"),
      },
    ],
  },
  {
    path: "/:appId/settings/type-ticket",
    name: "ticket.setting.type",
    component: () => import("@/views/ticketing/pages/MasterData/TypePage.vue"),
    meta: { title: "Type Ticket" },
  },
  {
    path: "/:appId/settings/category-ticket",
    name: "ticket.setting.category",
    component: () =>
      import(
        "@/views/ticketing/pages/MasterData/category/CategoryMainPage.vue"
      ),
    meta: { title: "Category Ticket" },
  },
  {
    path: "/:appId/settings/subject-ticket",
    name: "ticket.setting.subject",
    component: () =>
      import("@/views/ticketing/pages/MasterData/SubjectPage.vue"),
    meta: { title: "Subject Ticket" },
  },
  {
    path: "/:appId/settings/status-ticket",
    name: "ticket.setting.status",
    component: () =>
      import("@/views/ticketing/pages/MasterData/StatusPage.vue"),
    meta: { title: "Status Ticket" },
  },
  {
    path: "/:appId/settings/priority-ticket",
    name: "ticket.setting.priority",
    component: () =>
      import("@/views/ticketing/pages/MasterData/PriorityPage.vue"),
    meta: { title: "Priority Ticket" },
  },
  {
    path: "/:appId/settings/branch-office-ticket",
    name: "ticket.setting.branch-office",
    component: () =>
      import("@/views/ticketing/pages/MasterData/BranchOfficePage.vue"),
    meta: { title: "Business Unit" },
  },
  {
    path: "/:appId/settings/content-restricted-ticket",
    name: "ticket.setting.content-restricted",
    component: () =>
      import("@/views/ticketing/pages/MasterData/ContentRestrictedPage.vue"),
    meta: { title: "Content Restricted Ticket" },
  },
  {
    path: "/:appId/settings/tags",
    name: "ticket.setting.tags",
    component: () => import("@/views/ticketing/pages/MasterData/TagPage.vue"),
    meta: { title: "Tags" },
  },
  {
    path: "/:appId/settings/auto-response-office-hour",
    name: "settings.auto-response-office-hour",
    component: () =>
      import("@/views/settings/pages/AutoResponseOfficeHour.vue"),
    meta: { title: "Auto Response & Office Hour" },
  },
  {
    path: "/:appId/settings/auto-reminder-office-hour",
    name: "settings.auto-reminder-office-hour",
    component: () =>
      import(
        "@/views/ticketing/pages/MasterData/AutoReminderOfficeHourPage.vue"
      ),
    meta: { title: "Auto Reminder & Office Hour" },
  },
];
export default ticketRoute;
