import { TagService } from "@/services";

const Types = {
  FETCH_TAGS_SUCCESS: "FETCH_TAGS_SUCCESS",
  FETCH_TAGS_MAPPING_SUCCESS: "FETCH_TAGS_MAPPING_SUCCESS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  CREATE_TAG: "CREATE_TAG", // trigger in chat page
  NEW_TAG: "NEW_TAG", // trigger when user type in chat information but the tag does not exist,
};

const state = {
  data: [],
  tags: [],
  error: null,
  loading: false,
};

const getters = {
  get(state) {
    return state.data;
  },
  getTags(state) {
    return state.tags;
  },
};

const mutations = {
  [Types.FETCH_TAGS_SUCCESS]: (state, payload) => (state.data = payload),
  [Types.FETCH_TAGS_MAPPING_SUCCESS]: (state, payload) =>
    (state.tags = payload),
  [Types.CREATE_TAG]: (state, payload) =>
    (state.data = [...state.data, payload]),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.NEW_TAG]: (state, payload) => (state.data = [...state.data, payload]),
};

const actions = {
  async get({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TagService.find();
      commit(Types.FETCH_TAGS_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },

  async getAll({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await TagService.findAll();
      commit(Types.FETCH_TAGS_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },

  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TagService.store(payload);
      commit(Types.CREATE_TAG, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  new({ commit }, payload) {
    commit(Types.NEW_TAG, payload);
  },

  async getTags({ commit }, payload = null) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TagService.find(payload);
      let filters = response.data.map(function (item) {
        return {
          value: item.name,
          label: item.name,
        };
      });

      commit(Types.FETCH_TAGS_MAPPING_SUCCESS, filters);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },

  async searchTags({ commit }, payload) {
    // commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TagService.search(payload);
      let filters = response.data.map(function (item) {
        // return {
        //   value: item.id,
        //   label: item.name,
        // };
        return item.name;
      });

      commit(Types.FETCH_TAGS_MAPPING_SUCCESS, filters);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    // commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
