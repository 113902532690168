import { FunctionListService } from "@/services";
import axios from "axios";

const Types = {
  SET_SERVICE_FUNCTIONS: "SET_SERVICE_FUNCTIONS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  SET_TRASH_BIN_LIST: "SET_TRASH_BIN_LIST",
};

const state = {
  serviceFunctions: [],
  trashBinList: [],
  error: null,
  loading: false,
};

const getters = {
  serviceFunctions(state) {
    return state.serviceFunctions;
  },
  trashBinList(state) {
    return state.trashBinList;
  },
};

const mutations = {
  [Types.SET_SERVICE_FUNCTIONS]: (state, payload) =>
    (state.serviceFunctions = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
  [Types.SET_TRASH_BIN_LIST]: (state, payload) =>
    (state.trashBinList = payload),
};

const actions = {
  async getServiceFunction({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await axios.get(
        `${import.meta.env.VITE_BOTSTUDIO_URL}/api/service-functions`
      );
      commit(Types.SET_SERVICE_FUNCTIONS, response);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await FunctionListService.createFunctionList(payload);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async update({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await FunctionListService.updateFunctionList(payload);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async delete({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await FunctionListService.deleteFunctionList(payload);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async massDelete({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await FunctionListService.massDeleteFunctionList(payload);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getTrashBin({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await FunctionListService.getTrashBinList();
      commit(Types.SET_TRASH_BIN_LIST, response);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async restoreTrashBin({ commit }, id) {
    commit(Types.SET_LOADING, true);
    try {
      await FunctionListService.restoreTrashBin(id);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
