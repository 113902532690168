import { AgentService, GroupService } from "@/services";
import { httpPlain } from "@/libraries";

const Types = {
  FETCH_AGENT_SUCESS: "FETCH_AGENT_SUCCESS",
  FETCH_AGENT_DATA_SUCCESS: "FETCH_AGENT_DATA_SUCCESS",
  FETCH_AGENT_ERROR: "FETCH_AGENT_ERROR",
  SET_LOADING: "SET_LOADING",
  SET_ONLINE: "SET_ONLINE",
  APPROVAL_ASSIGN_AGENT: "APPROVAL_ASSIGN_AGENT",
  WAITING_APPROVAL_AGENT: "WAITING_APPROVAL_AGENT",
  REJECT_ASSIGN: "REJECT_ASSIGN",
  REMOVE_FROM_APPROVAL_AGENT: "REMOVE_FROM_APPROVAL_AGENT",
  REMOVE_APPROVAL_AND_WAITING_ASSIGNMENT:
    "REMOVE_APPROVAL_AND_WAITING_ASSIGNMENT",
  UPDATE_AVAILABLILITY: "UPDATE_AVAILABLILITY",
  ASSIGNED_CHANNEL: "ASSIGNED_CHANNEL",
  SET_GROUPS: "SET_GROUPS",
  ASSIGNED_GROUP: "ASSIGNED_GROUP",
  PROFILE_PICTURE: "PROFILE_PICTURE",
  SOCKET_STATUS: "SOCKET_STATUS",
  NEED_LOADED_AGENT: "NEED_LOADED_AGENT",
  REMOVE_LIST_AGENT: "REMOVE_LIST_AGENT",
};

const state = {
  data: [],
  online: false,
  dataAgentGroup: [],
  error: null,
  loading: false,
  approvalAssignAgent: [],
  waitingApprovalAgent: [],
  assignedChannel: [],
  groups: [],
  assignedGroup: [],
  state: null,
  profilePicture: null,
  socketStatus: "Connected",
  needLoadAgent: false,
};

const getters = {
  get: (state) => state.data,
  getAgentGroup: (state) => state.dataAgentGroup,
  online: (state) => state.online,
  loading: (state) => state.loading,
  approvalAssignAgent: (state) => state.approvalAssignAgent,
  waitingApprovalAgent: (state) => state.waitingApprovalAgent,
  assignedChannel: (state) => state.assignedChannel,
  groups: (state) => state.groups,
  assignedGroup: (state) => state.assignedGroup,
  getState: (state) => state.state,
  profilePicture: (state) => state.profilePicture,
  socketStatus: (state) => state.socketStatus,
  needLoadAgent: (state) => state.needLoadAgent,
};

const mutations = {
  [Types.SET_ONLINE]: (state, payload) => {
    state.online = payload;
    state.state = payload;
  },
  [Types.FETCH_AGENT_SUCESS]: (state, payload) => (state.data = payload),
  [Types.FETCH_AGENT_DATA_SUCCESS]: (state, payload) =>
    (state.dataAgentGroup = payload),
  [Types.FETCH_AGENT_ERROR]: (state, payload) => (state.error = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.APPROVAL_ASSIGN_AGENT]: (state, payload) => {
    if (state.approvalAssignAgent.length > 0) {
      AgentService.rejectAssign({
        ...payload,
        message: "Your conversation assignment was rejected",
      });
    } else {
      const isExist = state.approvalAssignAgent.some(
        (item) => item.room.id == payload.room.id
      );
      if (!isExist) {
        state.approvalAssignAgent = [...state.approvalAssignAgent, payload];
      }
    }
  },
  [Types.WAITING_APPROVAL_AGENT]: (state, payload) => {
    const isExist = state.waitingApprovalAgent.some(
      (item) => item.room.id == payload.room.id
    );
    if (!isExist) {
      state.waitingApprovalAgent = [...state.waitingApprovalAgent, payload];
    }
  },
  [Types.REJECT_ASSIGN]: (state, payload) => {
    const isExist = state.waitingApprovalAgent.some(
      (item) => item.room.id == payload.room.id
    );
    if (isExist) {
      state.waitingApprovalAgent = state.waitingApprovalAgent.filter(
        (item) => item.room.id !== payload.room.id
      );
    }
  },
  [Types.REMOVE_FROM_APPROVAL_AGENT]: (state, payload) => {
    const isExist = state.approvalAssignAgent.some(
      (item) => item.room.id == payload.room.id
    );
    if (isExist) {
      state.approvalAssignAgent = state.approvalAssignAgent.filter(
        (item) => item.room.id !== payload.room.id
      );
    }
  },
  [Types.REMOVE_APPROVAL_AND_WAITING_ASSIGNMENT]: (state, payload) => {
    state.approvalAssignAgent = state.approvalAssignAgent.filter(
      (each_item) => each_item.room.id !== payload.room.id
    );
    state.waitingApprovalAgent = state.waitingApprovalAgent.filter(
      (each_item) => each_item.room.id !== payload.room.id
    );
  },
  [Types.UPDATE_AVAILABLILITY]: (state, { current_status, user_id }) => {
    let index = state.data.findIndex(
      (each_data) => each_data.user_id == user_id
    );
    // console.log("index", index);
    // console.log("current_status", current_status);
    // state.data[index].online = current_status;
  },
  [Types.ASSIGNED_CHANNEL]: (state, payload) => {
    state.assignedChannel = payload;
  },
  [Types.SET_GROUPS]: (state, payload) => (state.groups = payload),
  [Types.ASSIGNED_GROUP]: (state, payload) => (state.assignedGroup = payload),
  [Types.PROFILE_PICTURE]: (state, payload) => (state.profilePicture = payload),
  [Types.SOCKET_STATUS]: (state, payload) => (state.socketStatus = payload),
  [Types.NEED_LOADED_AGENT]: (state, payload) => {
    state.needLoadAgent = payload;
  },
  [Types.REMOVE_LIST_AGENT]: (state) => {
    state.data = [];
    state.groups = [];
  },
};

const actions = {
  async getMember({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.getMember(payload);
      commit(Types.FETCH_AGENT_SUCESS, response.data);
      return response.data;
    } catch (error) {
      commit(Types.FETCH_AGENT_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async getMemberAgent({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.getMemberSearch(value);
      let filters = response.data.map(function (item) {
        return {
          value: item.user.id,
          label: item.user.name + "  -  " + item.user.email,
          data: item,
        };
      });
      commit(Types.FETCH_AGENT_SUCESS, filters);
    } catch (error) {
      commit(Types.FETCH_AGENT_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async getMemberGroup({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.getMemberGroup();
      commit(Types.FETCH_AGENT_DATA_SUCCESS, response);
    } catch (error) {
      commit(Types.FETCH_AGENT_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async updateOnline({ commit, rootGetters }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.updateOnline({
        agent_id: rootGetters.localStorage.decodedUserId,
        ...payload,
      });
      commit(Types.SET_ONLINE, response.data.state);
      commit(Types.SET_ONLINE, response.data.state);
    } catch (err) {
      console.log("error updateOnline", err);
    }
    commit(Types.SET_LOADING, false);
  },
  approvalAgent({ commit, rootGetters }, payload) {
    if (
      rootGetters.localStorage.decodedUserId == payload.room.livechat.handle_by
    ) {
      commit(Types.WAITING_APPROVAL_AGENT, payload);
    } else if (
      rootGetters.localStorage.decodedUserId == payload.agent.user_id
    ) {
      commit(Types.APPROVAL_ASSIGN_AGENT, payload);
    }
  },
  rejectAssign({ commit, rootGetters }, payload) {
    if (
      rootGetters.localStorage.decodedUserId == payload.room.livechat.handle_by
    ) {
      commit(Types.REJECT_ASSIGN, payload);
    } else if (
      rootGetters.localStorage.decodedUserId == payload.agent.user_id
    ) {
      commit(Types.REMOVE_FROM_APPROVAL_AGENT, payload);
    }
  },
  approveAssignment({ commit, rootGetters }, payload) {
    if (rootGetters.localStorage.decodedUserId == payload.agent.user_id) {
      commit(Types.REMOVE_FROM_APPROVAL_AGENT, payload);
    }
  },
  setOnline({ commit }, payload) {
    commit(Types.SET_ONLINE, payload);
  },
  removeApproveAndWaitingAssignment({ commit }, payload) {
    commit(Types.REMOVE_APPROVAL_AND_WAITING_ASSIGNMENT, payload);
  },
  updateAvailability({ commit, dispatch }, payload) {
    commit(Types.UPDATE_AVAILABLILITY, payload);
    dispatch("needUpdateAgent", true);
  },
  async assignedChannel({ commit, rootGetters }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.assignedChannel(
        rootGetters.localStorage.decodedUserId
      );
      commit(Types.ASSIGNED_CHANNEL, response.data.channel);
    } catch (err) {
      console.log("error assignedChannel", err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getGroup({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.getGroupWithAgent();
      if (response.success) {
        commit(Types.SET_GROUPS, response.data);
        commit(Types.SET_LOADING, false);
        return response;
      }
    } catch (e) {
      commit(Types.SET_LOADING, false);
      commit(Types.FETCH_AGENT_ERROR, e);
      return e;
    }
  },
  async getAssignedGroup({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.assignedGroup();
      if (response.success) {
        commit(Types.ASSIGNED_GROUP, response.data);
        commit(Types.SET_LOADING, false);
        return response;
      }
    } catch (e) {
      commit(Types.SET_LOADING, false);
      commit(Types.FETCH_AGENT_ERROR, e);
      return e;
    }
  },
  async getGroupV3({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await GroupService.getGroupWithAgent(payload);
      if (payload.paginated && payload.paginated == "true") {
        commit(Types.SET_GROUPS, response.data);
        commit(Types.SET_LOADING, false);
        commit(Types.NEED_LOADED_AGENT, false);
        return response;
      } else {
        commit(Types.SET_GROUPS, response.data);
        commit(Types.SET_LOADING, false);
        return response;
      }
    } catch (e) {
      commit(Types.SET_LOADING, false);
      commit(Types.FETCH_AGENT_ERROR, e);
      return e;
    }
  },
  async getProfilePicture({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await httpPlain.get(`user-platform/user-data`);
      commit(Types.PROFILE_PICTURE, response.data.picture);
      return response.data;

    } catch (err) {
      console.log("error euy", err);
      commit(Types.SET_LOADING, false);
    }
  },
  async getOnline({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.getState({ agent_id: payload });
      commit(Types.SET_ONLINE, response.data.state);
    } catch (err) {
      console.log("error getOnline()", err);
      commit(Types.SET_LOADING, false);
    }
  },
  needUpdateAgent({ commit }, payload) {
    commit(Types.NEED_LOADED_AGENT, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
