import { httpClientOmni } from "@/libraries";
import { ChannelService } from "@/services";

const state = {
  channels: [],
  roles: [],
  member: [],
  superior: [],
  content_template: [],
  conversation_label: [],
  conversation_label_sub_list: [],
};

const getters = {
  channels(state) {
    return state.channels;
  },
  roles(state) {
    return state.roles;
  },
  getRoles(state) {
    return state.roles;
  },
  superior(state) {
    return state.superior;
  },
  getContentTemplate(state) {
    return state.content_template;
  },
  getSubConversationLabel(state) {
    return state.conversation_label_sub_list;
  },
  createConversationLabel(state) {
    return state.conversation_label;
  },
  changeStatusConversationLabel(state) {
    return state.conversation_label;
  },
};

const mutations = {
  SET_CHANNELS: (state, payload) => {
    state.channels = payload;
  },
  SET_ROLES: (state, payload) => {
    state.roles = payload;
  },
  CREATE_MEMBER: (state, payload) => {
    state.member = payload;
  },
  EDIT_MEMBER: (state, payload) => {
    state.member = payload;
  },
  DELETE_MEMBER: (state, id) => {
    state.member = id;
  },
  GET_SUPERIOR: (state, payload) => {
    state.superior = payload;
  },
  GET_CONTENT_TEMPLATE: (state, payload) => {
    state.content_template = payload;
  },
  GET_SUB_CONVERSATION_LABEL: (state, payload) => {
    state.conversation_label_sub_list = payload;
  },
  SET_CONVERSATION_LABEL: (state, payload) => {
    state.conversation_label = payload;
  },
};

const actions = {
  async getChannels(context) {
    const response = await ChannelService.getV3Channel();
    context.commit("SET_CHANNELS", response.data);
  },
  async getRoles(context) {
    console.log("Action getRoles");
    let response = await httpClientOmni.get("v3/role");
    context.commit("SET_ROLES", response.data);
  },
  async superior(context, payload) {
    let response = await httpClientOmni.post("/v3/member/superior", {
      currentLevel: payload,
    });
    console.log("superior", response);
    context.commit("GET_SUPERIOR", response.data);
  },
  async create(context, payload) {
    let response = await httpClientOmni.post("/v3/member", payload);
    context.commit("CREATE_MEMBER", response.data);
  },
  async edit(context, payload) {
    let response = await httpClientOmni.post("/v3/member/edit", payload);
    context.commit("EDIT_MEMBER", response.data);
  },
  async delete(context, id) {
    let response = await httpClientOmni.post("/v3/member/delete", {
      id: id,
    });
    context.commit("DELETE_MEMBER", response.data);
  },
  async getContentTemplate(context) {
    let response = await httpClientOmni.get("/v3/content-template");
    console.log("ContentTemplate ", response.data);
    context.commit("GET_CONTENT_TEMPLATE", response.data);
  },

  async getSubConversationLabel(context) {
    let response = await httpClientOmni.get("/settings/conversation-label/sub");

    context.commit("GET_SUB_CONVERSATION_LABEL", response.data.data);
  },

  async createConversationLabel(context, payload) {
    let response = await httpClientOmni.post(
      "/settings/conversation-label/create",
      payload
    );

    context.commit("SET_CONVERSATION_LABEL", response.data);
  },
  async changeStatusConversationLabel(context, payload) {
    await httpClientOmni.put(
      `/settings/conversation-label/change-status/${payload.id}`,
      {
        status: payload.status,
      }
    );
  },
  async deleteConversationLabel(context, payload) {
    await httpClientOmni.delete(
      `/settings/conversation-label/delete/${payload.id}`
    );
  },

  async updateConversationLabel(context, payload) {
    let response = await httpClientOmni.put(
      `/settings/conversation-label/update/${payload.id}`,
      {
        label: payload.label,
        color: payload.color,
      }
    );

    context.commit("SET_CONVERSATION_LABEL", response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
