<template>
  <b-modal
    v-model="modalShow"
    hide-footer
    modal-class="flip"
    class="v-modal-custom"
    hide-header-close
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
    centered
  >
    <div class="modal-body text-center">
      <lottie
        colors="primary:#f7b84b,secondary:#405189"
        trigger="loop"
        :options="defaultOptions2"
        :height="130"
        :width="130"
      />
      <div class="mt-4 pt-4">
        <h4>Uh oh, This action cant be undo!</h4>
        <p class="text-muted">
          Are you sure, want to delete this youtube integration ?
        </p>
        <b-button
          class="me-2"
          type="button"
          variant="light"
          @click="handleCloseModal"
          >cancel
        </b-button>
        <b-button variant="danger" @click="handleDelete"
          >Yes, Delete it!</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import animationData from "@/components/widgets/gsqxdxog.json";
import Lottie from "@/components/widgets/lottie.vue";
export default {
  components: {
    lottie: Lottie,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: this.showModal,
      defaultOptions2: { animationData: animationData },
    };
  },
  watch: {
    showModal(newVal) {
      this.modalShow = newVal;
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("onClose");
      this.modalShow = false;
    },
    handleDelete() {
      this.$emit("onDelete");
      this.modalShow = false;
    },
  },
};
</script>
