<template>
  <div class="card-body">
    <h5 class="card-title">Integration Mobile Omni</h5>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="form-group col-md-10 mt-2">
          <br />
          <p>
            Scan qr code using <u>Mobile Omni Messaging Application</u> and read
            Messages on your smartphone anywhere, anytime
          </p>
        </div>
        <div class="d-flex flex-row gap-2 justify-content-start mt-2">
          <base-button
            type="button"
            class="btn"
            :class="!form.status ? 'btn-secondary' : 'btn-danger'"
            :disabled="!form.status"
            @click="disconnectChannel"
          >
            Disconnect
          </base-button>
          <base-button type="submit" class="btn btn-primary">
            {{ form.status ? "Update" : "Connect" }}
          </base-button>
        </div>
      </div>
    </form>
    <!-- <lenna-modal ref="lennaModal"></lenna-modal> -->
  </div>
</template>

<script>
import { find } from "lodash";

export default {
  data() {
    return {
      form: {
        channelName: "mobile-omni",
        status: this,
      },
    };
  },
  computed: {
    // ...mapGetters({
    //   integration: "integration/integrations",
    // }),
    integration() {
      let integration = this.$store.getters["integration/integrations"];
      console.log("integration", integration);
      integration = find(integration, function (item) {
        return item.name == "mobile-omni";
      });

      return integration;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    async disconnectChannel() {
      try {
        let data = {
          channelName: this.form.channelName,
          status: false,
        };
        let response = await this.$axios.put(
          `api/${this.$store.getters.appId.hashed}/integrate/update`,
          data
        );
        if (response.data.success) {
          this.fetchData();
        }
        this.emitter.emit("hideIntegrationModal");
        this.successToast("Channel disconected");
        this.form.status = false;
      } catch (error) {
        this.fetchData();
        this.form.status = !this.form.status;
        this.errorToast(error);
      }
    },
    async submitForm() {
      try {
        let formData = {
          status: true,
          channelName: this.form.channelName,
        };
        if (this.integration.integration.length) {
          formData.integrationId = this.integration.id;
        }
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/integrate`,
          formData
        );
        this.form.status = true;
        if (response.data.success) {
          this.fetchData();
        }
        this.emitter.emit("hideIntegrationModal");
        this.successToast(response.data.message);
      } catch (error) {
        this.fetchData();
        this.errorToast(this);
      }
    },
    async fetchData() {
      await this.$store.dispatch(
        "integration/getIntegrationData",
        this.form.channelName
      );
      let data = await this.$store.getters["integration/integrationData"];
      if (data.length) {
        this.form.status = data[0].status;
      }
    },
  },
  async mounted() {
    this.fetchData();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.bg-class {
  background-color: #fafafa;
  min-height: 750px;
  height: auto;
}
.img-prepend {
  position: relative;
  cursor: pointer;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
</style>
