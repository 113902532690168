<template>
  <div :class="['chat__contact', show && 'active']">
    <div class="chat__contact-header">
      <div class="chat__contact-header__profile">
        <span class="chat__contact-header__profile-name">Contact List</span>

        <div class="avatar-group">
          <a
            v-for="item in contactList.filter((r, i) => i < 3)"
            :key="item?.id"
            href="javascript:void(0)"
            class="avatar-group-item"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            :title="item.user?.name"
          >
            <!-- <img
              v-if="item.user.picture"
              :src="item.user.picture"
              :alt="item.user.name"
              class="rounded-circle avatar-xxs"
            /> -->
            <ImgLazy
              v-if="item.user?.picture"
              :src="item.user?.picture"
              :alt="item.user?.name"
              errorImg="/img/user-dummy-img.jpg"
              class="rounded-circle avatar-xxs"
            />
            <img
              v-else
              src="../assets/images/users/user-dummy-img.jpg"
              :alt="item.user?.name"
              class="rounded-circle avatar-xxs"
            />
          </a>

          <a
            v-if="contactList.length > 3"
            href="#addmemberModal"
            data-bs-toggle="modal"
            class="avatar-group-item"
          >
            <div class="avatar-xxs">
              <div
                style="
                  font-size: 0.8em;
                  color: black;
                  font-weight: 700;
                  background-color: #e6eef9;
                "
                class="avatar-title rounded-circle"
              >
                +{{ contactList.length - 3 }}
              </div>
            </div>
          </a>
        </div>
      </div>

      <div>
        <button type="button" class="btn-action" @click="$emit('minimize')">
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0V2H14V0H0Z" fill="white" />
          </svg>
        </button>
      </div>
    </div>

    <div>
      <input
        v-model="search"
        type="text"
        class="search-contact"
        placeholder="Search agent..."
      />
    </div>

    <div class="chat__contact-body">
      <ChatItem
        v-for="item in list"
        :key="`item-contact-${item?.id}`"
        :name="item.user?.name"
        :image="item.user?.picture"
        :lastMessage="item.status_online ? 'Online' : 'Offline'"
        :status="item.status_online"
        :isRead="true"
        :color="item.color"
        @click="
          $emit(
            'itemClick',
            item.user?.id,
            item.user?.name,
            item.user?.picture,
            item?.status_online
          )
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

import ChatItem from "./ChatItem";
import ImgLazy from "./base/ImgLazy";

export default defineComponent({
  components: {
    ChatItem,
    ImgLazy,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["back", "minimize", "itemClick"],
  setup() {
    const store = useStore();

    const search = ref("");

    const userId = computed(() =>
      parseInt(store.getters["localStorage"].decodedUserId)
    );

    const onlineMembers = computed(
      () => store.getters["internalChat/getOnlineMembers"]
    );

    const contactList = computed(() => {
      return store.getters["internalChat/getContactList"]
        .map((r) => {
          const check = onlineMembers.value.find(
            (onlineMember) => onlineMember.userId === r.user_id
          );

          return {
            ...r,
            status_online: check?.status || false,
          };
        })
        .filter((r) => r?.user?.id != userId.value);
    });

    const list = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return contactList.value
        .sort((a, b) => Number(b.status_online) - Number(a.status_online))
        .filter(
          (r) =>
            r.user &&
            r.user.name.toUpperCase().indexOf(search.value.toUpperCase()) > -1
        );
    });

    return {
      search,
      contactList,
      userId,
      list,
      onlineMembers,
    };
  },
});
</script>

<style scoped>
.search-contact {
  width: 100%;
  border: none;
  border-bottom: thin solid #ddd;
  padding: 10px 10px;
  outline: none;
}
</style>
