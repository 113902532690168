import { LivechatService } from "@/services";

const Types = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};

const state = {
  data: [],
  error: null,
  loading: false,
};

const getters = {
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_ERROR]: (state, payload) => (state.error = payload),
};

const actions = {
  async assignToGroup({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      LivechatService.assignToGroup(payload);
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async assignToAgent({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await LivechatService.assignToAgent(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async livechatResolve({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await LivechatService.livechatResolve(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async livechatRequest({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await LivechatService.livechatRequest(payload);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
      commit(Types.SET_LOADING, false);
      return error.response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
