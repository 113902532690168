<template>
  <Layout>
    <PageHeader :title="getTitle" :items="items" />

    <div class="container-integration">
      <div
        :class="['sidebar-integration card', sidePage ? 'd-block' : 'd-none']"
        data-simplebar
      >
        <template v-for="(m, key) in menu" :key="key">
          <div class="d-flex align-items-center mb-3 mt-3">
            <div class="flex-grow-1">
              <h5 class="card-title mb-0 text-capitalize">
                {{ changeName(m.category) }}
              </h5>
            </div>
          </div>
          <router-link
            v-for="(submenu, index) in m.submenu"
            :key="index"
            :to="{ name: submenu.name }"
            custom
            v-slot="{ href, navigate }"
          >
            <a
              :href="href"
              class="sidebar-integration-item"
              @click="
                (e) => {
                  navigate(e);
                  showPage();
                }
              "
            >
              <span class="flex-shrink-0">
                <span class="badge badge-soft-secondary p-2 position-relative">
                  <ImgLazy
                    :src="`/img/new-channels/${submenu.icon}`"
                    error-img="/img/new-channels/integration.png"
                    :alt="submenu.name"
                  />
                </span>
              </span>

              <strong
                class="flex-grow-1 ms-2 overflow-hidden fs-14 text-truncate font-bold"
              >
                {{ submenu.subMenuName }}
              </strong>

              <span
                v-if="submenu.total_integration_active > 0"
                class="badge-integration"
              >
                {{ submenu.total_integration_active }}
              </span>
            </a>
          </router-link>
        </template>
      </div>

      <div
        class="flex-1"
        :class="resMobile ? (!sidePage ? '' : 'd-none') : 'lg-show'"
      >
        <div class="card" style="min-height: calc(100vh - 200px)">
          <span class="d-flex flex-row align-items-center">
            <i
              v-if="this.resMobile"
              @click="removePage()"
              class="ri-arrow-left-s-line align-bottom mx-3"
              style="font-size: 2em"
            ></i>
            <p class="ms-2 mt-3" v-if="this.resMobile" @click="removePage()">
              Back
            </p>
          </span>

          <router-view></router-view>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
// import SecondSideBar from "@/views/common/components/SecondSidebar";
// import SettingSecondSideBar from "@/views/common/components/SettingSecondSidebar";
import _ from "lodash";
import { mapGetters } from "vuex";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import ImgLazy from "@/components/base/ImgLazy.vue";

export default {
  components: {
    Layout,
    PageHeader,
    ImgLazy,
    // SecondSideBar
    // SettingSecondSideBar
  },
  computed: {
    ...mapGetters({
      integrations: "integration/integrations",
    }),
    changeWidthUserWindow() {
      this.onResize();
      return "";
    },
    menu() {
      let menu = _.chain(this.subSubMenu)
        .groupBy("category")
        .map((value, key) => ({ category: key, submenu: value }))
        .value()
        .map((menu) => ({
          ...menu,
          submenu: menu.submenu.map((submenu) => ({
            ...submenu,
            total_integration_active:
              this.integrations
                .find((r) => r.name === submenu.slug)
                ?.integration.filter((r) => r.status)?.length || 0,
          })),
        }));
      return menu;
    },
    getTitle() {
      return "Integration";
    },
  },
  data() {
    return {
      sidePage: true,
      windowWidth: window.innerWidth,
      resMobile: false,
      items: [
        {
          text: "Channel",
          href: "/",
        },
        {
          text: "Channel Integration",
          active: true,
        },
      ],
      subSubMenu: [
        {
          slug: "whatsapp",
          name: "integration.whatsapp",
          icon: "whatsapp.svg",
          category: "messenger",
          subMenuName: "Whatsapp",
        },
        {
          slug: "line",
          name: "integration.line",
          icon: "line.svg",
          category: "messenger",
          subMenuName: "Line",
        },
        {
          slug: "telegram",
          name: "integration.telegram",
          icon: "telegram.svg",
          category: "messenger",
          subMenuName: "Telegram",
        },
        {
          slug: "gbusiness",
          name: "integration.google-business",
          icon: "gbusiness.svg",
          category: "messenger",
          subMenuName: "Google Business",
        },
        {
          slug: "whatsapp-lite",
          name: "integration.whatsapp-lite",
          icon: "whatsapp-lite.png",
          category: "messenger",
          subMenuName: "Whatsapp Lite",
        },
        {
          slug: "facebook",
          name: "integration.facebook",
          icon: "facebook.svg",
          category: "social-media",
          subMenuName: "Facebook",
        },
        {
          slug: "instagram",
          name: "integration.instagram",
          icon: "instagram.svg",
          category: "social-media",
          subMenuName: "Instagram",
        },
        {
          slug: "twitter",
          name: "integration.twitter",
          icon: "twitter.png",
          category: "social-media",
          subMenuName: "Twitter",
        },
        {
          slug: "Youtube",
          name: "integration.youtube",
          icon: "youtube.svg",
          category: "social-media",
          subMenuName: "youtube",
        },
        {
          slug: "tokopedia",
          name: "integration.tokopedia",
          icon: "tokopedia.svg",
          category: "marketplace",
          subMenuName: "Tokopedia",
        },
        {
          slug: "shoppee",
          name: "integration.soon",
          icon: "shoppee.svg",
          category: "marketplace",
          subMenuName: "Shoppee",
        },
        {
          slug: "lazada",
          name: "integration.soon",
          icon: "lazada.svg",
          category: "marketplace",
          subMenuName: "Lazada",
        },
        {
          slug: "bukalapak",
          name: "integration.soon",
          icon: "bukalapak.svg",
          category: "marketplace",
          subMenuName: "Bukalapak",
        },
        {
          slug: "zendesk",
          name: "integration.soon",
          icon: "zendesk.svg",
          category: "CRM",
          subMenuName: "Zendesk",
        },
        {
          slug: "freshchat",
          name: "integration.freshchat",
          icon: "freshdesk.svg",
          category: "CRM",
          subMenuName: "Freshchat",
        },
        {
          slug: "hubspot",
          name: "integration.soon",
          icon: "hubspot.svg",
          category: "CRM",
          subMenuName: "Hubspot",
        },
        {
          slug: "haptik",
          name: "integration.haptik",
          icon: "haptik.svg",
          category: "CRM",
          subMenuName: "Haptik",
        },
        {
          slug: "mailersend",
          name: "integration.mailersend",
          icon: "mailersend.png",
          category: "apps",
          subMenuName: "Mailersend",
        },
        {
          slug: "postmark",
          name: "integration.postmark",
          icon: "postmark.png",
          category: "apps",
          subMenuName: "Postmark",
        },
        {
          slug: "webchat",
          name: "integration.webchat",
          icon: "webchat.svg",
          category: "apps",
          subMenuName: "Embeddable Web Chat",
        },
        {
          slug: "mobile",
          name: "integration.mobile",
          icon: "mobile-omni.svg",
          category: "apps",
          subMenuName: "Omni Mobile",
        },
        {
          slug: "mobile",
          name: "integration.mobile",
          icon: "mobile.svg",
          category: "apps",
          subMenuName: "Your Own Apps",
        },
        {
          slug: "bot-integration",
          name: "integration.botintegration",
          icon: "chatbot.svg",
          category: "apps",
          subMenuName: "Chat Bot",
        },
        {
          slug: "email",
          name: "integration.email",
          icon: "email.svg",
          category: "apps",
          subMenuName: "Email",
        },
        {
          slug: "sms",
          name: "integration.sms",
          icon: "sms.svg",
          category: "apps",
          subMenuName: "SMS",
        },
        {
          slug: "dynamic",
          name: "integration.dynamic",
          icon: "sms.svg",
          category: "apps",
          subMenuName: "Dynamic",
        },
        {
          slug: "integration-api-token",
          name: "integration.apitoken",
          icon: "api.svg",
          category: "apps",
          subMenuName: "Api Token",
        },
      ],
    };
  },
  created() {
    this.onResize();
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    showPage() {
      // this.onResize()
      if (this.windowWidth < 769) {
        console.log("mobile view");
        this.sidePage = false;
      } else {
        console.log("desktop view");
        this.sidePage = true;
      }
    },
    removePage() {
      this.sidePage = true;
    },
    onResize() {
      this.windowWidth = window.innerWidth;

      // if data already clicked
      console.log("data selected");
      // mobile checker
      if (this.windowWidth < 769) {
        console.log("mobile view");
        this.resMobile = true;
      } else {
        console.log("desktop view");
        this.resMobile = false;
        this.sidePage = true;
      }
    },
    isActive(slug) {
      console.log("is active", window.location.pathname.split("/")[4]);
      return window.location.pathname.split("/")[4] == slug;
    },
    changeName(cat) {
      let name = cat;
      switch (cat) {
        case "messenger":
          name = "Messengers";
          break;
        case "social-media":
          name = "Social Media";
          break;
        case "apps":
          name = "Apps";
          break;
        default:
          name = cat;
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge img {
  width: 24px;
}
.channel-list .channel {
  padding: 5px;
  border-radius: 4px;
}
.channel-list .channel:hover {
  background: var(--vz-boxed-body-bg);
}
.channel-list {
  overflow: scroll;
  max-height: calc(100vh - 200px);
}

.container-integration {
  display: flex;
  gap: 10px;

  & .sidebar-integration {
    background-color: #fff;
    width: 260px;
    padding: 0px 10px;
    max-height: calc(100vh - 200px);

    @media screen and (max-width: 769px) {
      width: 100%;
    }

    &-item {
      display: flex;
      margin-bottom: 5px;
      background-color: #f8fafc;
      padding: 4px;
      border-radius: 5px;
      align-items: center;

      &:hover {
        background-color: #f1f5f9;
      }
    }

    & .badge-integration {
      display: inline-flex;
      min-width: 20px;
      background-color: rgb(35, 51, 97);
      margin-right: 5px;
      border-radius: 5px;
      color: #fff;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
  }
}

.flex-1 {
  flex: 1;
}
</style>
