import Soon from "@/views/integration/components/Soon";
import Line from "@/views/broadcastByChannel/components/LineList";
import Sms from "@/views/broadcastByChannel/components/SmsList";
export default {
  path: "/:appId/omni/broadcast",
  name: "omni.broadcast",
  component: () => import("@/views/broadcast/pages/BroadcastMainPage.vue"),
  redirect: { name: "omni.broadcast.message" },
  props: true,
  meta: {
    title: "Broadcast Message",
  },
  children: [
    {
      path: "broadcast-by-channel",
      name: "omni.broadcast.broadcast-by-channel",
      meta: {
        title: "Broadcast By Channel",
      },
      component: () =>
        import("@/views/broadcastByChannel/pages/broadcastByChannelPage.vue"),
      redirect: { name: "broadcast-by-channel.line" },
      props: true,
      children: [
        {
          path: "line",
          name: "broadcast-by-channel.line",
          component: Line,
        },
        {
          path: "facebook",
          name: "broadcast-by-channel.facebook",
          component: Soon,
        },
        {
          path: "telegram",
          name: "broadcast-by-channel.telegram",
          component: Soon,
        },
        {
          path: "whatsapp",
          name: "broadcast-by-channel.whatsapp",
          component: Soon,
        },
        {
          path: "webchat",
          name: "broadcast-by-channel.webchat",
          component: Soon,
        },
        {
          path: "mobile",
          name: "broadcast-by-channel.mobile",
          component: () =>
            import("@/views/broadcast/pages/BroadcastMessagePage.vue"),
        },
        {
          path: "mobile-omni",
          name: "broadcast-by-channel.mobile-omni",
          component: Soon,
        },
        {
          path: "twitter",
          name: "broadcast-by-channel.twitter",
          component: Soon,
        },
        {
          path: "qiscus",
          name: "broadcast-by-channel.qiscus",
          component: Soon,
        },
        {
          path: "instagram",
          name: "broadcast-by-channel.instagram",
          component: Soon,
        },
        {
          path: "ticket",
          name: "broadcast-by-channel.ticket",
          component: Soon,
        },
        {
          path: "email",
          name: "broadcast-by-channel.email",
          component: Soon,
        },
        {
          path: "tokopedia",
          name: "broadcast-by-channel.tokopedia",
          component: Soon,
        },
        {
          path: "External API",
          name: "broadcast-by-channel.external",
          component: Soon,
        },
        {
          path: "Sms",
          name: "broadcast-by-channel.sms",
          component: Sms,
        },
        {
          path: "soon",
          name: "broadcast-by-channel.soon",
          component: Soon,
        },
      ],
    },
    {
      path: "message",
      name: "omni.broadcast.message",
      meta: {
        title: "Broadcast Message",
      },
      component: () =>
        import("@/views/broadcast/pages/BroadcastMessagePage.vue"),
    },
    {
      path: "whatsapp-non-hsm",
      name: "omni.broadcast.whatsapp-non-hsm",
      meta: {
        title: "Broadcast Message",
      },
      component: () =>
        import("@/views/broadcast/pages/WhatsappBroadcastPage.vue"),
    },
  ],
};
