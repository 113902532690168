import { ReplyMaterialService } from '@/services';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async create(context, payload) {
        const response = await ReplyMaterialService.create(payload);
        return response;
    },
    async edit(context, payload) {
        const response = await ReplyMaterialService.edit(payload, payload.id);
        return response;
    },
    async delete(context, id) {
        const response = await ReplyMaterialService.delete(id);
        return response;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
